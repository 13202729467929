import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";
import emailjs from "@emailjs/browser";

import WOW from "wow.js";

// import logopng from "./img/logo.png";
// import biglogopng from "./img/biglogobg.png";
import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";
import uparrowelement from "../img/uparrowelement.png";
import playthailand from "../img/playthailand.png";
import camthailand from "../img/camerathailand.png";
import sharethailand from "../img/sharethailand.png";
import penthailand from "../img/writingthailand.png";
import compassthailand from "../img/compassthailand.png";
import thailandcarouselpic from "../img/Grand-palace.png";
import MesgPicthailand from "../img/MesgPicthailand.png";
import accplus from "../img/accplus.png";
import blogimg2 from "../img/blogimg2.jpg";
import blogimg1 from "../img/blogimg1.jpg";
import carousel1 from "../img/carousel1.png";
import DegreeCareer from "../img/icons8-degree-60.png";
import frontview from "../img/front-view.jpg";

import CareerBanner from "../img/careerbanner1.jpg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";
import "../css/globarpartner.css";
import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/CareerDetail.css";
import "../js/timeline";
import "../js/script";

const GlobalPartnerForm = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    CompanyName: "",
    WorkEmail: "",
    PhoneNumber: "",
    Country: "",
    POBOX: "",
    Designation: "",
    HowDidYouHearAboutUs: "",
    NumberOfUsersRequired: "",
    check: false,
  });
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: fieldValue }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_7kylfi3";
    const templateId = "template_9xj8jzs";
    const publicKey = "ab3qUhwvhzC7_Ky60";

    emailjs
      .send(serviceId, templateId, formData, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        alert("Email sent successfully!");
        setFormData({
          FirstName: "",
          LastName: "",
          CompanyName: "",
          WorkEmail: "",
          PhoneNumber: "",
          Country: "",
          POBOX: "",
          Designation: "",
          HowDidYouHearAboutUs: "",
          NumberOfUsersRequired: "",
          check: false,
        });
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("Error sending email! Please check the console for details.");
      });
  };

  return (
    <>
      <div className=" main-body theme-orange dark-horizontal globalpartnerform overflow-hidden">
        <div className="animsition">
          <div className="wrapper parallax-start">
            {/* <ReactTitle title="GlobalPartner | Mannai Travel" /> */}
            <div className="section  p-0 ">
              <div className="section-item blunbgglobal">
                <div
                  className="container-fluid  wow fadeInUp "
                  data-wow-delay=".2s"
                >
                  <div className=" globalformpart ">
                    <div className="col-lg-12  ">
                      <h4 className="wow fadeInUp">
                        WE ARE HERE TO SERVE YOU !{" "}
                      </h4>
                      <p
                        className="globalnormaltxt wow fadeInUp"
                        data-wow-delay="1s"
                        data-wow-duration="1.2s"
                      >
                        interested in doing business with us ? Lets connect.
                      </p>

                      <form
                        onSubmit={handleSubmit}
                        className="text-left wow fadeInUp"
                      >
                        <div className="row justify-content-center gap-2">
                          <div className="col-md-5 p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="First Name*"
                              name="FirstName"
                              value={formData.FirstName}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Last Name*"
                              name="LastName"
                              value={formData.LastName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2">
                          <div className="col-md-5 p-md-2 p-1 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Company Name*"
                              name="CompanyName"
                              value={formData.CompanyName}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Work Email*"
                              name="WorkEmail"
                              value={formData.WorkEmail}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2">
                          <div className="col-md-5 p-md-2 p-1 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Phone Number*"
                              name="PhoneNumber"
                              value={formData.PhoneNumber}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Country(India)"
                              name="Country"
                              value={formData.Country}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2">
                          <div className="col-md-5 p-md-2 p-1 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="P.O Box / Postal Code"
                              name="POBOX"
                              value={formData.POBOX}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5  p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Designation"
                              name="Designation"
                              value={formData.Designation}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2">
                          <div className="col-md-5 p-md-2 p-1">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="How did you hear about us ?"
                              name="HowDidYouHearAboutUs"
                              value={formData.HowDidYouHearAboutUs}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 p-md-2 p-1 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Number of User’s Required "
                              name="NumberOfUsersRequired"
                              value={formData.NumberOfUsersRequired}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row mt-3 tabcheck">
                          <div className="checkbox wow fadeInUp col-1 p-0 d-flex justify-content-center">
                            <input
                              type="checkbox"
                              className="largerCheckbox "
                              name="check"
                              onChange={handleChange}
                              checked={formData.check}
                              required
                            />
                            <span className="checkmark"></span>{" "}
                          </div>
                          <p className="formtext wow fadeInUp col-11">
                            {" "}
                            By filling and submitting this form you understand
                            and agree that the use of Mannai Travel web site is
                            subject to the Mannaitravels.com{" "}
                            <span>Terms &amp; Conditions.</span> Additional
                            details regarding Finanzz collection and use of your
                            personal information, including information about
                            access, retention, rectification, deletion,
                            security, cross-border transfers and other topics,
                            is available in the Mannaitravels.com{" "}
                            <span>Privacy Policy.</span>
                          </p>
                        </div>
                        <button
                          className="btn btn-primary my-2  formbtn wow fadeInLeft"
                          data-wow-delay="1s"
                          data-wow-duration="1.2s"
                          type="submit"
                        >
                          SUBMIT{" "}
                          <i className="icon ion-ios-arrow-thin-right"></i>{" "}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
};

export default GlobalPartnerForm;
