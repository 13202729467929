import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import logopng from "../Pages/img/Mannai PNG logo@72x.png";
import biglogopng from "../Pages/img/biglogobg.png";
import homeicon from "../Pages/img/headerhome.png";
import facebook from "../Pages/img/mannaifacebook.png";
import instagram from "../Pages/img/mannaiinstagram.png";
import twitter from "../Pages/img/mannaitwitter.png";
import linkedin from "../Pages/img/mannailinkedin.png";
import snapchat from "../Pages/img/mannaisnapchat.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../Pages/css/animate.min.css";
import "../Pages/css/ionicons.min.css";
import "../Pages/css/style.css";
import "../Pages/css/header.css";
import "../Pages/js/script";
import WhatsAppButton from "./WhatsAppButton";

const Header = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  const [togglespacetravel, setTogglespacetravel] = useState(false);
  const [togglewhatwedo, setTogglewhatwedo] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      console.log(scrollTop);
      if (scrollTop > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {}, []);

  const toggleClassToHtml = () => {
    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    } else {
      htmlTag.classList.add("body-menu-opened");
      htmlTag.classList.remove("body-menu-close");
    }
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  const handleScrollToDiv = () => {
    window.scrollTo({
      top: 800,
      behavior: "instant",
    });
    // const targetDiv = document.getElementById('luftansa');
    // if (targetDiv) {

    //   targetDiv.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'start',
    //   });
    // }
  };
  return (
    <header
      id="header"
      className={`header header-fixed ${isScrolled ? "scrolled" : ""}`}
    >
      <div className="container-fluid mp0 clearfix">
        <div className="d-flex justify-content-between">
          {" "}
          <div className="p-2 px-2 py-2 px-lg-4 py-lg-4 px-xl-4 px-lg-5 px-md-4 py-md-4  ">
            {" "}
            <Link to="/" onClick={handleScrollToTop}>
              <div
                className={`logomannai p-md-1 p-2 ${
                  isScrolled ? "d-none" : ""
                }`}
              >
                <img src={logopng} alt="" className="brand-logo img-fluid " />
              </div>
            </Link>
          </div>
          <WhatsAppButton />
          <div
            className="d-flex h-fit-content"
            style={{ background: "#17355d" }}
          >
            <div className="homeicon d-flex align-items-center mx-2">
              <Link to="/" onClick={handleScrollToTop}>
                {/* <i className="icon icon ion-home"></i>{" "} */}
                <img
                  src={homeicon}
                  height={30}
                  width={30}
                  alt=""
                  className=""
                />
              </Link>
            </div>
            <button
              className="nav-toggle-btn a-nav-toggle"
              onClick={toggleClassToHtml}
            >
              {" "}
              <span className="nav-toggle nav-toggle-sm">
                {" "}
                <span className="stick stick-1"></span>{" "}
                <span className="stick stick-2"></span>{" "}
                <span className="stick stick-3"></span>{" "}
              </span>{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="hide-menu a-nav-toggle"></div>
      <div className="menu">
        <input
          type="text"
          className="form-control form-control-custom"
          placeholder="How can we help you?"
        />
        <div className="menu-main" id="accordion">
          <ul>
            <li class="expand">
              <a
                data-text=""
                data-toggle="collapse"
                href="#menuWhatwedo"
                role="button"
                aria-expanded="false"
                aria-controls="menuWhatwedo"
                onClick={() => setTogglewhatwedo(!togglewhatwedo)}
              >
                What We Do
              </a>
              <div
                class={togglewhatwedo ? "" : "collapse"}
                id="menuWhatwedo"
                data-parent="#menuWhatwedo"
              >
                <ul>
                  <li>
                    <Link
                      class="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text=""
                      to="/Services"
                      onClick={handleScrollToTop}
                    >
                      Our Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="animsition-link"
                      data-animsition-out-class="fade-out"
                      to="/Businesstravel"
                      onClick={handleScrollToTop}
                    >
                      Business Travel
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text=""
                      to="/Leisuretravel"
                      onClick={handleScrollToTop}
                    >
                      Leisure Travel
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {/* <li>
              <Link to="/Services" onClick={handleScrollToTop}>
               
                  What we do
               
              </Link>
              <div
                className="collapse"
                id="menuLeisure"
                data-parent="#accordion"
              >
                <ul>
                  <li>
                    <a
                      href="#"
                      className="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text=""
                    >
                      Leisure
                    </a>
                  </li>
                </ul>
              </div>
            </li> */}
            <li>
              <Link to="/Whoweare" onClick={handleScrollToTop}>
                Who We are
              </Link>
              <div
                className="collapse"
                id="menuWhoweare"
                data-parent="#accordion"
              >
                <ul>
                  <li>
                    <a
                      href="#"
                      className="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text="Company Overview"
                    >
                      Company Overview
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text="Our Mission &amp; Value"
                    >
                      Our Mission &amp; Value
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text="Awards &amp; Achievements"
                    >
                      Awards &amp; Achievements
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text="Our Team / Leadership"
                    >
                      Our Team / Leadership
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text="Company Overview"
                    >
                      Company Overview
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link to="/Technologies" onClick={handleScrollToTop}>
                Technology{" "}
              </Link>
            </li>

            <li class="expand">
              <a
                data-text=""
                data-toggle="collapse"
                href="#OurSubsidiaries"
                role="button"
                aria-expanded="false"
                aria-controls="OurSubsidiaries"
                onClick={() => setTogglespacetravel(!togglespacetravel)}
              >
                Our Subsidiaries
              </a>
              <div
                class={togglespacetravel ? "" : "collapse"}
                id="OurSubsidiaries"
                data-parent="#OurSubsidiaries"
              >
                <ul>
                  <li>
                    <Link
                      class="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text=""
                      to="/SpaceTravel"
                      onClick={handleScrollToTop}
                    >
                      Space Travel
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text=""
                      to="/SpaceTravel"
                      onClick={handleScrollToDiv}
                    >
                      Space Lufthansa
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="animsition-link"
                      data-animsition-out-class="fade-out"
                      to="/SpaceCargo"
                      onClick={handleScrollToDiv}
                    >
                      Space Cargo
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/MannaiHoliday"
                      class="animsition-link"
                      onClick={handleScrollToTop}
                    >
                      Mannai Hollidays
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link to="/GlobalPartner" onClick={handleScrollToTop}>
                Global Partners
              </Link>
            </li>
            <li>
              <Link to="/Blogsandnews" onClick={handleScrollToTop}>
                Blogs and News{" "}
              </Link>
            </li>
            {/* <li>
            <Link to="" onClick={handleScrollToTop}>
            
            CSR & Networking{" "}
             
              </Link>
            
            </li>
            */}
            {/* <li>
              <Link to="/Career" onClick={handleScrollToTop}>
                Careers{" "}
              </Link>
              <div
                className="collapse"
                id="menuCareers"
                data-parent="#accordion"
              >
                <ul>
                  <li>
                    <a
                      href="#"
                      className="animsition-link"
                      data-animsition-out-class="fade-out"
                      data-text="Contact Details"
                    >
                      Career Details
                    </a>
                  </li>
                </ul>
              </div>
            </li> */}
            <li>
              <Link to="/connectus" onClick={handleScrollToTop}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="menu-footer">
          <ul className="social social-rounded">
            <li>
              <a
                href="https://www.facebook.com/mannaitravelqatar"
                target="_blank"
              >
                <img src={facebook} width="28" height="28" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/mannaitravelqatar/"
                target="_blank"
              >
                <img src={instagram} width="28" height="28" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/MannaiTravel" target="_blank">
                <img src={twitter} width="28" height="28" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/83501210/admin/feed/posts/"
                target="_blank"
              >
                <img src={linkedin} width="28" height="28" />
              </a>
            </li>
            <li>
              <a
                href="https://www.snapchat.com/add/mannaitravelqa"
                target="_blank"
              >
                <img src={snapchat} width="28" height="28" />
              </a>
            </li>
          </ul>
        </div>
        <div className="menu-lang d-flex justify-content-end  ">
          {" "}
          <Link
            to="/privacypolicy"
            className="menu-lang-item text-decoration-none"
            onClick={handleScrollToTop}
          >
            Privacy Policy
          </Link>{" "}
          <Link
            to="/termsandconditions"
            className="menu-lang-item text-decoration-none"
            onClick={handleScrollToTop}
          >
            Terms &amp; Conditions
          </Link>{" "}
          <Link
            to="/cookiepolicy"
            className="menu-lang-item text-decoration-none"
            onClick={handleScrollToTop}
          >
            Cookie Policy
          </Link>{" "}
        </div>
        <div className="menu-copyright copyright d-flex justify-content-end ">
          COPYRIGHT &copy;2024 MANNAI TRAVEL DOHA QATAR
        </div>
        <div className="biglogo-fixed">
          {" "}
          <img src={biglogopng} alt="" />
        </div>
      </div>
    </header>
  );
};

export default Header;
