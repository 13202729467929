import React, { useEffect } from "react";

import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

// import logopng from "./img/logo.png";
// import biglogopng from "./img/biglogobg.png";
import biglogoservicepng from "./img/biglogoservice.png";
import roundtxtpng from "./img/roundtxt.png";
import element1png from "./img/element1.png";
import element2png from "./img/element2.png";

import uparrowelement from "./img/uparrowelement.png";

import mannairoundtxt from "./img/Mannai Circle 001@72x.png";
import termsBanner from "./img/TermsOfUseBanner.jpg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "./css/animate.min.css";
import "./css/ionicons.min.css";
import "./css/style.css";
import "./css/Terms&Conditions.css";
import "./css/globarpartner.css";
import "./js/timeline";
import "./js/script";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Partners from "../Components/Partners";

const TermsandConditions = () => {
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <div className="main-body theme-orange dark-horizontal termsandcondition overflow-hidden">
      <div className="animsition">
        <div className="wrapper">
          <ReactTitle title="Terms&Conditions | Mannai Travel" />
          <Header />

          <div className="slide-container">
            <div className="slide-bg wow pulse">
              <div className="inside opacityBG ">
                <img
                  src={termsBanner}
                  alt=""
                  className=" img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div className="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>
              {/* <div
                className="pluselementglobal wow rotateIn"
                style={{ visibility: "visible", animationName: "rotateIn" }}
            >
                {" "}
                <img src={pluselement} alt="" />
            </div> */}
              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>

              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>

              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>

              {/* <div className="wwabpluselement wow rotateIn">
                {" "}
                <img src={pluselement} alt="" />
              </div> */}

              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                    TERMS OF USE
                  </h3>
                  <div className="leftarrowanimationelement">
                    {" "}
                    <img src={element2png} alt="" className="ani-left-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bgthailandblue p-4">
            <div className="col-12 col-md-2 col-lg-2 bgsquarex mobnone tabnone">
              <div className="d-flex justify-content-center align-items-center py-5"></div>
            </div>
            <div className="col-12 col-md-7 col-lg-5 justify-content-center align-items-center padmob">
              <div className="mx-4 testing">
                <h2
                  className="text-white termpart"
                  style={{ textAlign: "left" }}
                >
                  Mannai Travel
                </h2>
                <br />
                <h2
                  className="text-white  termpart"
                  style={{ textAlign: "left" }}
                >
                  Terms & Conditions
                </h2>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-4">
              <h3
                className="text-center text-white mt-5  termupdate"
                style={{ textAlign: "left" }}
              >
                UPDATED DATE : 10-10-23
              </h3>
            </div>
          </div>

          <div className="linearthailandbgm ">
            <div className="row mobborderbottom">
              <div className="col-12 col-lg-12  wow fadeInRight ">
                <div className="cdecontentcd py-5">
                  <h3 className="text-white  ">Effective Date: 10-10-23</h3>
                  <p className="text-white ">
                    Please read these Terms and Conditions carefully before
                    using the Mannai Travel Qatar website (the "Website"). By
                    accessing or using the website, you agree to be bound by
                    these Terms and Conditions. If you do not agree to these
                    terms, please do not use the Website.
                  </p>
                  <ol>
                    <li className="text-white abouttext mt-2">
                      Use of the Website
                    </li>
                    <ol>
                      <li className="text-white my-2">
                        The Website is provided for informational and booking
                        purposes related to the travel services offered by
                        Mannai Travels Qatar. You may use the Website for
                        personal, non-commercial use and in accordance with
                        these Terms and Conditions.
                      </li>

                      <li className="text-white my-2 ">
                        You must be at least 18 years old to use the Website. If
                        you are under 18, you may only use the Website under the
                        supervision of a parent or legal guardian.
                      </li>
                      <li className="text-white my-2">
                        You agree not to use the Website for any unlawful or
                        prohibited purpose and not to violate any local,
                        national, or international laws and regulations.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      Booking and Reservations
                    </li>
                    <ol>
                      <li className="text-white my-2">
                        When making reservations or bookings through the
                        Website, you agree to provide accurate, current, and
                        complete information, and you are responsible for any
                        changes to or cancellations of reservations as per our
                        cancellation policy.
                      </li>
                      <li className="text-white my-2">
                        Mannai Travel Qatar reserves the right to cancel or
                        refuse any booking for any reason at our discretion. We
                        may require additional information to confirm your
                        booking.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      Content and Intellectual Property
                    </li>
                    <ol>
                      <li className="text-white my-2">
                        The content on the Website, including text, graphics,
                        images, logos, and software, is the property of Mannai
                        Travels Qatar and is protected by copyright and other
                        intellectual property laws. You may not reproduce,
                        distribute, or use the content without our written
                        permission.
                      </li>
                      <li className="text-white my-2">
                        Any trademarks, logos, or service marks displayed on the
                        Website are registered and unregistered trademarks of
                        Mannai Travel Qatar. You may not use these marks without
                        our written permission.
                      </li>
                    </ol>
                    <li className="text-white abouttext  mt-2">Privacy</li>
                    <ol>
                      <li className="text-white my-2">
                        Our use of your personal information is governed by our
                        Privacy Policy, which can be found on our Website. By
                        using the Website, you consent to the collection, use,
                        and sharing of information as described in our Privacy
                        Policy.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">Disclaimer</li>
                    <ol>
                      <li className="text-white my-2">
                        The information provided on the Website is for general
                        informational purposes only. While we strive to provide
                        accurate and up-to-date information, we do not guarantee
                        the accuracy, completeness, or reliability of the
                        content.
                      </li>
                      <li className="text-white my-2">
                        Mannai Travel Qatar is not responsible for any errors or
                        omissions on the Website, and we reserve the right to
                        correct any inaccuracies.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      Limitation of Liability
                    </li>
                    <ol>
                      <li className="text-white my-2 ">
                        Mannai Travel Qatar and its affiliates shall not be
                        liable for any direct, indirect, incidental, special, or
                        consequential damages arising out of or in any way
                        connected with your use of the Website.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">Termination</li>
                    <ol>
                      <li className="text-white my-2 ">
                        We reserve the right to terminate or suspend your access
                        to the Website at our sole discretion, without notice,
                        for any reason, including breach of these Terms and
                        Conditions.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">
                      Changes to Terms and Conditions
                    </li>
                    <ol>
                      <li className="text-white my-2">
                        We may update or modify these Terms and Conditions at
                        any time. Any changes will be effective upon posting on
                        the Website with a new effective date. It is your
                        responsibility to review the Terms and Conditions
                        regularly.
                      </li>
                    </ol>
                    <li className="text-white abouttext mt-2">Contact Us</li>
                    <ul className="list-unstyled">
                      <li className="text-white my-2">
                        If you have any questions or concerns about these Terms
                        and Conditions, please contact us at
                        <a
                          className="text-decoration-none"
                          href="mailto: travel.services@mannai.com.qa"
                        >
                          {" "}
                          travel.services@mannai.com.qa{" "}
                        </a>
                      </li>
                      <li className="text-white my-2">
                        Thank you for using Mannai Travel Qatar. We hope you
                        have a great experience using our Website and our travel
                        services.
                      </li>
                    </ul>
                  </ol>
                </div>
              </div>
              {/* <h2 className="row text-white  termeffectiveDate">
                Effective Date : 10-10-23{" "}
              </h2>
              <p className="text-white  termpara ">
                Please read these Terms and Conditions carefully before using
                the Mannai Travel Qatar website (the "Website"). By accessing
                or using the website , you agree to be bound by these Terms and
                Conditions . If you do not agree to these terms , please do not
                use the Website
              </p>
              <p className=" text-white    termhead">1. Use of the webiste </p>

              <p className=" text-white   termpara">
                1.1 The Website is provided for informational and booking
                purpose related to travel services offered by Mannai Travel
                Qatar. You may the Website for Personal, ,non-commercial use and
                in accordace with these Terms and Conditions.
              </p>

              <p className=" text-white  termpara">
                1.2 You must be at least 18 years old to use the Website. If you
                are under 18 , you may only use the Website under the
                supervisionn of a parent or legal guardian.
              </p>

              <p className=" text-white  termpara">
                1.3 You agree not to use the Website for any unlawful or
                prohibited purpose and not to violate any local, national ,or
                international laws adn regulations.
              </p>
              <p className="text-white   termhead">
                2. Booking and Reservations
              </p>

              <p className=" text-white   termpara">
                2.1 When making reservations or bookings through the Website,
                you agree to provide accurate , current , and complete
                information , and you are responsibel for any chanages to or
                cancellations or reservations as per our cancellation policy.
              </p>

              <p className="text-white    termpara">
                2.2 Mannai Travel Qatar reserves the right to cancel or refuse
                any booking for any reason at our discretion. We may require
                additional information to confirm your booking.
              </p>
              <p className="text-white   termhead">
                3. Content and Intellectual Property
              </p>

              <p className="text-white    termpara">
                3.1 The content on the website , including text , graphics,
                images , logos, and software , is the property of Mannai Travel
                Qatar and is protected by copyright and other intellectual
                property laws. you may not reproduce, distribute , or use the
                content without our written permission.
              </p>

              <p className="text-white   termpara">
                3.2 Any trademarks , logos , or service marks displayed on the
                Website are registered and unregistered trademarks of Mannai
                Travels Qatar . You may not use these marks without our written
                Permission
              </p>

              <p className="text-white   termhead">4. Privacy</p>

              <p className="text-white  termpara">
                4.1 Our use of your personal information is governed by our
                Privacy Policy , which can be found on our website. By using the
                Website , you consent to the collecion, use , and sharing of
                information as described in our Privacy Policy.
              </p>

              <p className="text-white    termhead">5. Disclaimer</p>

              <p className="text-white    termpara">
                5.1 The information provided on the Website is for general
                informational purposes only. While we strive to provide accurate
                and up-to-date information , we do not guranteen the accuracy ,
                completeness , or reliability of the content.
              </p>

              <p className="text-white   termpara">
                5.2 The content on the website , including text , graphics,
                images , logos, and software , is the property of Mannai Travel
                Qatar and is protected by copyright and other intellectual
                property laws. you may not reproduce, distribute , or use the
                content without our written permission.
              </p>

              <p className="text-white  termhead">6. Limitation of Liability</p>

              <p className="text-white  termpara">
                6.1 The content on the website , including text , graphics,
                images , logos, and software , is the property of Mannai Travel
                Qatar and is protected by copyright and other intellectual
                property laws. you may not reproduce, distribute , or use the
                content without our written permission.
              </p>

              <p className="text-white   termhead">7. Termination</p>

              <p className="text-white   termpara">
                7.1 The content on the website , including text , graphics,
                images , logos, and software , is the property of Mannai Travel
                Qatar and is protected by copyright and other intellectual
                property laws. you may not reproduce, distribute , or use the
                content without our written permission.
              </p>

              <p className="text-white   termhead">8. Termination</p>

              <p className="text-white   termpara">
                8.1 The content on the website , including text , graphics,
                images , logos, and software , is the property of Mannai Travel
                Qatar and is protected by copyright and other intellectual
                property laws. you may not reproduce, distribute , or use the
                content without our written permission.
              </p>

              <p className="text-white  termhead">9. Termination</p>

              <p className="text-white    termpara">
                The content on the website , including text , graphics, images ,
                logos, and software , is the property of Mannai Travel Qatar
                and is protected by copyright and other intellectual property
                laws. you may not reproduce, distribute , or use the content
                without our written permission.
              </p>

              <p className="text-white  termpara">
                The content on the website , including text , graphics, images ,
                logos, and software , is the property of Mannai Travel Qatar
                and is protected by copyright and other intellectual property
                laws. you may not reproduce, distribute , or use the content
                without our written permission.
              </p> */}
            </div>

            {/* ATTRACTION SECTION */}

            {/* <div
              className="d-flex overflow-x-scroll mx-4 scroll-hidden"
              style={{ gap: "20px" }}
            >
              {console.log} 

              <div className="w-30 position-relative">
                <div className=" justify-content-center align-items-center mb-4">
                  <img className="op8 car" src={e.image} alt="" />{" "}
                </div> 
                <div className="position-absolute editcardiv shadowCard">
                  <div className="position-relative " style={{ bottom: 33 }}>
                    <h4 className="loose">{e.title} </h4>
                    <h4 className="mt-4 loose">{e.desc}</h4>
                    <img
                      className="position-absolute editcarplus"
                      src={pluselement}
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="w-30 position-relative">
                <div className=" justify-content-center align-items-center mb-4 ">
                  {" "}
                  <img className="op8 car" src={imgm2} alt="" />{" "}
                </div>
                <div className="position-absolute editcardiv shadowCard">
                  <div className="position-relative " style={{ bottom: 33 }}>
                    <h4 className="loose">ATTRACTION 02</h4>
                    <h4 className="mt-auto loose">
                      SOUTH AFRICA'S CULINARY ODYSSEY{" "}
                    </h4>
                    <img
                      className="position-absolute editcarplus"
                      src={pluselement}
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                </div>
              </div>
              <div className="w-30 position-relative">
                <div className=" justify-content-center align-items-center mb-4 ">
                  {" "}
                  <img className="op8 car" src={imgm3} alt="" />{" "}
                </div>
                <div className="position-absolute editcardiv shadowCard">
                  <div className="position-relative" style={{ bottom: 33 }}>
                    <h4 className="loose">ATTRACTION 03</h4>
                    <h4 className="mt-4 loose">
                      BUNNY CHOW CAPE TOWN'S ICONIC FOOD
                    </h4>
                    <img
                      className="position-absolute editcarplus"
                      src={pluselement}
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                </div>
              </div>
              <div className="w-30 position-relative">
                <div className=" justify-content-center align-items-center mb-4 ">
                  {" "}
                  <img className="op8 car" src={imgm4} alt="" />{" "}
                </div>
                <div className="position-absolute editcardiv shadowCard">
                  <div className="position-relative " style={{ bottom: 33 }}>
                    <h4 className="loose">ATTRACTION 04</h4>
                    <h4 className="mt-4 loose">
                      BOBOTIE SOUTH AFRICA HERITAGE
                    </h4>
                    <img
                      className="position-absolute editcarplus"
                      src={pluselement}
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                </div>
              </div>
              <div className="w-30 position-relative">
                <div className=" justify-content-center align-items-center mb-4 ">
                  {" "}
                  <img className="op8 car" src={imgm5} alt="" />{" "}
                </div>
                <div className="position-absolute editcardiv shadowCard">
                  <div className="position-relative" style={{ bottom: 33 }}>
                    <h4 className="loose">ATTRACTION 05</h4>
                    <h4 className="mt-4 loose">
                      SNOKE BRAAI - SEAFOOD SENSATION
                    </h4>
                    <img
                      className="position-absolute editcarplus"
                      src={pluselement}
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                </div>
              </div>
              <div className="w-30 position-relative">
                <div className=" justify-content-center align-items-center mb-4 ">
                  {" "}
                  <img className="op8 car" src={imgm6} alt="" />{" "}
                </div>
                <div className="position-absolute editcardiv shadowCard">
                  <div className="position-relative" style={{ bottom: 33 }}>
                    <h4 className="loose">ATTRACTION 06</h4>
                    <h4 className="mt-4 loose">
                      {" "}
                      GATSBY: CAPE TOWN'S SANDWICH
                    </h4>
                    <img
                      className="position-absolute editcarplus"
                      src={pluselement}
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                </div>
              </div> */}

            {/* PHI PHI SECTION  */}
            {/* <div className="row mobborderbottom">
              <div className="col-12 col-lg-3 MesgPicthailand wow fadeInLeft">
                <img className="roundedtop  phiphi1" src={imgm1} />{" "}
              </div>
              <div className="col-12 col-lg-9 d-flex justify-content-center flex-column align-items-center position-relative wow fadeInRight ">
                <div className="p-5 ">
                  <h2 className="text-white w-100">PHIPHI TTILE</h2>
                  <p className="text-white abouttext mt-2">PHIPHI DESC</p>
                  <img
                    className="position-absolute editplusnew"
                    src={pluselement}
                    alt=""
                    height="100"
                    width="100"
                  />
                </div>
              </div>
            </div> */}
            {/* second  */}
            {/* <div className="row mobborderbottom">
              <div className="col-12 col-lg-9 d-flex justify-content-center flex-column align-items-center position-relative ">
                <div className="p-5">
                  <h2 className="text-white text-end w-100">
                    South Africa's Culinary Odyssey
                  </h2>
                  <p className="text-white abouttext text-end mt-2">
                    As European settlers landed on South African shores, they
                    brought with them the traditions of Dutch, German, and
                    French cuisines, adding layers of hearty stews, sausages,
                    and refined dining customs. The French Huguenots, in
                    particular, ignited South Africa's wine culture, cultivating
                    vineyards that continue to flourish. When the British took
                    the reins, their English breakfasts and pies left their
                    mark, alongside the Portuguese, who introduced fiery flavors
                    with their chilli-infused dishes.
                  </p>
                  <img
                    className="position-absolute editplusnewright"
                    src={pluselement}
                    alt=""
                    height="100"
                    width="100"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-3 MesgPicthailand ">
                <img
                  className="roundedtop phiphi3"
                  src="https://img.taste.com.au/9zJVb7ln/taste/2016/11/bobotie-south-african-curried-mince-pie-90508-1.jpeg "
                  alt=""
                />{" "}
              </div>
            </div> */}
            {/* third  */}
            {/* <div className="row mobborderbottom">
              <div className="col-12 col-lg-3 MesgPicthailand ">
                <img className="roundedtop phiphi2" src={imgm4} alt="" />{" "}
              </div>
              <div className="col-12 col-lg-9 d-flex justify-content-center flex-column align-items-center position-relative">
                <div className="p-5">
                  <h2 className="text-white w-100">Bunny Chow</h2>
                  <p className="text-white abouttext mt-2">
                    Yet, perhaps the most intriguing fusion came from the Malay
                    slaves, who hailed from Indonesia and Madagascar. Their
                    mastery of aromatic spices transformed the South African
                    palate, infusing the local cuisine with a tantalizing array
                    of flavors. Fast forward to the arrival of Indian indentured
                    labourers, and South Africa welcomed a symphony of curries,
                    making curry and rice a beloved national dish alongside "pap
                    en vleis."This culinary journey reveals that South African
                    cuisine is a harmonious blend of indigenous ingredients and
                    the influences of Dutch, French, English, Portuguese, Malay,
                    and Indian culinary traditions, each contributing a
                    delicious note to the nation's symphony of flavors.
                  </p>
                  <img
                    className="position-absolute editplusnew"
                    src={pluselement}
                    alt=""
                    height="100"
                    width="100"
                  />
                </div>
              </div>
            </div> */}
            {/* fourth  */}
            {/* <div className="row mobborderbottom">
              <div className="col-12 col-lg-9 d-flex justify-content-center flex-column align-items-center position-relative ">
                <div className="p-5">
                  <h2 className="text-white text-end w-100">Bobotie </h2>
                  <p className="text-white abouttext text-end mt-2">
                    For a taste of South African heritage, Bobotie is a
                    must-try. This dish, often considered the national dish of
                    South Africa, consists of spiced minced meat topped with a
                    mixture of egg and milk, baked to golden perfection. The
                    aromatic blend of curry, turmeric, and dried fruits creates
                    a symphony of flavors that will transport you to the heart
                    of the Rainbow Nation. To savor an authentic Bobotie, visit
                    one of Cape Town's historic restaurants, such as Gold
                    Restaurant in the city center, which offers a cultural and
                    culinary experience in one.
                  </p>
                  <img
                    className="position-absolute editplusnewright"
                    src={pluselement}
                    alt=""
                    height="100"
                    width="100"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-3 MesgPicthailand ">
                <img className="roundedtop phiphi4" src={imgm5} alt="" />{" "}
              </div>
            </div> */}
            {/* <div className="ms-5 py-5">
              <img src={element2png} alt="" class="ani-left-right ms-5" />
              <div class=" wow fadeInUp d-flex ms-5" data-wow-delay="0.4s">
                {" "}
                <a class="btn btn-primary mr-4 fr mt-4" href="#">
                  BOOK THAILAND PACKAGE
                  <i class="icon ion-ios-arrow-thin-right"></i>
                </a>{" "}
              </div>
            </div> */}
          </div>

          {/* <div className="bgthailandblue px-5 py-4">
            <h3 className="text-white">FAQ'S</h3>
          </div> */}

          {/* <div className="linearthailandbgm pt-4"> */}
          {/* old  */}
          {/* <div className="row px-4 justify-content-around py-2 ">
              <div className="col-12 col-md-6">
                <div className="bg-light p-4 ">
                  <h4>1. BEST TIME TO VISIT THAILAND</h4>
                  <p>LOREM IPSUM DOLOR SIT AMET, CONSECTETUER</p>
                  <div className="d-flex justify-content-end">
                    <img height="20" width="20" src={accplus} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="bg-light p-4 ">
                  <h4>1. BEST TIME TO VISIT THAILAND</h4>
                  <p>LOREM IPSUM DOLOR SIT AMET, CONSECTETUER</p>
                  <div className="d-flex justify-content-end">
                    <img height="20" width="20" src={accplus} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-4 justify-content-around py-2 ">
              <div className="col-12 col-md-6">
                <div className="bg-light p-4">
                  <h4>1. BEST TIME TO VISIT THAILAND</h4>
                  <p>LOREM IPSUM DOLOR SIT AMET, CONSECTETUER</p>
                  <div className="d-flex justify-content-end">
                    <img height="20" width="20" src={accplus} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="bg-light p-4 ">
                  <h4>1. BEST TIME TO VISIT THAILAND</h4>
                  <p>LOREM IPSUM DOLOR SIT AMET, CONSECTETUER</p>
                  <div className="d-flex justify-content-end">
                    <img height="20" width="20" src={accplus} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-4 justify-content-around py-2 ">
              <div className="col-12 col-md-6">
                <div className="bg-light p-4 m">
                  <h4>1. BEST TIME TO VISIT THAILAND</h4>
                  <p>LOREM IPSUM DOLOR SIT AMET, CONSECTETUER</p>
                  <div className="d-flex justify-content-end">
                    <img height="20" width="20" src={accplus} alt="" />
                  </div>
                </div>
              </div>
             
              <div className="col-12 col-md-6">
                <div className="bg-light p-4 ">
                  <h4>1. BEST TIME TO VISIT THAILAND</h4>
                  <p>LOREM IPSUM DOLOR SIT AMET, CONSECTETUER</p>
                  <div className="d-flex justify-content-end">
                    <img height="20" width="20" src={accplus} alt="" />
                  </div>
                </div>
              </div>
            </div> */}

          {/* accordion  */}
          {/* <div class="container">
              <div class="row">
                <!-- start  -->
                <div class="col-md-6 mobpad">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header m-3" id="headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          1. BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the first item's accordion body.
                          </strong>{" "}
                          It is shown by default, until the collapse plugin adds
                          the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item ">
                      <h2 class="accordion-header m-3" id="headingTwo">
                        <button
                          class="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          2.BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the second item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header m-3" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          3. BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the third item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 end 

                start
                <div class="col-md-6">
                   <!-- start  -->
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header m-3" id="headingFour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          4. BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse "
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the first item's accordion body.
                          </strong>{" "}
                          It is shown by default, until the collapse plugin adds
                          the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header m-3" id="headingFive">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          5. BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the second item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header m-3" id="headingSix">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          6. BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the third item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end  
                </div>
              </div>
            </div> */}

          {/* <div className="row py-4 px-5">
              <div className="col-12 col-lg-3 bgsquarex d-flex justify-content-center align-items-center h-150">
                <h2 className="text-white m-0">SIMILAR BLOGS</h2>
              </div>
            </div> */}

          {/* <div class="container-fluid">
              <div class="row">
                <div class="col-12 col-lg-6  ">
                  <div class="article-item bl1 mb70">
                    <div class="blogelementsmallx ">
                      {" "}
                      <img
                        src={elementsmallxpng}
                        alt=""
                        class="wow pulse"
                        data-wow-delay="300ms"
                        data-wow-iteration="infinite"
                        data-wow-duration="2s"
                      />
                    </div>
                    <div class="blogpartpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <h5
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.4s"
                      data-wow-duration="1s"
                    >
                      BLOG - 24
                    </h5>
                    <h6
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.6s"
                      data-wow-duration="1s"
                    >
                      LEISURE TRAVEL
                    </h6>
                    <div
                      class="article-item-photo zooming wow fadeInDown"
                      data-wow-delay=".4s"
                      data-wow-offset="150"
                    >
                      {" "}
                      <img src={img10} alt="" class="img-fluid" />{" "}
                    </div>
                    <div class="blogimgtpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <div class="blogimgarelement ">
                      {" "}
                      <img src={element2png} alt="" class="ani-left-right" />
                    </div>
                    <div
                      class="blogimgrbbtn editbottom wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      {" "}
                      <a class="btn btn-primary mr-4 fr " href="#">
                        READ BLOG <i class="icon ion-ios-arrow-thin-right"></i>
                      </a>{" "}
                    </div>
                    <div class="bolgimgtitle ">
                      Uncover Armenia's Hidden Gems: 10 Things to Do Beyond the
                      Tourist Trail
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6  ">
                  <div class="article-item bl1 mb70">
                    <div class="blogelementsmallx ">
                      {" "}
                      <img
                        src={elementsmallxpng}
                        alt=""
                        class="wow pulse"
                        data-wow-delay="300ms"
                        data-wow-iteration="infinite"
                        data-wow-duration="2s"
                      />
                    </div>
                    <div class="blogpartpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <h5
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.4s"
                      data-wow-duration="1s"
                    >
                      BLOG - 24
                    </h5>
                    <h6
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.6s"
                      data-wow-duration="1s"
                    >
                      LEISURE TRAVEL
                    </h6>
                    <div
                      class="article-item-photo zooming wow fadeInDown"
                      data-wow-delay=".4s"
                      data-wow-offset="150"
                    >
                      {" "}
                      <img src={img11} alt="" class="img-fluid" />{" "}
                    </div>
                    <div class="blogimgtpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <div class="blogimgarelement ">
                      {" "}
                      <img src={element2png} alt="" class="ani-left-right" />
                    </div>
                    <div
                      class="blogimgrbbtn editbottom wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      {" "}
                      <a class="btn btn-primary mr-4 fr " href="#">
                        READ BLOG <i class="icon ion-ios-arrow-thin-right"></i>
                      </a>{" "}
                    </div>
                    <div class="bolgimgtitle ">
                      Top 10 Things to Do to Get an Unforgettable Experience in
                      Armenia
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 tabnone ">
                  <div class="article-item bl1 mb70">
                    <div class="blogelementsmallx ">
                      {" "}
                      <img
                        src={elementsmallxpng}
                        alt=""
                        class="wow pulse"
                        data-wow-delay="300ms"
                        data-wow-iteration="infinite"
                        data-wow-duration="2s"
                      />
                    </div>
                    <div class="blogpartpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <h5
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.4s"
                      data-wow-duration="1s"
                    >
                      BLOG - 24
                    </h5>
                    <h6
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.6s"
                      data-wow-duration="1s"
                    >
                      LEISURE TRAVEL
                    </h6>
                    <div
                      class="article-item-photo zooming wow fadeInDown"
                      data-wow-delay=".4s"
                      data-wow-offset="150"
                    >
                      {" "}
                      <img src={img12} alt="" class="img-fluid" />{" "}
                    </div>
                    <div class="blogimgtpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <div class="blogimgarelement ">
                      {" "}
                      <img src={element2png} alt="" class="ani-left-right" />
                    </div>
                    <div
                      class="blogimgrbbtn editbottom wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      {" "}
                      <a class="btn btn-primary mr-4 fr " href="#">
                        READ BLOG <i class="icon ion-ios-arrow-thin-right"></i>
                      </a>{" "}
                    </div>
                    <div class="bolgimgtitle ">
                      Practical Tips for Exploring Armenia's Hidden Gems
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 tabnone  mobnone">
                  <div class="article-item bl1 mb70">
                    <div class="blogelementsmallx ">
                      {" "}
                      <img
                        src={elementsmallxpng}
                        alt=""
                        class="wow pulse"
                        data-wow-delay="300ms"
                        data-wow-iteration="infinite"
                        data-wow-duration="2s"
                      />
                    </div>
                    <div class="blogpartpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <h5
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.4s"
                      data-wow-duration="1s"
                    >
                      BLOG - 24
                    </h5>
                    <h6
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.6s"
                      data-wow-duration="1s"
                    >
                      LEISURE TRAVEL
                    </h6>
                    <div
                      class="article-item-photo zooming wow fadeInDown"
                      data-wow-delay=".4s"
                      data-wow-offset="150"
                    >
                      {" "}
                      <img src={img13} alt="" class="img-fluid" />{" "}
                    </div>
                    <div class="blogimgtpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <div class="blogimgarelement ">
                      {" "}
                      <img src={element2png} alt="" class="ani-left-right" />
                    </div>
                    <div
                      class="blogimgrbbtn wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      {" "}
                      <a class="btn btn-primary mr-4 fr " href="#">
                        READ BLOG <i class="icon ion-ios-arrow-thin-right"></i>
                      </a>{" "}
                    </div>
                    <div class="bolgimgtitle ">
                      Why You Should Explore Beyond the Tourist Trail in
                      Armenia?
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          {/* </div> */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TermsandConditions;
