import React from "react";
import WhatsAppIcon from "../Pages/img/whatsapp.png";
import "../Pages/css/WhatsAppButton.css";

const WhatsAppButton = () => {
  const phoneNumber = "97444282655";

  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202.`}
      className="float"
      target="_blank"
    >
      <img
        src={WhatsAppIcon}
        alt="WhatsApp Icon "
        className="my-float position-absolute"
      />
    </a>
  );
};

export default WhatsAppButton;
