import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

// import logopng from "./img/logo.png";
// import biglogopng from "./img/biglogobg.png";
import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";
import uparrowelement from "../img/uparrowelement.png";
import playthailand from "../img/playthailand.png";
import camthailand from "../img/camerathailand.png";
import sharethailand from "../img/sharethailand.png";
import penthailand from "../img/writingthailand.png";
import compassthailand from "../img/compassthailand.png";
import thailandcarouselpic from "../img/Grand-palace.png";
import MesgPicthailand from "../img/MesgPicthailand.png";
import accplus from "../img/accplus.png";
import blogimg2 from "../img/blogimg2.jpg";
import blogimg1 from "../img/blogimg1.jpg";
import carousel1 from "../img/carousel1.png";
import DegreeCareer from "../img/icons8-degree-60.png";
import frontview from "../img/front-view.jpg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/CareerDetail.css";
import "../js/timeline";
import "../js/script";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Partners from "../../Components/Partners";
import GlobalPartnerForm from "../GlobalPartnersPage/GlobarPartnerForm";
import GlobalPartnerSection1 from "../GlobalPartnersPage/GlobalPartnerSection1";
import MannaiHolidayHeroSection from "./MannaiHolidayHeroSection";
import MannaiHolidayHeroSection2 from "./MannaiHolidaySection2";
import MannaiHolidaySection3 from "./MannaiHolidaySection3";

const MannaiHoliday = () => {
  const location = useLocation();
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <div class="main-body theme-orange dark-horizontal mannaiholiday overflow-hidden">
      <div class="animsition">
        <div class="wrapper">
          <ReactTitle title="Mannai Holiday | Mannai Travel" />
          <Header />
          <MannaiHolidayHeroSection />
          <MannaiHolidayHeroSection2 />
          <MannaiHolidaySection3 />
          <GlobalPartnerForm />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MannaiHoliday;
