import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";
import ReactVisibilitySensor from "react-visibility-sensor";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import WOW from "wow.js";
import CountUp from "react-countup";

import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";
import mannairoundtxt from "../img/Mannai Circle 001@72x.png";

import uparrowelement from "../img/uparrowelement.png";
import wwapluselement from "../img/wwapluselement.png";
import ltbannerbg from "../img/ltbannerbg.jpg";
import ltbiglogo from "../img/ltbiglogo.png";
import smallxltm from "../img/smallxltm.png";
import lteimgbg from "../img/lteimgbg.png";
import ltmimg from "../img/ltmimg.png";
import keybenibg from "../img/keybenibg.jpg";
import grayuparr from "../img/grayuparr.png";
import likeimg from "../img/like.png";
import smallxgray from "../img/smallxgray.png";
import btbg from "../img/BussinessBanner.jpg";
import btmgimg from "../img/btmgimg.png";
import btmimg from "../img/btmimg.png";
import btarrow from "../img/btarrow.png";
import ambgimg from "../img/ambgimg.png";
import amimg from "../img/amimg.png";
import demobg from "../img/demobg.jpg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/Businesstravel.css";
import "../css/globarpartner.css";
import "../js/script";
import Requestform from "../../Components/Requestform";

const Businesstravel = () => {
  const location = useLocation();
  const [acc1, setAcc1] = useState(true);
  const [acc2, setAcc2] = useState(false);
  const [acc3, setAcc3] = useState(false);
  const [acc4, setAcc4] = useState(false);
  const [acc5, setAcc5] = useState(false);

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inclass: "fade-in",
        outclass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    $(document).ready(function () {
      $(".toggle-accordion").click(function () {
        const target = $($(this).data("target"));
        target.collapse("toggle");

        // Toggle the plus/minus icon
        const icon = $(this).find(".icon-plus");
        icon.toggleClass("icon-minus");
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation
    // $(".a-counter").countdown(() => {});
    new WOW().init();
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <div className="main-body theme-orange dark-horizontal businesstravel overflow-hidden ">
      <div className="animsition">
        <div className="wrapper">
          <ReactTitle title="Business Travel | Mannai Travel" />
          <Header />

          <div className="slide-container">
            <div className="slide-bg wow pulse">
              <div className="inside opacityBG ">
                <img
                  src={btbg}
                  alt=""
                  className=" img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div className="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>
              {/* <div
                className="pluselementglobal wow rotateIn"
                style={{ visibility: "visible", animationName: "rotateIn" }}
            >
                {" "}
                <img src={pluselement} alt="" />
            </div> */}
              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>
              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>

              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>
              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                    BUSINESS TRAVEL
                  </h3>
                  <p
                    className="normaltext  wow slideInRight"
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    {" "}
                    Our core expertise lies in organizing and overseeing
                    business travel efficiently. We assist companies in
                    optimizing their business travel expenditures, providing an
                    array of travel-related services delivered with exceptional
                    service standards.{" "}
                  </p>
                  <div className="readbutton">
                    <div className="leftarrowanimationelement">
                      {" "}
                      <img
                        src={element2png}
                        alt=""
                        className="ani-left-right"
                      />
                    </div>
                    {/* <Link to={``} > */}
                    <a className="btn btn-primary   " href="#Businesstravel">
                      READ MORE <i className="icon ion-ios-arrow-thin-down"></i>
                    </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid btm" id="Businesstravel">
            <div className="slide-body btmbg">
              <div className="row ">
                <div className=" col-lg-6">
                  <div className="tmpulusbot ">
                    {" "}
                    {/* <img src={roundtxtpng} className="wow rotateIn" alt="" /> */}
                  </div>
                  <div className="col-md-12 travelmanagementpart ">
                    <div className="d-flex plushead">
                      <div className="plusrotate">
                        <img
                          src={roundtxtpng}
                          className="wow rotateIn"
                          alt=""
                        />
                      </div>
                      <div
                        className="wow fadeInLeft NormalHeading"
                        data-wow-delay="0.2s"
                        data-wow-duration="1s"
                      >
                        Business Travel Management{" "}
                      </div>
                    </div>
                    <div
                      className="wow fadeInRight SubHeading"
                      data-wow-delay="0.4s"
                      data-wow-duration="1s"
                    >
                      The perfect solution is as <br />
                      unique as your enterprise!
                    </div>
                    <div
                      className="wow fadeInLeft    Pyara "
                      data-wow-delay="0.6s"
                      data-wow-duration="1s"
                    >
                      Discover our range of solutions to ﬁnd the one that
                      perfectly aligns with your requirements. Whether you
                      prefer a straightforward approach, a customized solution,
                      or a high-touch service, we'll stand beside you, ensuring
                      every journey is meaningful.
                    </div>
                    <div className="serviceelementbtm ">
                      {" "}
                      <img
                        src={element2png}
                        alt=""
                        className="ani-left-right"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-10 col-lg-6 p0  position-relative">
                  <div className="btmgimg wow pulse d-lg-block d-none">
                    <img src={btmgimg} classNameName="mobbtwoman" alt="" />
                  </div>
                  <div className="btmimg d-lg-block d-none ">
                    <img
                      src={btmimg}
                      className="wow rotateIn mobbtwomanbg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid amgradientbg">
            <div className="row ">
              <div className="col-lg-6">
                <div className="col-md-12  accmanagementpartb  ">
                  <div className="d-flex">
                    <div className="plusrotate">
                      <img src={roundtxtpng} className="wow rotateIn" alt="" />
                    </div>
                    <div
                      className="wow fadeInLeft text-white NormalHeading "
                      data-wow-delay="0.2s"
                      data-wow-duration="1s"
                    >
                      ACCOUNT MANAGEMENT
                    </div>
                  </div>
                  <div
                    className="wow fadeInLeft Pyara"
                    data-wow-delay="0.4s"
                    data-wow-duration="1s"
                  >
                    Establishing a robust travel program today is similar to
                    constructing a complex puzzle: it demands a skilled
                    strategist. With Mannai Travel's Account Management, we'll
                    assist you in creating a travel program that not only pushes
                    boundaries but also reﬂects your business identity, all
                    while proactively averting costly pitfalls.
                  </div>
                  <div className="serviceelementbtm ">
                    {" "}
                    <img src={btarrow} alt="" className="ani-left-right" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 p0 position-relative">
                <div className="accmanagementimagebg wow fadeInRight ani-move2">
                  {" "}
                  <img src={ambgimg} />{" "}
                </div>
                <div className="amimg">
                  {" "}
                  <img src={amimg} alt="" />{" "}
                </div>
              </div>
            </div>

            <div className="row customerper">
              <div className="col-lg-6 p0">
                <div className="nintyeight ">
                  <div className="inside a-counter" data-value="98">
                    <ReactVisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <CountUp start={0} end={98} duration={10}>
                          {({ countUpRef, start }) => (
                            <div>
                              <span ref={countUpRef} />
                              <span className="percentage">%</span>
                              {start()}
                            </div>
                          )}
                        </CountUp>
                        // <CountUp end={98} duration={10}></CountUp>
                      )}
                    </ReactVisibilitySensor>
                    {/* <span className="percentage">%</span> */}
                  </div>{" "}
                </div>
                <div className="cr">
                  {" "}
                  <h4
                    className="wow fadeInLeft"
                    data-wow-delay="0.4s"
                    data-wow-duration="1s"
                  >
                    CUSTOMER RETENTION
                  </h4>{" "}
                </div>
                <div className="nintyfive">
                  <div className="inside a-counter">
                    <ReactVisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <CountUp start={0} end={95} duration={10}>
                          {({ countUpRef, start }) => (
                            <div>
                              <span ref={countUpRef} />
                              <span className="percentage">%</span>
                              {start()}
                            </div>
                          )}
                        </CountUp>
                        // <CountUp end={95} duration={10}></CountUp>
                      )}
                    </ReactVisibilitySensor>
                    {/* <span className="percentage">%</span> */}
                  </div>{" "}
                </div>
                <div className="cs">
                  {" "}
                  <h4
                    className="wow fadeInLeft"
                    data-wow-delay="0.5s"
                    data-wow-duration="1s"
                  >
                    CUSTOMER SATISFACTION
                  </h4>{" "}
                </div>
              </div>
              <div className="col-lg-6 comitted">
                <div
                  className="col-md-12  amtext  wow fadeInRight"
                  data-wow-delay="0.2s"
                  data-wow-duration="1s"
                >
                  <div
                    className=" px-2 wow fadeInRight SubHeading"
                    data-wow-delay="0.3s"
                    data-wow-duration="1s"
                  >
                    {" "}
                    Having a committed account manager at your service means you
                    will:{" "}
                  </div>
                  <ul>
                    <li>
                      Unlock exclusive corporate deals you've yet to explore.
                    </li>
                    <li>
                      Explore an extensive inventory, whether it's flights or
                      hotels, all with unbeatable rates you've never seen
                      before.
                    </li>
                    <li>
                      Discover a more streamlined travel program tailored to
                      meet your precise requirements.
                    </li>
                    <li>
                      Gain entry to resources and comprehensive reports that
                      dissect critical trends, facilitating worldwide
                      consolidation and uniformity.
                    </li>
                    <li>
                      Strengthen your travel policy and deploy tools that
                      elevate your efficiency.
                    </li>
                  </ul>
                  <div className="amtextelementbtm ">
                    {" "}
                    <img src={btarrow} alt="" className="ani-left-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid btm businesslike position-relative">
            <div className="slide-body counterwhitebg">
              <div className="row ">
                <div className="col-lg-12 countpart">
                  <div className="countpulusbot wow rotateIn">
                    {" "}
                    <img src={pluselement} alt="" />
                  </div>
                  {/* <div className="graypluselement  position-absolute rotateIn d-none d-sm-block">
                    {" "}
                    <img src={pluselement} alt="" />
                  </div> */}
                  <div className="countuparrowelement">
                    {" "}
                    <img
                      src={uparrowelement}
                      alt=""
                      className="ani-top-bottom"
                    />
                  </div>
                  <div className="countelementsmallx d-none d-sm-block">
                    {" "}
                    <img
                      src={elementsmallxpng}
                      alt=""
                      className="wow pulse pulseimagesmallx"
                      data-wow-delay="300ms"
                      data-wow-iteration="infinite"
                      data-wow-duration="2s"
                    />
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      src={pluselement}
                      alt=""
                      className="plusimg wow rotateIn"
                    />
                    <div
                      classNameName="d-flex mt-5  thumb"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <h5
                        className="wow fadeInLeft pt-md-5 thumbtext"
                        data-wow-delay="0.4s"
                        data-wow-duration="1s"
                      >
                        We've excelled thus far and are now <br />
                        pushing the boundaries even further{" "}
                      </h5>
                      <div className="likeimg wow flash">
                        {" "}
                        <img src={likeimg} alt="" className="thumblike" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="facts row ">
                <div className="col-md-3 fact-item">
                  <div className=" font-abril text-blue">
                    <div className="inside a-counter mplusblue">
                      <ReactVisibilitySensor
                        partialVisibility
                        offset={{ bottom: 100 }}
                      >
                        {({ isVisible }) => (
                          <CountUp start={0} end={10} duration={7}>
                            {({ countUpRef, start }) => (
                              <div>
                                <span ref={countUpRef} />
                                <span className="fact-item-param mplusblue">
                                  M+
                                </span>
                                {start()}
                              </div>
                            )}
                          </CountUp>
                        )}
                      </ReactVisibilitySensor>
                      {/* <CountUp end={10} duration={3}></CountUp>
                      <span className="fact-item-param mplusblue">M+</span> */}
                    </div>
                    <p className="count-textblue">
                      HAPPY TRAVELLERS
                      <br />
                      PER YEAR
                    </p>
                  </div>
                </div>
                <div className="col-md-3 fact-item">
                  <div className=" font-abril text-blue">
                    <div className="inside a-counter mplusblue">
                      <ReactVisibilitySensor
                        partialVisibility
                        offset={{ bottom: 200 }}
                      >
                        {({ isVisible }) => (
                          <CountUp start={0} end={45} duration={7}>
                            {({ countUpRef, start }) => (
                              <div>
                                <span ref={countUpRef} />
                                <span className="fact-item-param mplusblue">
                                  +
                                </span>
                                {start()}
                              </div>
                            )}
                          </CountUp>
                        )}
                      </ReactVisibilitySensor>
                      {/* <CountUp end={45} duration={5}></CountUp>{" "}
                      <span className="fact-item-param mplusblue">+</span> */}
                    </div>
                    <p className="count-textblue">
                      YEARS IN BUSINESS
                      <br />
                      TRAVEL
                    </p>
                  </div>
                </div>
                <div className="col-md-3 fact-item">
                  <div className=" font-abril text-blue">
                    <div className="inside a-counter mplusblue">
                      <ReactVisibilitySensor
                        partialVisibility
                        offset={{ bottom: 200 }}
                      >
                        {({ isVisible }) => (
                          <CountUp start={0} end={100} duration={7}>
                            {({ countUpRef, start }) => (
                              <div>
                                <span ref={countUpRef} />
                                <span className="fact-item-param mplusblue">
                                  +
                                </span>
                                {start()}
                              </div>
                            )}
                          </CountUp>
                        )}
                      </ReactVisibilitySensor>
                      {/* <CountUp end={100} duration={7}></CountUp>{" "}
                      <span className="fact-item-param mplusblue">+</span> */}
                    </div>
                    <p className="count-textblue">TRAVEL EXPERTS</p>
                  </div>
                </div>
                <div className="col-md-3 fact-item">
                  <div className=" font-abril text-blue">
                    <div className="inside a-counter mplusblue">
                      <ReactVisibilitySensor
                        partialVisibility
                        offset={{ bottom: 200 }}
                      >
                        {({ isVisible }) => (
                          <CountUp start={0} end={200} duration={7}>
                            {({ countUpRef, start }) => (
                              <div>
                                <span ref={countUpRef} />
                                <span className="fact-item-param mplusblue">
                                  +
                                </span>
                                {start()}
                              </div>
                            )}
                          </CountUp>
                        )}
                      </ReactVisibilitySensor>
                      {/* <CountUp end={200} duration={8}></CountUp>{" "}
                      <span className="fact-item-param mplusblue">+</span> */}
                    </div>
                    <p className="count-textblue">CORPORATES</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="keyben ">
            <div className="slide-container">
              <div className="slide-bg">
                <div
                  className="inside"
                  style={{ backgroundImage: `url(${keybenibg})` }}
                ></div>
              </div>
              <div className="container">
                <div className="keybenelement2 ">
                  {" "}
                  <img src={element2png} alt="" className="ani-left-right" />
                </div>
                <h5 className="wow slideInLeft " data-wow-duration="1.2s">
                  Key benefits of using Mannai’s
                  <br />
                  Corporate Booking Tool
                </h5>
                <div className="row">
                  <div className="col-lg-6 finfea">
                    <div className="adminfeatext "> ADMIN FEATURE</div>
                    <div className=" adminfeabg wow wow slideInLeft">
                      <div className="contentpartadminfea pb-2">
                        <ul>
                          <li className="wow fadeInLeft data-wow-delay='.1s'  ">
                            100% Automated Travel Booking solutions. Real-time
                            ticketing{" "}
                          </li>
                          <li className="wow fadeInLeft data-wow-delay='1.1s'  ">
                            Exhaustive search result display through Multiple
                            GDS and LCC
                          </li>
                          <li className="wow fadeInLeft data-wow-delay='1.2s'  ">
                            Airline API Integrations for all products{" "}
                          </li>
                          <li className="wow fadeInLeft data-wow-delay='1.3s'  ">
                            Online multi-tier travel approval workflow{" "}
                          </li>
                          <li className="wow fadeInLeft data-wow-delay='1.4s'  ">
                            Online Cancellations / ability to track amendments
                          </li>
                          <li className="wow fadeInLeft data-wow-delay='1.5s' ">
                            Ability to Sort / Filter and Email results to
                            Travelers{" "}
                          </li>
                          <li className="wow fadeInLeft data-wow-delay='1.6s'  ">
                            Multiple payment options: BTA, Credit Card & Deposit
                          </li>
                          <li className="wow fadeInLeft data-wow-delay='1.7s'  ">
                            User roles to control access levels for each
                            employee
                          </li>
                          <li className="wow fadeInLeft data-wow-delay='1.8s'  ">
                            Accounts, Travel administrator, IT, Travelers &
                            Approvers
                          </li>
                          <li className="wow fadeInLeft data-wow-delay='1.9s' ">
                            Ability to display and sell Published Fares & GDS
                            Private Fares
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 adminfea">
                    <div className=" finfeabg wow slideInRight">
                      <div className="contentpartadminfea pb-2">
                        <ul>
                          <li className="wow fadeInRight data-wow-delay='.2s' ">
                            Automated Commercials computation, ensuring 100%
                            transparency
                          </li>
                          <li className="wow fadeInRight data-wow-delay='.3s' ">
                            Powerful Expense and Accounting reports, analytics
                            and visual charts enabling you to track your travel
                            expenditure
                          </li>
                          <li className="wow fadeInRight data-wow-delay='.4s' ">
                            track your travel expenditure Band-wise /
                            product-wise real-time reports{" "}
                          </li>
                          <li className="wow fadeInRight data-wow-delay='.5s' ">
                            Online multi-tier travel approval workflow{" "}
                          </li>
                          <li className="wow fadeInRight data-wow-delay='.6s'  ">
                            Accounting XML feeds for auto-import into Financial
                            Package
                          </li>
                          <li className="wow fadeInRight data-wow-delay='.7s' ">
                            Security Audit reports
                          </li>
                          <li className="wow fadeInRight data-wow-delay='.8s'  ">
                            Real-time missed Saving reporting
                          </li>
                          <li className="wow fadeInRight data-wow-delay='.9s'  ">
                            Automated passing of Tour-codes
                          </li>
                          <li className="wow fadeInRight data-wow-delay='1s'  ">
                            Various MIS Reports to help ﬁnance team to pull out
                            management reports{" "}
                          </li>
                          <li className="wow fadeInRight data-wow-delay='1.1s'  ">
                            Mordern Dashboards
                          </li>
                          <li className="wow fadeInRight data-wow-delay='1.2s'  ">
                            360 Degree overview of your spendings vs savings etc
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="finfeatext typing" data-wow-duration="1.2s">
                      FINANCE FEATURE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Requestform />

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Businesstravel;
