import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";
import emailjs from "@emailjs/browser";

import WOW from "wow.js";

import pluselement from "../img/pluselement.png";

import elementsmallxbig from "../img/elementsmallxbig.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/CareerDetail.css";
import "../js/timeline";
import "../js/script";

import CareerSectionData from "./CareerSection3.json";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Partners from "../../Components/Partners";
import CareerSection3 from "./CareerSection3";

const CareerDetailSection2 = () => {
  const params = useParams();
  const location = useLocation();
  const [blog, setBlog] = useState("");
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    EmailID: "",
    MobileNumber: "",
    CurrentCompany: "",
    CurrentCTC: "",
    CurrentLocation: "",
    QID: "",
    file: "",
  });

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(params.ID);
    console.log(CareerSectionData);
    const selectedBlog = CareerSectionData.CareerList.find(
      (e) => e.CareerId === params.ID
    );
    setBlog(selectedBlog);
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, [params.ID]);
  const handleChange = (e) => {
    const { name, value, type } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "file" ? e.target.files[0] : value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_e7q0n79";
    const templateId = "template_fnrhm98";
    const publicKey = "KwGs19TOytdfp1w5Z";
    const recipientEmail = "travel.services@mannai.com.qa";
    const templateParams = {
      ...formData,

      to_email: recipientEmail,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        alert("Email sent successfully!");
        setFormData({
          FirstName: "",
          LastName: "",
          EmailID: "",
          MobileNumber: "",
          CurrentCompany: "",
          CurrentCTC: "",
          CurrentLocation: "",
          QID: "",
          file: "",
        });
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("Error sending email! Please check the console for details.");
      });
  };
  return (
    <>
      <div className="main-body theme-orange dark-horizontal careersection1 overflow-hidden">
        <div className="animsition carblunbgcd">
          <div className="wrapper parallax-start">
            <ReactTitle title="CareerSection1 | Mannai Travel" />

            <div className="section section-item p0">
              <div className="container-fluid">
                <div className="slide-body cdebnbg">
                  <div className="row ">
                    <div className="col-lg-12">
                      <div
                        className="col-md-12  cdecontent1cd  wow fadeInRight"
                        data-wow-delay="0.2s"
                        data-wow-duration="1s"
                      >
                        <p className="mt-3 ">
                          We are seeking a highly motivated and experienced
                          Project Manager to join our online travel company. As
                          a Project Manager, you will play a critical role in
                          leading and delivering carious projects that
                          contribute to our company's success and growth. If you
                          have a passion for travel and strong track record in
                          project management, we want to hear from you.
                        </p>
                      </div>
                      <div
                        className="col-md-12  cdecontentcd wow fadeInRight"
                        data-wow-delay="0.2s"
                        data-wow-duration="1s"
                      >
                        <div>
                          <div
                            className=" cdcontentpluselementcd wow rotateIn"
                            style={{
                              visibility: "visible",
                              animationName: "rotateIn",
                            }}
                          >
                            {" "}
                            <img
                              src={pluselement}
                              alt=""
                              className="wow rotateIn"
                            />
                          </div>
                          <h5>RESPONSIBILITIES</h5>
                        </div>
                        <ol className="mt-3 ">
                          {" "}
                          <li>
                            Project Planning: Develop Comprehensive project
                            plans, outlining scope, goals, deliverables, and
                            timelines. Ensure projects are well-defined,
                            well-planned, and aligned with the company's
                            strategic objectives.
                          </li>
                          <li>
                            Team Leadership: Lead cross-functional teams,
                            including developers, designers, and other project
                            stakeholders. Motivate and guide team members to
                            achieve project goals and milestones.
                          </li>
                          <li>
                            Risk Management: Identify and proactively manage
                            project risks and issues. Develop contingency plans
                            to mitigate potential roadblocks.
                          </li>
                          <li>
                            Resource Allocation: Efficiently allocate and manage
                            project resources, including budgets, personnel, and
                            technology.
                          </li>
                          <li>
                            Communication: Maintain clear and effective
                            communication with team members and stakeholders,
                            providing regular project updates and status
                            reports.
                          </li>
                          <li>
                            Quality Assurance: Ensure the highest level of
                            quality in project deliverables. Conduct regular
                            quality checks and testing to meet or exceed project
                            expectations.
                          </li>
                          <li>
                            Vendor and Partner Management: Collaborate with
                            third-party vendors and partners to ensure seamless
                            project execution and timely delivery.
                          </li>
                          <li>
                            Performance Tracking: Monitor and evaluate project
                            performance against key performance indicators
                            (KPIs). Implement improvements and changes as
                            necessary to meet project goals.
                          </li>
                          <li>
                            Documentation: Maintain comprehensive project
                            documentation, including project charters, plans,
                            and post-project evaluations.
                          </li>
                          <li>
                            Continuous Improvement: Identify opportunities for
                            process improvement and implement best practices to
                            enhance project management within the organization.
                          </li>
                        </ol>
                        <div>
                          <div
                            className="cdcontentpluselement1cd wow rotateIn"
                            style={{
                              visibility: "visible",
                              animationName: "rotateIn",
                            }}
                          >
                            {" "}
                            <img
                              src={pluselement}
                              alt=""
                              className="wow rotateIn"
                            />
                          </div>
                          <h5>REQUIREMENTS:</h5>
                        </div>
                        <ol className="mt-3">
                          {" "}
                          <li>
                            {" "}
                            Bachelor's degree in Business, Project Management,
                            or a related field. PMP certification is a plus.
                          </li>
                          <li>
                            Proven experience as a Project Manager in the travel
                            industry or related field. Strong leadership,
                            organizational, and communication skills.
                          </li>
                          <li>
                            Proficiency in project management software and
                            tools.
                          </li>
                          <li>
                            Excellent problem-solving and decision-making
                            abilities.
                          </li>
                          <li>
                            Ability to work in a fast-paced, dynamic environment
                            and manage multiple projects simultaneously.
                          </li>
                          <li>
                            Passion for travel and a deep understanding of the
                            online travel industry is a plus.
                          </li>
                        </ol>
                        <div className="">
                          <div
                            className="cdcontentpluselement2cd wow rotateIn"
                            style={{
                              visibility: "visible",
                              animationName: "rotateIn",
                            }}
                          >
                            {" "}
                            <img
                              src={pluselement}
                              alt=""
                              className="wow rotateIn"
                            />
                          </div>
                          <h5>APPLY</h5>
                        </div>
                        <p className="mt-3">
                          {" "}
                          Join our team and be a key player in shaping the
                          future of our online travel company. If your are a
                          driven and results-oriented Project Manager with
                          passion for the travel industry, we encourage you to
                          apply today and help us deliver exceptional travel
                          experiences to our customers{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit} className=" wow fadeInUp">
                    <div className="row ">
                      <div className="col-lg-12 col-12">
                        <div className="cdeformpartcd">
                          <div className="cdeformcd">
                            <h5>FIRST NAME*</h5>
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="First Name*"
                              name="FirstName"
                              value={formData.FirstName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="cdeformcd">
                            <h5>LAST NAME* </h5>
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Last Name*"
                              name="LastName"
                              value={formData.LastName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="cdeformcd">
                            <h5>EMAIL ID* </h5>
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Email ID*"
                              name="EmailID"
                              value={formData.EmailID}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="cdeformcd">
                            <h5>MOBILE NUMBER* </h5>
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Mobile Number*"
                              name="MobileNumber"
                              value={formData.MobileNumber}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="cdeformcd">
                            <h5>CURRENT COMPANY </h5>
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Current Company"
                              name="CurrentCompany"
                              value={formData.CurrentCompany}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="cdeformcd">
                            <h5>CURRENT CTC </h5>
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Current CTC"
                              name="CurrentCTC"
                              value={formData.CurrentCTC}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="cdeformcd">
                            <h5>CURRENT LOCATION</h5>
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Current Location"
                              name="CurrentLocation"
                              value={formData.CurrentLocation}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="cdeformcd">
                            <h5>QID NUMBER IF APPLICABLE </h5>
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="QID"
                              name="QID"
                              value={formData.QID}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="cdeformcd">
                            <h5>ATTACH FILE* </h5>
                            <label
                              htmlFor="fileInput"
                              className="custom-file-upload"
                            >
                              <i className="fa fa-cloud-upload"></i>
                            </label>
                            {formData.file === "" || formData.file === null ? (
                              <input
                                type="file"
                                id="fileInput"
                                name="file"
                                className="form-control form-control-custom"
                                onChange={handleChange}
                              />
                            ) : (
                              <>
                                <lable className="custom-file-upload pr-2">
                                  {formData.file.name}
                                </lable>
                                <button
                                  type="button"
                                  onClick={() =>
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      ["file"]: "",
                                    }))
                                  }
                                  className="btn-primary  px-2 py-2 form-control form-control-custom"
                                >
                                  Reupload
                                </button>
                              </>
                            )}
                          </div>

                          {/* <div className="cdeformcd">
                  <div className="text-center mt-50 ">
                    <a className="btn w100 btn-primary mr-4  " href="#">
                      CLICK TO UPLOAD{" "}
                    </a>
                  </div>
                </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-50 pb-4">
                      <button
                        className="btn w50 btn-primary mr-4  "
                        type="submit"
                      >
                        APPLY{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="d-flex align-items-center justify-content-start socontentpartcd">
                <div className="d-flex align-items-center justify-content-center">
                  <h3
                    className="wow fadeInRight"
                    data-wow-delay="0.2s"
                    data-wow-duration="1s"
                  >
                    SIMILAR OPENINGS
                  </h3>
                </div>
                <div className="sosmallxbncd  d-lg-flex align-items-center justify-content-center">
                  <img
                    src={elementsmallxbig}
                    alt=""
                    className="wow pulse"
                    data-wow-delay="300ms"
                    data-wow-iteration="infinite"
                    data-wow-duration="2s"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerDetailSection2;
