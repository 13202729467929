import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import element2png from "../img/element2.png";
import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";

import "../css/Section3.css";
import "../js/timeline";
import "../js/script";

import CareerSectionData from "./CareerSection3.json";

const CareerSection3 = () => {
  const location = useLocation();
  const [blog, setBlog] = useState("");
  const [visibleBlogs, setVisibleBlogs] = useState(4);

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);
  const handleExploreMore = () => {
    setVisibleBlogs((prev) => prev + 4);
  };

  const handleExploreLess = () => {
    setVisibleBlogs(4);
  };
  const isExploreLessVisible =
    visibleBlogs >= CareerSectionData?.CareerList?.length;

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  return (
    <>
      <div className="main-body theme-orange dark-horizontal section3 overflow-hidden">
        <div className="animsition">
          <div className="wrapper">
            <ReactTitle title="Career | Mannai Travel" />
            <div className="section section-item p0 carblunbg">
              <div className="  ">
                <div className="slide-body py-5 px-lg-3">
                  <div className="section-item text-left mobmt150">
                    <div className="container-fluid screen427">
                      <div className="row">
                        {CareerSectionData?.CareerList?.slice(
                          0,
                          visibleBlogs
                        ).map((e, i) => (
                          <div className="col-12 col-lg-6 p-4 p-md-3" key={i}>
                            <div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <div className="wow rotateIn">
                                    <img src={pluselement} alt="" />
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: "-50px",
                                      marginTop: "-50px",
                                    }}
                                  >
                                    <h5
                                      className="wow fadeInUp text-white fs20 pb-3 text-start"
                                      data-wow-delay="0.4s"
                                      data-wow-duration="1s"
                                      style={{ marginLeft: "0px" }}
                                    >
                                      {e.CareerId}
                                    </h5>
                                    <h6
                                      className="wow fadeInUp text-white fs18 mb-3 text-start "
                                      data-wow-delay="0.6s"
                                      data-wow-duration="1s"
                                    >
                                      {e.title}
                                    </h6>
                                  </div>
                                </div>
                                <div>
                                  <img
                                    src={elementsmallxpng}
                                    alt=""
                                    className="wow pulse d-none d-md-block"
                                    data-wow-delay="300ms"
                                    data-wow-iteration="infinite"
                                    data-wow-duration="2s"
                                  />
                                </div>
                              </div>
                              <div
                                className="position-relative d-flex align-items-center justify-content-center"
                                style={{ height: "65vh" }}
                              >
                                <img
                                  src={pluselement}
                                  alt=""
                                  className=" wow rotateIn position-absolute"
                                  style={{ zIndex: 10 }}
                                />
                                <img
                                  src={require("../img/" + e.image)}
                                  alt=""
                                  className="object-fit-cover position-relative filter-opacity"
                                  width="100%"
                                  style={{ height: "60vh" }}
                                />
                                <div
                                  className=" wow fadeInUp position-absolute d-grid"
                                  style={{ bottom: "10%", right: "5%" }}
                                  data-wow-delay="0.4s"
                                >
                                  <img
                                    src={element2png}
                                    alt=""
                                    className="ani-left-right mb-2"
                                  />
                                  <Link
                                    to={`/Career/CareerDetails/${e.CareerId}`}
                                  >
                                    <a
                                      className="btn btn-primary fr "
                                      href="#"
                                      onClick={handleScrollToTop}
                                    >
                                      JOB DETAILS{" "}
                                      <i className="icon ion-ios-arrow-thin-right"></i>
                                    </a>
                                  </Link>
                                </div>
                              </div>
                              <p className="text-white fs-5 text-left wow fadeInUp ">
                                {" "}
                                {e.title}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="text-center">
                      {isExploreLessVisible ? (
                        <button
                          className="btn btn-primary mr-4 explorelap"
                          onClick={handleExploreLess}
                        >
                          EXPLORE LESS JOBS{" "}
                          <i className="icon ion-ios-arrow-thin-left"></i>
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary mr-4 explorelap"
                          onClick={handleExploreMore}
                        >
                          EXPLORE MORE JOBS{" "}
                          <i className="icon ion-ios-arrow-thin-right"></i>
                        </button>
                      )}
                    </div>    */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerSection3;
