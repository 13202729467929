import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

// import logopng from "./img/logo.png";
// import biglogopng from "./img/biglogobg.png";
import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";

import element2png from "../img/element2.png";
import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";
import uparrowelement from "../img/uparrowelement.png";
import playthailand from "../img/playthailand.png";
import camthailand from "../img/camerathailand.png";
import sharethailand from "../img/sharethailand.png";
import penthailand from "../img/writingthailand.png";
import compassthailand from "../img/compassthailand.png";
import facebook from "../img/mannaifacebook.png";
import instagram from "../img/mannaiinstagram.png";
import twitter from "../img/mannaitwitter.png";
import linkedin from "../img/mannailinkedin.png";
import snapchat from "../img/mannaisnapchat.png";

// Json Data
import NewsData from "./NewsData/NewsData.json";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/visitthailand.css";
import "../js/timeline";
import "../js/script";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Partners from "../../Components/Partners";

const News = () => {
  const params = useParams();
  const location = useLocation();
  const [blog, setBlog] = useState("");
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(params.ID);
    const selectedBlog = NewsData.find((e) => e.newsId === params.ID);
    setBlog(selectedBlog);
    // console.log(BlogData.find((e) => e.BlogID === location.state));
    // setBlog(BlogData.find((e) => e.BlogID === location.state));

    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, [params.ID]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  return (
    <div class="main-body theme-orange dark-horizontal visithailand overflow-hidden">
      <div class="animsition">
        <div class="wrapper">
          <ReactTitle title="News | Mannai Travel" />
          <Header />

          <div class="slide-container">
            {/* for banner  */}
            <div class="slide-bg wow pulse">
              <div class="inside  visitthaillandbanner"></div>
            </div>
            <div class="container">
              {/* pluse symbol  */}
              <div class="techpulusbot wow fadeInRight">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>
              {/* right arrow  */}
              <div class="serviceelementjwwab ">
                {" "}
                <img src={element2png} alt="" class="ani-left-right" />
              </div>
              {/* big com logo  */}
              <div class="wwasmlogo">
                {" "}
                <img src={biglogoservicepng} alt="" />
              </div>
              {/* plus element  */}
              <div class="wwabpluselement wow rotateIn">
                {" "}
                <img src={pluselement} alt="" />
              </div>
              {/* upper arrow  */}
              <div class="uparrowelement">
                {" "}
                <img src={uparrowelement} alt="" class="ani-top-bottom" />
              </div>
              {/* 5*6 int symbol  */}
              <div class="techelementsmallx">
                {" "}
                <img
                  src={elementsmallxpng}
                  alt=""
                  class="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>
              {/* banner text  */}
              <div class="techbannertext">
                <p
                  class="wow slideInLeft text-white fw-bold fs-1 visthailandbannerfont"
                  data-wow-duration="1.2s"
                >
                  {blog.newsId}
                </p>
                <p
                  class="normaltext  wow slideInRight fw-bold visthailandnormalfont"
                  data-wow-delay=".1s"
                  data-wow-duration=".80s"
                >
                  {blog.Title}
                </p>
              </div>
              {/* button  */}
              <div
                class=" vtpackbtnwow fadeInUp d-flex ms-5"
                data-wow-delay="0.4s"
              >
                {" "}
                <a class="btn btn-primary mr-4 fr" href="#">
                  BOOK SOUTH AFRICA PACKAGE
                  <i class="icon ion-ios-arrow-thin-right"></i>
                </a>{" "}
              </div>
            </div>
          </div>

          <div className="row bgthailandblue p-4">
            {/* 5*6 int img with play png  */}
            <div className="col-12 col-md-2 bgsquarex mobnone tabnone">
              <div className="d-flex justify-content-center align-items-center py-5">
                <img
                  src={playthailand}
                  height="80"
                  width="80"
                  alt=""
                  class="wow pulse  "
                ></img>
              </div>
            </div>

            <div className="col-12 col-md-5 d-flex justify-content-center align-items-center padmob">
              <h3
                className="text-white text-align: center"
                style={{ textAlign: "center" }}
              >
                BEST PLACES TO VISIT IN CAPE TOWN
              </h3>
            </div>
            <div className="col-12 col-md-5">
              <div className="d-flex justify-content-center">
                <img src={camthailand} alt="" />
                <h5 className="mt-3 mb-0 ms-2 text-white author">
                  Author / Traveler
                </h5>
              </div>
              <h5 className="text-center text-white mt-3 manasi">
                Manasi Thankur
              </h5>
              <div className="d-flex justify-content-center mt-3 ms-3">
                <ul className="footersocial footer social-rounded d-flex">
                  <li>
                    <a
                      href="https://www.facebook.com/mannaitravelqatar"
                      target="_blank"
                    >
                      <img src={facebook} width="28" height="28" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/mannaitravelqatar/"
                      target="_blank"
                    >
                      <img src={instagram} width="28" height="28" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MannaiTravel" target="_blank">
                      <img src={twitter} width="28" height="28" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/83501210/admin/feed/posts/"
                      target="_blank"
                    >
                      <img src={linkedin} width="28" height="28" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.snapchat.com/add/mannaitravelqa"
                      target="_blank"
                    >
                      <img src={snapchat} width="28" height="28" />
                    </a>
                  </li>

                  <img
                    height="25"
                    className="mt-2"
                    src={sharethailand}
                    alt=""
                  />
                  <p className="mt-2 mb-0 ms-2 text-white share">Share</p>
                </ul>
              </div>
              <div className="d-flex justify-content-around mt-2 gap-2">
                <div className="d-flex justify-content-center">
                  {/* <img
                    height="25"
                    className="mt-2"
                    src={sharethailand}
                    alt=""
                  /> */}
                  {/* <p className="mt-2 mb-0 ms-2 text-white">Share</p> */}
                </div>{" "}
                <div className="d-flex justify-content-center">
                  <img height="25" className="mt-2" src={penthailand} alt="" />
                  <p className="mt-2 mb-0 ms-2 text-white  text-center">
                    Posted : 29-10-2023
                  </p>
                </div>{" "}
                <div className="d-flex justify-content-center">
                  <img
                    height="25"
                    className="mt-2"
                    src={compassthailand}
                    alt=""
                  />
                  <p className="mt-2 mb-0 ms-2 text-white text-center">
                    Read Time : 20 Mins 30 Sec
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="linearthailandbgm ">
            <div className="py-4 px-5">
              <h2 className="text-white mobcenterhead">{blog.Title2}</h2>
              <p className="text-white abouttext mt-2 mobcenterpara">
                {blog.desc}
              </p>
            </div>

            {/* PHI PHI SECTION  */}
            {blog?.BlogSubSections?.map((e, i) =>
              i === 0 || i % 2 === 0 ? (
                <div className="row mobborderbottom" key={i}>
                  <div className="col-12 col-lg-3 MesgPicthailand wow fadeInLeft">
                    <img className="roundedtop  phiphi1" src={e.image} />{" "}
                  </div>

                  <div className="col-12 col-lg-9 d-flex  flex-column align-items-center position-relative wow fadeInRight ">
                    <div className="p-5">
                      <h2 className="text-white w-100 mobcenterhead ">
                        {e.Title}
                      </h2>
                      <p className="text-white abouttext mt-2">{e.desc}</p>
                      <img
                        className="position-absolute editplusnew"
                        src={pluselement}
                        alt=""
                        height="100"
                        width="100"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mobborderbottom">
                  <div className="col-12 col-lg-9 d-flex justify-content-center flex-column align-items-center position-relative ">
                    <div className="p-5">
                      <h2 className="text-white text-end w-100 mobcenterhead ">
                        {e.Title}
                      </h2>
                      <p className="text-white abouttext text-end mt-2">
                        {e.desc}
                      </p>
                      <img
                        className="position-absolute editplusnewright"
                        src={pluselement}
                        alt=""
                        height="100"
                        width="100"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 MesgPicthailand ">
                    <img className="roundedtop phiphi3" src={e.image} alt="" />{" "}
                  </div>
                </div>
              )
            )}

            <div className="ms-5 py-5">
              <img src={element2png} alt="" class="ani-left-right ms-5" />
              <div class=" wow fadeInUp d-flex ms-5" data-wow-delay="0.4s">
                {" "}
                <a class="btn btn-primary mr-4 fr mt-4" href="#">
                  BOOK THAILAND PACKAGE
                  <i class="icon ion-ios-arrow-thin-right"></i>
                </a>{" "}
              </div>
            </div>
          </div>

          <div className="bgthailandblue px-5 py-4">
            <h3 className="text-white">FAQ'S</h3>
          </div>

          <div className="linearthailandbgm pt-4">
            {/* accordion  */}
            <div class="container">
              <div class="row">
                {/* <!-- start  --> */}
                <div class="col-md-6 mobpad">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header m-3" id="headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          1. BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the first item's accordion body.
                          </strong>{" "}
                          It is shown by default, until the collapse plugin adds
                          the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item ">
                      <h2 class="accordion-header m-3" id="headingTwo">
                        <button
                          class="accordion-button collapsed "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          2.BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the second item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header m-3" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          3. BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the third item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end  --> */}

                {/* <!-- start  --> */}
                <div class="col-md-6">
                  {/* <!-- start  --> */}
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header m-3" id="headingFour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          4. BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse "
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the first item's accordion body.
                          </strong>{" "}
                          It is shown by default, until the collapse plugin adds
                          the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header m-3" id="headingFive">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          5. BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the second item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header m-3" id="headingSix">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          6. BEST TIME TO VISIT THAILAND
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <strong>
                            This is the third item's accordion body.
                          </strong>{" "}
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the{" "}
                          <code>.accordion-body</code>, though the transition
                          does limit overflow.
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end  */}
                </div>
              </div>
            </div>

            {/* Blog list section  */}
            <div className="row py-4 px-5">
              <div className="col-12 col-lg-3 bgsquarex d-flex justify-content-center align-items-center h-150">
                <h2 className="text-white m-0">SIMILAR BLOGS</h2>
              </div>
            </div>

            <div class="container-fluid row">
              {blog?.BlogList?.map((e, i) => (
                <div class="col-12 col-lg-6 " key={i}>
                  <div class="article-item bl1 mb70">
                    <div class="blogelementsmallx ">
                      {" "}
                      <img
                        src={elementsmallxpng}
                        alt=""
                        class="wow pulse"
                        data-wow-delay="300ms"
                        data-wow-iteration="infinite"
                        data-wow-duration="2s"
                      />
                    </div>
                    <div class="blogpartpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <h5
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.4s"
                      data-wow-duration="1s"
                    >
                      {e.newsId}
                    </h5>
                    <h6
                      class="wow fadeInUp lapres"
                      data-wow-delay="0.6s"
                      data-wow-duration="1s"
                    >
                      {e.Title}
                    </h6>
                    <div
                      class="article-item-photo zooming wow fadeInDown"
                      data-wow-delay=".4s"
                      data-wow-offset="150"
                    >
                      {" "}
                      <img src={e.image} alt="" class="img-fluid" />{" "}
                    </div>
                    <div class="blogimgtpluselement wow rotateIn">
                      {" "}
                      <img src={pluselement} alt="" />
                    </div>
                    <div class="blogimgarelement ">
                      {" "}
                      <img src={element2png} alt="" class="ani-left-right" />
                    </div>
                    <div
                      class="blogimgrbbtn editbottom wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      {" "}
                      {console.log(e)}
                      <Link to={`/Blogsandnews/News/${e.newsId}`}>
                        <a
                          class="btn btn-primary mr-4 fr"
                          href="#"
                          onClick={handleScrollToTop}
                        >
                          READ BLOG{" "}
                          <i class="icon ion-ios-arrow-thin-right"></i>
                        </a>{" "}
                      </Link>
                    </div>
                    <div class="bolgimgtitle ">{e.desc}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default News;
