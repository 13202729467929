import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogopng from "../img/biglogobg.png";
import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";
import semicircledownelement from "../img/semicircledownelement.png";
import semicircledownelement1 from "../img/semicircledownelement1.png";
import semicircleelement from "../img/semicircleelement.png";
import uparrowelement from "../img/uparrowelement.png";
import water1element from "../img/water1element.png";
import waveselementdark from "../img/waveselementdark.png";
import waveliteelement2 from "../img/waveliteelement2.png";
import wavedark2 from "../img/wavedark2.png";
import smallcirclepng from "../img/smallcircle.png";
import botlinepng from "../img/botline.png";
import wwabanner from "../img/whowearebanner.jpeg";
import dotsbg from "../img/dotsbg.jpg";
import hoarr from "../img/hoarr.jpg";
import biglogowwa from "../img/biglogowwa.png";
import wwapluselement from "../img/wwapluselement.png";
import blobsbg from "../img/blobsbg.png";
import hisimg from "../img/hisimg.png";

import wcubgimg from "../img/wcubgimg.jpg";
import misvisbg from "../img/misvisbg.jpg";
import techgraylogo from "../img/techgraylogo.png";
import pluselementmis from "../img/pluselementmis.png";

import flight3 from "../img/plane.png";
import building from "../img/building.png";
import iataa from "../img/IATAA.png";
import spatr from "../img/spatr.png";
import letr from "../img/letr.png";
import vfsg from "../img/vfsg.png";
import iso from "../img/iso.png";
import spcar from "../img/spcar.png";
import online from "../img/online.png";
import smtool from "../img/smtool.png";
import mannairoundtxt from "../img/Mannai Circle 001@72x.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";
import "../css/globarpartner.css";
import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/whoweare.css";
import "../js/timeline";
import "../js/script";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Partners from "../../Components/Partners";

const Whoweare = () => {
  const location = useLocation();
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <div class="main-body theme-orange dark-horizontal whoweare overflow-hidden">
      <div class="animsition">
        <div class="wrapper">
          <ReactTitle title="Who We Are | Mannai Travel" />
          <Header />

          <div class="slide-container ">
            <div class="slide-bg wow pulse">
              <div className="inside opacityBG ">
                <img
                  src={wwabanner}
                  alt=""
                  className=" img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div class="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>
              {/* <div
                className="pluselementglobal wow rotateIn"
                style={{ visibility: "visible", animationName: "rotateIn" }}
            >
                {" "}
                <img src={pluselement} alt="" />
            </div> */}
              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>

              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>

              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>
              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                    WHO WE ARE
                  </h3>

                  <p
                    class="normaltext  wow slideInRight"
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    {" "}
                    Built on the outstanding heritage of Mannai Corporation
                    QPSC, Mannai Travel stands as a cherished and esteemed
                    agency offering the most exceptional travel experiences with
                    a legacy of over 40 years in Qatar.{" "}
                  </p>
                  <div className="readbutton">
                    <div className="leftarrowanimationelement ">
                      {" "}
                      <img
                        src={element2png}
                        alt=""
                        className="ani-left-right"
                      />
                    </div>
                    {/* <Link to={``} > */}
                    <a className="btn btn-primary   " href="#WhoWeAre">
                      READ MORE <i className="icon ion-ios-arrow-thin-down"></i>
                    </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="misvis" id="WhoWeAre">
            <div class="slide-container" style={{ minHeight: "auto" }}>
              <div class="slide-bg ">
                <div
                  class="inside"
                  style={{ backgroundImage: `url(${misvisbg})` }}
                ></div>
              </div>
              <div class="">
                {/* <div class="uparrowelementmis ">
                  {" "}
                  <img src={uparrowelement} alt="" class="ani-top-bottom" />
                  
                </div> */}
                {/* <div class="element2vis ">
                  {" "}
                  <img src={element2png} alt="" class="ani-left-right" />
                  
                </div> */}
                <div class="wwabiglogo">
                  {" "}
                  <img src={techgraylogo} alt="" />
                </div>
                {/* <div class="pluselementmis wow rotateIn">
                  {" "}
                  <img src={pluselementmis} alt="" />
                </div> */}
                {/* <div class="pluselementvis wow rotateIn">
                  {" "}
                  <img src={pluselementmis} alt="" />
                </div> */}
                <div class="misviselementsmallx">
                  {" "}
                  <img
                    src={elementsmallxpng}
                    alt=""
                    class="wow pulse"
                    data-wow-delay="300ms"
                    data-wow-iteration="infinite"
                    data-wow-duration="2s"
                  />
                </div>
                <div></div>

                <div className="row">
                  <div class="missionpart col-12 col-lg-6 p-0">
                    <div class="contentpartmission">
                      <div className="d-flex align-items-end ps-4">
                        {/* <div class="uparrowelementmis "> */}{" "}
                        {/* <img src={uparrowelement} alt="" class="ani-top-bottom pb-5" /> */}
                        <img
                          src={element2png}
                          alt=""
                          className="ani-left-right "
                          style={{
                            rotate: "180deg",
                            marginLeft: "-40px",
                            position: "relative",
                            left: "162px",
                            top: "-61px",
                          }}
                        />
                        {/* </div> */}
                        <h3
                          class="wow slideInLeft ms-2 mb-0"
                          data-wow-duration="1.2s"
                        >
                          MISSION
                        </h3>
                        {/* <div class="pluselementmis wow rotateIn"> */}{" "}
                        <img
                          src={pluselementmis}
                          className="wow rotateIn"
                          alt=""
                          style={{ marginLeft: "-60px" }}
                        />
                        {/* </div> */}
                      </div>
                      <div class="bb wow backInRight"></div>
                      <p
                        class=" wow slideInLeft p-5 fs-6 text-white text-align-justify"
                        data-wow-delay=".3s"
                        data-wow-duration="1.2s"
                      >
                        {" "}
                        At Mannai Travel, our mission is to be the premier
                        provider of innovative travel solutions, delivering
                        seamless and personalized experiences to our clients
                        exceeding the expectations of our clients with the
                        highest standards.{" "}
                      </p>
                    </div>
                  </div>
                  <div class="visionpart col-12 col-lg-6 p-0">
                    <div class="contentpartvision">
                      <div className="d-flex align-items-end justify-content-end pe-5">
                        <img
                          src={pluselementmis}
                          alt=""
                          className="wow rotateIn"
                          style={{ marginRight: "-60px" }}
                        />
                        <div>
                          <img
                            src={element2png}
                            alt=""
                            class="ani-left-right"
                          />
                          <h3 class="wow slideInRight" data-wow-duration="1.5s">
                            VISION
                          </h3>
                        </div>
                      </div>
                      <div class="bb wow backInRight"></div>
                      <p
                        class="wow slideInRight p-5 fs-6 text-white text-align-justify"
                        data-wow-delay=".3s"
                        data-wow-duration="1.5s"
                      >
                        {" "}
                        Our vision at Mannai Travel is to reshape the future of
                        travel by continuously innovating and adapting to the
                        evolving needs of our clients. We strive to contribute
                        positively to the industry and the communities we serve
                        through cutting-edge technology and sustainable travel
                        practices.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="slide-container pt0 ourhistory ourhistoryone"
            style={{ minHeight: "auto" }}
          >
            <div class="slide-bg">
              <div
                class="inside"
                style={{ backgroundImage: `url(${dotsbg})` }}
              ></div>
            </div>
            <div class="col-12">
              <div class="hoarr ">
                {" "}
                <img src={hoarr} alt="" class="ani-left-right" />
              </div>

              {/* <div class="pluselementohwwa wow rotateIn">
                {" "}
                <img src={wwapluselement} alt="" />
              </div> */}
              <div className="row">
                <div class="col-12 col-md-6 p-md-5 p-4">
                  <div className="d-flex justify-content-end ">
                    <div class="historybiglogo">
                      {" "}
                      <img src={biglogowwa} alt="" />
                    </div>
                    <h1
                      class="wow slideInLeft fw-semibold ourhistorytitle"
                      data-wow-duration="1.2s"
                    >
                      OUR HISTORY
                    </h1>
                    <img src={wwapluselement} alt="" />
                  </div>
                  <p
                    class="normaltext wow slideInLeft text-align-justify fs-6"
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    {" "}
                    Mannai Travel is a distinguished name in Qatar and has
                    earned its stellar reputation by providing expert,
                    responsive, and tailored services to its clients.
                    Established in 1978, Mannai Travel has been dedicated to
                    crafting exceptional travel experiences for both business
                    and leisure travelers. As an accredited IATA agent, Mannai
                    Travel delivers comprehensive solutions to individual
                    travelers as well as business clientele through its
                    affiliations and partnerships with regional and
                    international sound names in the industry.{" "}
                  </p>
                </div>
                <div className="col-12 col-md-6 historyimagecontainer position-relative">
                  <div class="historyimage wow fadeInRight ani-move2">
                    {" "}
                    <img src={blobsbg} />{" "}
                  </div>
                  <div class="historyimgpartwwa">
                    {" "}
                    <img src={hisimg} alt="" />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="histextpart">
            <div class="section  ">
              <div class=""> */}
          <div class=" histext p-md-5 p-3">
            <p className="normaltext wow fadeInUp text-align-justify fs-5 text-white">
              With a history spanning four decades, Mannai Travel has extended
              its reach with branch offices strategically located throughout
              Qatar, ensuring the highest standards of customer service.
              Committed to thriving in a dynamic industry, Mannai Travel adopts
              a comprehensive approach to its operations, resulting in
              remarkable client retention and a strong presence in both local
              and regional markets. Experience the rich legacy and exceptional
              services of Mannai Travel today.
            </p>
          </div>
          {/* </div>
            </div>
          </div> */}

          {/* <div class="slide-container">
            <div class="slide-bg">
              <div
                class="inside"
                style={{ backgroundImage: `url(${jurneybg})` }}
              ></div>
            </div>
            <div class="row">
              <div class="journeywwa col-1 p-0" data-wow-duration="1.2s">
                MANNAI TRAVEL JOURNEY
              </div>
              <div class="contentpartjpwwa col-11 row">
                <div class="row main-timeline10 position-relative col-12">
                  <div class=" col-12 row">
                    <div
                      class=" col-12 col-md-6 col-lg-3 timeline wow fadeInRight"
                      data-wow-delay=".1s"
                      data-wow-duration="1s"
                    >
                      <div class="timeline-inner">
                        <div class="year">1978</div>
                        <div class="timeline-content">
                          <div class="post">ESTABLISHMENT</div>
                          <p class="description">
                            {" "}
                            Mannai Travel Establishment as a Full fledge in
                            house travel agency.{" "}
                          </p>
                        </div>
                        <div class="timeline-icon">
                          {" "}
                          <i class="fa fa-globe"></i>{" "}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-6 col-lg-3 timeline wow fadeInRight"
                      data-wow-delay=".1s"
                      data-wow-duration="1.2s"
                    >
                      <div class="timeline-inner">
                        <div class="year">1982</div>
                        <div class="timeline-content">
                          <div class="post">IATA</div>
                          <p class="description">
                            {" "}
                            We got our accreditation from International Air
                            Transport Association(IATA){" "}
                          </p>
                        </div>
                        <div class="timeline-icon">
                          {" "}
                          <i class="fa fa-rocket"></i>{" "}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-6 col-lg-3 timeline wow fadeInRight"
                      data-wow-delay=".1s"
                      data-wow-duration="1.4s"
                    >
                      <div class="timeline-inner">
                        <div class="year">1988</div>
                        <div class="timeline-content">
                          <div class="post">SPACE TRAVEL</div>
                          <p class="description">
                            {" "}
                            Mannai Travel established Space Travel which is the
                            General Sales Agent Division for FlyDubai, Kenya
                            Airways and Philippines Airlines.{" "}
                          </p>
                        </div>
                        <div class="timeline-icon">
                          {" "}
                          <i class="fa fa-briefcase"></i>{" "}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-6 col-lg-3 timeline wow fadeInRight"
                      data-wow-delay=".1s"
                      data-wow-duration="1.6s"
                    >
                      <div class="timeline-inner">
                        <div class="year">1995</div>
                        <div class="timeline-content">
                          <div class="post">LEISURE TRAVEL</div>
                          <p class="description">
                            {" "}
                            Mannai Holidays was established, the leisure arm of
                            Mannai Travel is specializing in both outbound and
                            inbound leisure travels.{" "}
                          </p>
                        </div>
                        <div class="timeline-icon">
                          {" "}
                          <i class="fa fa-camera"></i>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" col-12 row">
                    <div
                      class="col-12 col-md-6 col-lg-3 timeline wow fadeInRight"
                      data-wow-delay=".1s"
                      data-wow-duration="1.8s"
                    >
                      <div class="timeline-inner">
                        <div class="year">2007</div>
                        <div class="timeline-content">
                          <div class="post">VFS GLOBAL</div>
                          <p class="description">
                            {" "}
                            Mannai Travel became the global partner of VFS
                            Global and started providing visa facilitation to
                            customers
                          </p>
                        </div>
                        <div class="timeline-icon">
                          {" "}
                          <i class="fa fa-camera"></i>{" "}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-6 col-lg-3 timeline wow fadeInRight"
                      data-wow-delay=".1s"
                      data-wow-duration="2s"
                    >
                      <div class="timeline-inner">
                        <div class="year">2017</div>
                        <div class="timeline-content">
                          <div class="post">ISO CERTIFIED</div>
                          <p class="description">
                            {" "}
                            We got our ISO certificate for International
                            Organization for Standardization{" "}
                          </p>
                        </div>
                        <div class="timeline-icon">
                          {" "}
                          <i class="fa fa-camera"></i>{" "}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-6 col-lg-3 timeline wow fadeInRight"
                      data-wow-delay=".1s"
                      data-wow-duration="2.2s"
                    >
                      <div class="timeline-inner">
                        <div class="year">2018</div>
                        <div class="timeline-content">
                          <div class="post">SPACE CARGO</div>
                          <p class="description">
                            {" "}
                            Space Cargo was established which is the logistics
                            division of Mannai Travel and provides Door-to-Door
                            delivery services to countries like India, Nepal,
                            Bangladesh, Sri Lanka, Philippines, Indonesia, the
                            UK, USA and UAE.{" "}
                          </p>
                        </div>
                        <div class="timeline-icon">
                          {" "}
                          <i class="fa fa-camera"></i>{" "}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-6 col-lg-3 timeline wow fadeInRight"
                      data-wow-delay=".1s"
                      data-wow-duration="2.4s"
                    >
                      <div class="timeline-inner">
                        <div class="year">2020</div>
                        <div class="timeline-content">
                          <div class="post">ONLINE</div>
                          <p class="description">
                            {" "}
                            Best Holidays Online was established which is a B2C
                            website helping customers in India book their flight
                            tickets, hotels and etc.{" "}
                          </p>
                        </div>
                        <div class="timeline-icon">
                          {" "}
                          <i class="fa fa-camera"></i>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="section section-item p0 position-relative">
            <div className="container-fluid">
              <div className="slide-body journeybg">
                <div className="container col-sm-12">
                  <h4 className="journey text-white pt-3">
                    MANNAI TRAVEL'S JOURNEY{" "}
                  </h4>
                  <div className="about__timeline">
                    <div
                      id="timeline-aboutus"
                      className="timeline text-inverted"
                    >
                      {" "}
                      <img
                        className="timeline__cursor"
                        src={flight3}
                        width="60"
                      />
                      <ul className="timeline__steps translateX263">
                        <li className="prev-step">
                          {" "}
                          <span className="year bg1 font-lg headingbold">
                            1978
                          </span>
                          <p className="bubble headingbold1">ESTABLISHMENT</p>
                          <p className="textnormal">
                            Mannai Travel started as a full-fledged in-house
                            travel agency.
                          </p>
                          <p className="build">
                            {" "}
                            <img src={building} />
                          </p>
                        </li>
                        <li className="active">
                          {" "}
                          <span className="year bg2  font-lg headingbold">
                            1982
                          </span>
                          <p className="bubble headingbold2">IATA</p>
                          <p className="textnormal">
                            We got our accreditation from the International Air
                            Transport Association(IATA).
                          </p>
                          <p className="iata">
                            {" "}
                            <img src={iataa} className="object-contain w-50" />
                          </p>
                        </li>
                        <li className="next-step">
                          {" "}
                          <span className="year  bg3 font-lg headingbold">
                            1988
                          </span>
                          <p className="bubble headingbold3">SPACE TRAVEL</p>
                          <p className="textnormal">
                            Mannai Travel established Space Travel that
                            represents Flydubai, Kenya Airways, Phillippine
                            Airline and Akasa Air.
                          </p>
                          <p className="spatr">
                            {" "}
                            <img src={spatr} />
                          </p>
                        </li>
                        <li className="">
                          {" "}
                          <span className="year bg4 font-lg headingbold">
                            1995
                          </span>
                          <p className="bubble headingbold4">LEISURE TRAVEL</p>
                          <p className="textnormal">
                            Mannai Holidays was established as the leisure arm
                            of Mannai Travel, specializing in outbound and
                            inbound travel experiences.
                          </p>
                          <p className="letr">
                            {" "}
                            <img src={letr} />
                          </p>
                        </li>
                        <li className="">
                          {" "}
                          <span className="year bg5 font-lg headingbold">
                            2007
                          </span>
                          <p className="bubble headingbold5">VFS GLOBAL</p>
                          <p className="textnormal">
                            Mannai Corporation represents VFS Global as their
                            facilitation management company in the State of
                            Qatar.
                          </p>
                          <p className="vfsg">
                            {" "}
                            <img src={vfsg} />
                          </p>
                        </li>
                        <li className="">
                          {" "}
                          <span className="year bg6 font-lg headingbold">
                            2017
                          </span>
                          <p className="bubble headingbold6">ISO CERTIFIED</p>
                          <p className="textnormal">
                            We got our ISO certificate for International
                            Organization for Standardization - ISO 9001:2015.
                          </p>
                          <p className="iso">
                            {" "}
                            <img src={iso} />
                          </p>
                        </li>
                        <li className="">
                          {" "}
                          <span className="year bg7 font-lg headingbold">
                            2018
                          </span>
                          <p className="bubble headingbold7">SPACE CARGO</p>
                          <p className="textnormal">
                            Space Cargo was established and provides freight
                            forwarding services globally.
                          </p>
                          <p className="spcar">
                            {" "}
                            <img src={spcar} />
                          </p>
                        </li>
                        <li className="">
                          {" "}
                          <span className="year bg8 font-lg headingbold">
                            2020
                          </span>
                          <p className="bubble headingbold8">ONLINE</p>
                          <p className="textnormal">
                            Best Holidays, an online{" "}
                            <span className="text-nowrap">e-commerce</span>{" "}
                            portal, was established to provide global travel
                            services and experiences to end users.
                          </p>
                          <p className="online">
                            {" "}
                            <img src={online} />
                          </p>
                        </li>
                        <li className="">
                          {" "}
                          <span className="year bg9 font-lg headingbold">
                            2023
                          </span>
                          <p className="bubble headingbold9">TECH SOLUTION</p>
                          <p className="textnormal">
                            Offering a state-of-the art tech platform to travel
                            agents, corporates, and end users.
                          </p>
                          <p className="smtool">
                            {" "}
                            <img src={smtool} />
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="wcu">
            <div class="slide-container">
              <div class="slide-bg">
                <div
                  class="inside"
                  style={{ backgroundImage: `url(${wcubgimg})` }}
                ></div>
              </div>
              <div className="mx-md-5 mx-3">
                <div class="wcuheading d-flex align-items-center mb-5 mx-md-5 mx-0">
                  <h3
                    className="whowearebannerfont wow slideInLeft d-flex"
                    data-wow-duration="1.2s"
                  >
                    WHY CHOOSE US?
                  </h3>
                  {/* <img className=" wow rotateIn" src={pluselementmis} alt=""  style={{zIndex:1}}/> */}
                </div>
                {/* <div class="container"> */}
                <div class="contentpartwcubg row p-4 mx-md-5 mx-0">
                  <div
                    class="normaltext  wow slideInLeft col-12 col-lg-6 fs-5 d-flex align-items-center fw-semibold"
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    <p>
                      Mannai Travel provides a wide range of travel solutions
                      that are customized to suit any specification. Armed with
                      in-depth industry knowledge and key partnerships, our
                      insights and expertise are both global and local, while
                      being flexible and agile.{" "}
                    </p>
                  </div>

                  <div
                    class="listtext  wow slideInRight col-12 col-lg-6 fs-5  d-flex align-items-center fw-semibold"
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    <ul>
                      <li>Personalized travel plans and programs </li>
                      <li>Wide options </li>
                      <li>Competitive rates </li>
                      <li>Streamlining processes </li>
                      <li>Technology solutions </li>
                      <li>Dedicated travel advisors </li>
                      <li>Round-the-clock customer care </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="serviceroundtxttechnology wow rotateIn">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>
              <div class="serviceelement1bot">
                {" "}
                <img
                  src={element1png}
                  class="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                  alt=""
                />
              </div>
              <div class="wcubiglogo">
                {" "}
                <img src={biglogopng} alt="" width="200px" />
              </div>
              <div class="pluselementwcu wow rotateIn">
                {" "}
                <img src={pluselement} alt="" />
              </div>
              <div class="serviceelement2 ">
                {" "}
                <img src={element2png} alt="" class="ani-left-right" />
              </div>
              {/* <div class="semicircledownelement">
                  {" "}
                  <img src={semicircledownelement} alt="" />
                </div> */}
              {/* <div class="semicircleelement">
                  {" "}
                  <img src={semicircleelement} alt="" />
                </div> */}
              <div class="uparrowelementWCU">
                {" "}
                <img src={uparrowelement} alt="" class="ani-top-bottom" />
              </div>
              <div class="water1element ani-move">
                {" "}
                <img
                  src={semicircleelement}
                  alt=""
                  className="wwasemicircleelement"
                />
                <img src={water1element} alt="" className="mt-5" />
              </div>
              <div className="waveselementdark ani-move">
                {" "}
                <img src={waveselementdark} alt="" />
                <div class="smallcircleelement">
                  {" "}
                  <img src={smallcirclepng} alt="" />
                </div>
                <div class="semicircledownelement">
                  {" "}
                  <img src={semicircledownelement} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}

          {/*          
          <div class="award ">
            <div class="section section-content slide-dark">
              <div class="container-fluid">
                <div class="project-list-flash row  no-gutters ">
                  <div
                    class="col-lg-4 col-sm-6 p-0 wow fadeInRight"
                    data-wow-delay=".2s"
                  >
                    {" "}
                    <a class="project-item">
                      <div class="aletter">A</div>
                      <div class="wletter">W</div>
                      <div class="pluselementaward wow rotateIn">
                        {" "}
                        <img src={pluselementaward} alt="" />
                      </div>
                      <div
                        class="project-carousel-photo"
                        style={{ backgroundImage: `url(${award1})` }}
                      ></div>
                    </a>{" "}
                  </div>
                  <div
                    class="col-lg-4 col-sm-6 p-0 wow fadeInRight "
                    data-wow-delay=".4s"
                  >
                    {" "}
                    <a class="project-item">
                      <div class="aaletter">A</div>
                      <div
                        class="project-carousel-photo"
                        style={{ backgroundImage: `url(${award2})` }}
                      ></div>
                    </a>{" "}
                  </div>
                  <div
                    class="col-lg-4 col-sm-6 p-0 wow fadeInRight "
                    data-wow-delay=".6s"
                  >
                    <div class="awardelementsmallx">
                      {" "}
                      <img
                        src={elementsmallxpng}
                        alt=""
                        class="wow pulse"
                        data-wow-delay="300ms"
                        data-wow-iteration="infinite"
                        data-wow-duration="2s"
                      />
                    </div>
                    <a class="project-item">
                      <div
                        class="project-carousel-photo"
                        style={{ backgroundImage: `url(${award3})` }}
                      ></div>
                    </a>{" "}
                  </div>
                  <div
                    class="col-lg-4 col-sm-6 p-0 wow fadeInLeft "
                    data-wow-delay=".2s"
                  >
                  
                    <a class="project-item">
                      <div
                        class="project-carousel-photo"
                        style={{ backgroundImage: `url(${award4})` }}
                      ></div>
                    </a>{" "}
                  </div>
                  <div
                    class="col-lg-4 col-sm-6 p-0 wow fadeInLeft "
                    data-wow-delay=".4s"
                  >
                    {" "}
                    <a class="project-item">
                      <div class="rletter">R</div>
                      <div
                        class="project-carousel-photo"
                        style={{ backgroundImage: `url(${award5})` }}
                      ></div>
                    </a>{" "}
                  </div>
                  <div
                    class="col-lg-4 col-sm-6 p-0 wow fadeInLeft "
                    data-wow-delay=".6s"
                  >
                    {" "}
                    <a class="project-item">
                      <div class="dletter">D</div>
                      <div class="sletter">S</div>
                      <div class="pluselementaward1 wow rotateIn">
                        {" "}
                        <img src={pluselementaward} alt="" />
                      </div>
                      <div
                        class="project-carousel-photo"
                        style={{ backgroundImage: `url(${award2})` }}
                      ></div>
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* 
          <div class="section section-item p0 ">
            <div class="container-fluid team">
              <div class="slide-body teambg">
                <div class="container-fluid">
                  <div class="contentpartteam">
                    <div className="d-flex p-5 align-items-center">
                    <img src={biglogowwa} alt=""  className="lg:position-relative position-absolute"/>
                    <div className="d-flex mt-5">
                    <h3 class="wow slideInLeft" data-wow-duration="1.2s">
                      Our Leadership Team
                    </h3>
                    <img src={wwapluselement} alt="" style={{marginLeft:"-60px"}}/>
                    </div>
                    </div>
                    <div class=" row align-items-center mt-5 ">
                      <div
                        class="col-md-4  gmdeskimg wow fadeInLeft"
                        data-wow-delay=".2s"
                      >
                        {" "}
                        <img src={gmimg} alt="" />{" "}
                      </div>
                      <div class="col-md-6 gmsmsg pe-4">
                        <h4 class="wow slideInRight" data-wow-duration="1.2s">
                          MESSAGE FROM
                          <br />
                          GM’S DESK
                        </h4>
                        <div
                          class="normaltext  wow slideInRight"
                          data-wow-delay=".1s"
                          data-wow-duration=".80s"
                        >
                          <p className="my-0">
                            {" "}
                            At Mannai Travel, it is our privilege to serve you,
                            and I wanted to take a moment to express my
                            heartfelt gratitude for your continued trust and
                            support.
                          </p>
                          <p className="my-0">
                            In these unprecedented times, the world of travel
                            has faced its share of challenges. Yet, we have
                            remained steadfast in our commitment to providing
                            you with exceptional service, ensuring your safety,
                            comfort, and peace of mind throughout your journeys.
                          </p>
                          <p className="my-0">
                            Our dedicated team has worked tirelessly to adapt to
                            the changing landscape, offering you flexible
                            options, top-notch safety measures, and a seamless
                            booking experience through our secure and reliable
                            apps.
                          </p>
                          <p className="my-0">
                            As we look forward to the future, Mannai Travel
                            remains committed to innovation, excellence, and
                            personalized service. We are excited about the
                            opportunities that lie ahead and are continuously
                            striving to enhance your travel experiences.
                          </p>
                          <p className="my-0">
                            Your feedback is invaluable to us, and we encourage
                            you to share your thoughts and suggestions as we
                            work together to shape the future of travel. Our
                            24/7 support team is here to assist with any queries
                            you may have, travel-related or otherwise, ensuring
                            that your every need is met.
                          </p>
                          <p className="my-0">
                            In conclusion, Mannai Travel is not just a travel
                            agency; it's a trusted partner in your journeys.
                            Thank you for choosing us, and we look forward to
                            serving you with dedication, reliability, and a
                            commitment to excellence.
                          </p>
                          <p className="mt-2 mb-0">Warm regards,</p>
                          <p className="mt-1 mb-0">Shafiq Nasiruddin</p>
                          <p className="my-0">General Manager, Mannai Travel</p>
                        </div>
                      </div>
                    </div>
                    <div class=" row  ">
                      <div
                        class="col-md-12 col-sm-6 gmimg wow fadeInLeft"
                        data-wow-delay=".2s"
                      >
                        <div class="teammembers">
                          <div class="teamsheading d-flex justify-content-center wow slideInRight" >
                            <h5>TEAM</h5>
                            <img className="wow rotateIn" src={pluselementaward} alt=""  style={{marginLeft:"-60px"}} />
                          </div>
                          <div className="position-relative">
                            <div class="container">
                              <div class="row">
                                <div id="teams-slider" class="owl-carousel">
                                  <div class="teammemberslider">
                                    <div class="pic">
                                      {" "}
                                      <img src={gmimg} alt="" />{" "}
                                    </div>
                                    <div class="name">SHAFIQ NASIRUDDIN </div>
                                    <span class="title">
                                      General Manager
                                    </span>{" "}
                                  </div>
                                  <div class="teammemberslider">
                                    <div class="pic">
                                      {" "}
                                      <img src={gmimg} alt="" />{" "}
                                    </div>
                                    <div class="name">SHAFIQ NASIRUDDIN </div>
                                    <span class="title">
                                      General Manager
                                    </span>{" "}
                                  </div>
                                  <div class="teammemberslider">
                                    <div class="pic">
                                      {" "}
                                      <img src={gmimg} alt="" />{" "}
                                    </div>
                                    <div class="name">SHAFIQ NASIRUDDIN </div>
                                    <span class="title">
                                      General Manager
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="waveselementdarkteams d-flex align-items-center ani-move pb-2">
                              {" "}
                              <img
                                src={waveselementdark}
                                class=""
                                alt=""
                              />
                              <div class="smallcircleelementteams">
                              {" "}
                              <img src={smallcirclepng} alt="" />
                            </div>
                               <div class="semicircledownelementteams">
                              {" "}
                              <img src={semicircledownelement} alt="" />
                            </div>
                            </div>
                        
                            <div class="serviceelementteams ">
                              {" "}
                              <img
                                src={element2png}
                                alt=""
                                class="ani-left-right"
                              />
                            </div>
                           
                            <div class="teamselementsmallx">
                              {" "}
                              <img
                                src={elementsmallxpng}
                                alt=""
                                class="wow pulse"
                                data-wow-delay="300ms"
                                data-wow-iteration="infinite"
                                data-wow-duration="2s"
                              />
                            </div>
                           
                            <div class="teamselement1bot">
                              {" "}
                              <img
                                src={element1png}
                                class="wow pulse"
                                data-wow-delay="300ms"
                                data-wow-iteration="infinite"
                                data-wow-duration="2s"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                    <div className="water1elementteam ani-move">
                      {" "}
                      <img src={semicircleelementteam} alt="" className="wwasemicircleelementteam" />
                      <img src={water1element} alt="" class="mt-5"  />
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <Partners />

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Whoweare;
