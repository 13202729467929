import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

// import logopng from "./img/logo.png";
// import biglogopng from "./img/biglogobg.png";
import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";

import uparrowelement from "../img/uparrowelement.png";

import CareerBanner from "../img/careerbanner1.jpg";
import mannairoundtxt from "../img/Mannai Circle 001@72x.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/globarpartner.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../js/timeline";
import "../js/script";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Partners from "../../Components/Partners";

const CareerSection1 = () => {
  const location = useLocation();
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <>
      <div className="main-body theme-orange dark-horizontal careersection1 overflow-hidden">
        <div className="animsition">
          <div className="wrapper parallax-start">
            <ReactTitle title="CareerSection1 | Mannai Travel" />
            <div className="slide-container">
              <div className="slide-bg wow pulse">
                <div className="inside opacityBG ">
                  <img src={CareerBanner} alt="" className=" img-fluid " />
                </div>
              </div>
              <div className="container">
                <div className="downplus wow rotateIn ">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                {/* <div
                className="pluselementglobal wow rotateIn"
                style={{ visibility: "visible", animationName: "rotateIn" }}
            >
                {" "}
                <img src={pluselement} alt="" />
            </div> */}
                <div className="smlogoglobal">
                  {" "}
                  <img
                    src={biglogoservicepng}
                    alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                  />
                </div>
                <div className="downanimationelement">
                  <img
                    src={element1png}
                    alt=""
                    className="wow pulse"
                    data-wow-delay="300ms"
                    data-wow-iteration="infinite"
                    data-wow-duration="2s"
                  />
                </div>

                <div className="uparrowelementall">
                  {" "}
                  <img src={uparrowelement} alt="" className="ani-top-bottom" />
                </div>
                <div className="roundanimation">
                  <div className="roundtxtanimationplus">
                    {" "}
                    <img src={roundtxtpng} alt="" />
                  </div>
                  <div className="roundtextanimation">
                    {" "}
                    <img src={mannairoundtxt} alt="" />{" "}
                  </div>
                </div>
                <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                  <div className="">
                    <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                      CAREER
                    </h3>
                    <p
                      className="normaltext  wow slideInRight"
                      data-wow-delay=".1s"
                      data-wow-duration=".80s"
                    >
                      {" "}
                      Embark on an extraordinary career journey with Mannai
                      Travels! We invite you to join our passionate team and be
                      part of one of the most cherished companies in Qatar. At
                      Mannai Travel, we are committed to nurturing your talents
                      and helping you reach your full potential. We believe that
                      together, we can create memorable experiences for our
                      clients and for ourselves.{" "}
                    </p>
                    <div>
                      <div className="leftarrowanimationelement">
                        {" "}
                        <img
                          src={element2png}
                          alt=""
                          className="ani-left-right"
                        />
                      </div>
                      {/* <Link to={``} > */}
                      <a className="btn btn-primary   " href="#Careers">
                        READ MORE{" "}
                        <i className="icon ion-ios-arrow-thin-down"></i>
                      </a>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerSection1;
