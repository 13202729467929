import React, { useEffect } from "react";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import ServiceBgImg from "../img/woman-safety-equipment-work.jpg";
import img1 from "../img/Blue ship icon.png";
import img2 from "../img/Box icon.png";
import img3 from "../img/General Cargo Icon.png";
import img4 from "../img/Air sea icon.png";
import img5 from "../img/Frieght Forward icon.png";
import img6 from "../img/Door to D icon.png";
import img7 from "../img/Pet cargo icon.png";
import img8 from "../img/Human Remain icon.png";
import "./styles.css";
import pluselement from "../img/pluselement.png";
import element2png from "../img/element2.png";
import shape3 from "../img/shape3.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/CareerDetail.css";
import "../js/timeline";
import "../js/script";
import { useLocation } from "react-router-dom";
import Footer from "../../Components/Footer";
import GlobalPartnerForm from "../GlobalPartnersPage/GlobarPartnerForm";
import HeroSection from "./HeroSection";
import Header from "../../Components/Header";
const SpaceCargo = () => {
  const location = useLocation();
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <>
      <div className="main-body theme-orange dark-horizontal spacetravel overflow-hidden">
        <div className="animsition">
          <div className="wrapper">
            <ReactTitle title="Space Cargo | Mannai Travel" />
            <Header />
            <HeroSection data={"SPACE CARGO"} />
            <div className="blunbg1" id="SPACE CARGO">
              <p
                className="fw-semibold text-light md:fs-4 sm:fs-5 text-justify  p-5 text-align-justify wow fadeInUp "
                data-wow-duration="1.4s"
              >
                Space Cargo, the cargo and logistics associate of Space Travel,
                currently represents Philippine Airlines and Flydubai as their
                GSA in Qatar. It facilitates the transfer and management of
                commercial cargo, general cargo, air & seas import/export
                clearance, freight forwarding, door-to-door delivery, live
                animals, and human remains. As a member of the JCTRANS Network,
                Space Cargo guarantees greater connectivity with approved agents
                for multiple logistics services across the globe.
              </p>
              <div className="text-center fw-bold text-dark pb-5 mt-3">
                <img
                  // width="100px"
                  // height="100px"
                  src={img1}
                  alt="COMMERCIAL CARGO"
                  className="mb-2 object-fit-contain wow slideInRight"
                  data-wow-duration="1.4s"
                />
                <h2
                  className="pb-4 mx-5 text-blue wow slideInRight"
                  style={{ borderBottom: "2px dashed #17355d" }}
                  data-wow-duration="1.4s"
                >
                  EXPLORE OUR CARGO SOLUTIONS
                </h2>
              </div>

              <div className="row g-0 darkblunbg1 overflow-hidden">
                <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column align-items-center md:mx-5 md:px-5 md:p-0">
                  <div className="d-md-flex d-grid gap-3 my-2 fs-5 fw-bold text-white mt-3">
                    <div
                      className="blunbg1 p-2 wow slideInLeft"
                      data-wow-duration="1.4s"
                      style={{ width: "30vh" }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center p-2 border border-light">
                        <div>
                          <img
                            width="100px"
                            height="100px"
                            src={img1}
                            alt="COMMERCIAL CARGO"
                            className="mb-2 object-fit-contain "
                          />
                        </div>
                        <span className="text-center">COMMERCIAL</span>
                        <span className="text-center">CARGO</span>
                      </div>
                    </div>

                    <div
                      className="blunbg1 p-2 wow slideInRight"
                      data-wow-duration="1.4s"
                      style={{ width: "30vh" }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center p-2 border border-light">
                        <div>
                          <img
                            width="100px"
                            height="100px"
                            src={img2}
                            alt="PERSONAL EFFECTS"
                            className="mb-2 object-fit-contain "
                          />
                        </div>
                        <span className="text-center">PERSONAL</span>
                        <span className="text-center">EFFECTS</span>
                      </div>
                    </div>
                  </div>

                  <div className="d-md-flex d-grid gap-3 my-2 fs-5 fw-bold text-white">
                    <div
                      className="blunbg1 p-2 wow slideInLeft"
                      data-wow-duration="1.4s"
                      style={{ width: "30vh" }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center p-2 border border-light">
                        <div>
                          <img
                            width="100px"
                            height="100px"
                            src={img3}
                            alt="GENERAL CARGO"
                            className="mb-2 object-fit-contain "
                          />
                        </div>
                        <span className="text-center">GENERAL</span>
                        <span className="text-center">CARGO</span>
                        <span className="text-center"></span>
                      </div>
                    </div>

                    <div
                      className="blunbg1 p-2 wow slideInRight"
                      data-wow-duration="1.4s"
                      style={{ width: "30vh" }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center p-2 border border-light">
                        <div>
                          <img
                            width="70px"
                            height="70px"
                            src={img4}
                            alt="AIR/SEA IMPORT/EXPORT CLEARANCE"
                            className="mb-2 object-fit-contain "
                          />
                        </div>
                        <span className="text-center">AIR/SEA</span>
                        <span className="text-center">IMPORT/EXPORT</span>
                        <span className="text-center">CLEARANCE</span>
                      </div>
                    </div>
                  </div>

                  <div className="d-md-flex d-grid gap-3 my-2 fs-5 fw-bold text-white">
                    <div
                      className="blunbg1 p-2 wow slideInLeft"
                      data-wow-duration="1.4s"
                      style={{ width: "30vh" }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center p-2 border border-light">
                        <div>
                          <img
                            width="100px"
                            height="100px"
                            src={img5}
                            alt="FRIEGHT FORWARDING"
                            className="mb-2 object-fit-contain "
                          />
                        </div>
                        <span className="text-center">FRIEGHT</span>
                        <span className="text-center">FORWARDING</span>
                      </div>
                    </div>

                    <div
                      className="blunbg1 p-2 wow slideInRight"
                      data-wow-duration="1.4s"
                      style={{ width: "30vh" }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center p-2 border border-light">
                        <div>
                          <img
                            width="100px"
                            height="100px"
                            src={img6}
                            alt="DOOR TO DOOR DELIVERY"
                            className="mb-2 object-fit-contain "
                          />
                        </div>
                        <span className="text-center">DOOR TO DOOR</span>
                        <span className="text-center">DELIVERY</span>
                      </div>
                    </div>
                  </div>

                  <div className="d-md-flex d-grid gap-3 my-2 fs-5 fw-bold text-white mb-3">
                    <div
                      className="blunbg1 p-2 wow slideInLeft"
                      data-wow-duration="1.4s"
                      style={{ width: "30vh" }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center p-2 border border-light">
                        <div>
                          <img
                            width="100px"
                            height="100px"
                            src={img7}
                            alt="PETS & LIVE ANIMALS"
                            className="mb-2 object-fit-contain "
                          />
                        </div>
                        <span className="text-center">PETS & LIVE</span>
                        <span className="text-center">ANIMALS</span>
                      </div>
                    </div>

                    <div
                      className="blunbg1 p-2 wow slideInRight"
                      data-wow-duration="1.4s"
                      style={{ width: "30vh" }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center p-2 border border-light">
                        <div>
                          <img
                            width="100px"
                            height="100px"
                            src={img8}
                            alt="HUMAN REMAINS"
                            className="mb-2 object-fit-contain "
                          />
                        </div>
                        <span className="text-center">HUMAN</span>
                        <span className="text-center">REMAINS</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12 spacecargoourservices d-flex"
                  // style={{backgroundImage:`url(${ServiceBgImg})`}}
                >
                  {/* <img src={ServiceBgImg} alt="" style={{height:'138vh'}}/>  */}
                  <div className="transperentbgblue w-100 h-100 p-4">
                    <div className="d-flex justify-content-center flex-column h-100">
                      <div className="d-flex align-items-center mb-3">
                        <img
                          src={pluselement}
                          className="wow rotateIn"
                          data-wow-duration="1.4s"
                        />
                        <div className="plustitle-ml-60">
                          <h1
                            className="fs-1 fw-bold text-white wow slideInRight"
                            data-wow-duration="1.4s"
                          >
                            Our
                          </h1>
                          <h1
                            className="fs-1 fw-bold text-white wow slideInRight"
                            data-wow-duration="1.4s"
                          >
                            Services
                          </h1>
                        </div>
                      </div>
                      <div>
                        <img
                          src={element2png}
                          alt=""
                          className="ani-left-right mb-2 pl-2 wow slideInRight"
                        />
                        <h2
                          className="fw-semibold text-light fs-4 wow fadeInUp"
                          data-wow-duration="1.4s"
                        >
                          "Empowering Your Global Logistics:
                        </h2>
                        <h2
                          className="fw-semibold text-light fs-4 wow fadeInUp"
                          data-wow-duration="1.4s"
                        >
                          A Trusted Partner in Comprehensive Cargo Services"
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center mb-3 py-4">
                <img
                  src={pluselement}
                  className="wow rotateIn"
                  data-wow-duration="1.4s"
                />
                <div className="plustitle-ml-60 pb-3">
                  <h1
                    className="fs-1 fw-bold text-white mb-3 wow slideInRight"
                    data-wow-duration="1.4s"
                  >
                    DELIVERING DREAMS WORLDWIDE <img src={shape3} />
                  </h1>
                  <h1
                    className="fs-4 fw-semibold text-white wow slideInRight"
                    data-wow-duration="1.4s"
                  >
                    ENSURING CARE, SAFETY, AND TRANSPARENCY
                  </h1>
                </div>
              </div>

              <div className="stats-bg">
                <div className="transperentbgblue row g-0 overflow-hidden p-md-5 p-sm-0 h-100">
                  <div className="col-lg-5 col-md-5 col-sm-12 d-flex flex-column md:mx-5 md:px-5 sm:p-3">
                    <div className="d-flex flex-column  gap-5 m-5">
                      <div
                        className="p-3 transperentbgblue2 wow slideInRight"
                        data-wow-duration="1.4s"
                      >
                        <div className="px-3 border-start border-light">
                          <h4 className="text-white m-1">5000 +</h4>
                          <h6 className="text-white">
                            Customers served Everyday
                          </h6>
                        </div>
                      </div>
                      <div
                        className="p-3 transperentbgblue2 wow slideInRight"
                        data-wow-duration="1.4s"
                      >
                        <div className="px-3 border-start border-light">
                          <h4 className="text-white m-1">2000 +</h4>
                          <h6 className="text-white">Executives to Support</h6>
                        </div>
                      </div>
                      <div
                        className="p-3 transperentbgblue2 wow slideInRight"
                        data-wow-duration="1.4s"
                      >
                        <div className="px-3  border-start border-light">
                          <h4 className="text-white m-1">1M +</h4>
                          <h6 className="text-white">
                            Air Freight tons shipped
                          </h6>
                        </div>
                      </div>
                      <div
                        className="p-3 transperentbgblue2 wow slideInRight"
                        data-wow-duration="1.4s"
                      >
                        <div className="px-3  border-start border-light">
                          <h4 className="text-white m-1">2.5M +</h4>
                          <h6 className="text-white">
                            Ocean Freight TEUs Shipped
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-12 md:mx-5 md:px-5 ">
                    {/* <div className="w-100 h-100 p-4"> */}
                    <div
                      className="transperentbgblue2 wow slideInRight m-5"
                      data-wow-duration="1.4s"
                    >
                      <div className="p-5 py-4">
                        <h4 className="text-white mb-3">
                          BECOME INTEGRAL PART OF THE WORLD FASTEST FRIGHT
                          FORWARDING COMMUNITY
                        </h4>
                        <p
                          className="text-white mb-3 fs-5  wow fadeInUp"
                          data-wow-duration="1.4s"
                        >
                          Joining this dynamic community opens doors to
                          unprecedented opportunities in the evolving landscape
                          of logistics.
                        </p>
                        <p
                          className="text-white fs-5  text-align-justify pb-1 wow fadeInUp"
                          data-wow-duration="1.4s"
                        >
                          As a member, you will be at the forefront of
                          innovation, contributing to the seamless
                          transportation of cargo beyond Earth's borders. Here,
                          collaboration, cutting-edge technology, and a shared
                          commitment to excellence converge to redefine the
                          future of logistics. Be part of this global network
                          where speed, precision, and a pioneering spirit unite
                          to propel the logistics industry into new frontiers.
                        </p>
                      </div>
                      <div></div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            <GlobalPartnerForm />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default SpaceCargo;
