import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../Pages/css/animate.min.css";
import "../Pages/css/ionicons.min.css";
import "../Pages/css/style.css";
import "../Pages/css/header.css";
import "../Pages/js/script";

function NotFoundPage() {
  return (
    <div className="slide-container p0  notfound flex flex-col items-center justify-center text-center">
      <h1 className="error">404</h1>
      <div className="page text-white">
        Ooops!!! The page you are looking for is not found
      </div>
      <Link className="text-white text-decoration-none" to="/">
        {" "}
        <h5 className="text-white pt-4">Back to Home </h5>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          height="30"
          width="30"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
          />
        </svg>
      </Link>
    </div>
  );
}

export default NotFoundPage;
