import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";

import element2png from "../img/element2.png";

import pluselement from "../img/pluselement.png";
import uparrowelement from "../img/uparrowelement.png";
import playthailand from "../img/playthailand.png";
import camthailand from "../img/camerathailand.png";
import sharethailand from "../img/sharethailand.png";
import penthailand from "../img/writingthailand.png";
import compassthailand from "../img/compassthailand.png";
import facebook from "../img/mannaifacebook.png";
import instagram from "../img/mannaiinstagram.png";
import twitter from "../img/mannaitwitter.png";
import linkedin from "../img/mannailinkedin.png";
import snapchat from "../img/mannaisnapchat.png";

import mannairoundtxt from "../img/Mannai Circle 001@72x.png";
// Json Data
import BlogData from "./BlogsData/Blogdata.json";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/visitthailand.css";
import "../css/globarpartner.css";
import "../css/blogdetail.css";
import "../js/timeline";
import "../js/script";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import Section3 from "./Section3";

const Blogs = () => {
  const params = useParams();
  const location = useLocation();
  const [blog, setBlog] = useState("");
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(params.ID);
    const selectedBlog = BlogData.find((e) => e.BlogID === params.ID);
    setBlog(selectedBlog);
    // console.log(BlogData.find((e) => e.BlogID === location.state));
    // setBlog(BlogData.find((e) => e.BlogID === location.state));

    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, [params.ID]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  return (
    <div class="main-body theme-orange dark-horizontal visithailand overflow-hidden">
      <div class="animsition">
        <div class="wrapper">
          <ReactTitle title="Blogs | Mannai Travel" />
          <Header />

          <div class="slide-container">
            {/* for banner  */}
            <div class="slide-bg wow pulse">
              <div className="inside opacityBG ">
                {blog.image && (
                  <img
                    src={require("../img/" + blog.image)}
                    alt=""
                    height="100"
                    width="100"
                    className=" img-fluid object-fit-cover"
                  />
                )}
              </div>
            </div>
            <div class="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>
              {/* <div
                className="pluselementglobal wow rotateIn"
                style={{ visibility: "visible", animationName: "rotateIn" }}
            >
                {" "}
                <img src={pluselement} alt="" />
            </div> */}
              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>
              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>
              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>
              {/* banner text  */}
              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <p
                    class="wow slideInLeft text-white fw-bold fs-1 visthailandbannerfont"
                    data-wow-duration="1.2s"
                  >
                    {blog.BlogID}
                  </p>
                  <p
                    class="normaltext  wow slideInRight fw-bold visthailandnormalfont"
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    {blog.Title}
                  </p>
                  <div>
                    <div className="leftarrowanimationelement">
                      {" "}
                      <img
                        src={element2png}
                        alt=""
                        className="ani-left-right"
                      />
                    </div>
                    <Link to={``}>
                      <a className="btn btn-primary   " href="#">
                        BOOK PACKAGE{" "}
                        <i className="icon ion-ios-arrow-thin-right"></i>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bgthailandblue p-4">
            {/* 5*6 int img with play png  */}
            <div className="col-12 col-md-2 bgsquarex mobnone tabnone">
              <div className="d-flex justify-content-center align-items-center py-5">
                <img
                  src={playthailand}
                  height="80"
                  width="80"
                  alt=""
                  class="wow pulse  "
                ></img>
              </div>
            </div>

            <div className="col-12 col-md-5 d-flex justify-content-center align-items-center padmob">
              <h3
                className="text-white text-align: center"
                style={{ textAlign: "center" }}
              >
                BEST PLACES TO VISIT IN CAPE TOWN
              </h3>
            </div>
            <div className="col-12 col-md-5">
              <div className="d-flex justify-content-center">
                <img src={camthailand} alt="" />
                <h5 className="mt-3 mb-0 ms-2 text-white author">
                  Author / Traveler
                </h5>
              </div>
              <h5 className="text-center text-white mt-3 manasi">
                Manasi Thankur
              </h5>
              <div className="d-flex justify-content-center mt-3 ms-3">
                <ul className="footersocial footer social-rounded d-flex">
                  <li>
                    <a
                      href="https://www.facebook.com/mannaitravelqatar"
                      target="_blank"
                    >
                      <img src={facebook} width="28" height="28" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/mannaitravelqatar/"
                      target="_blank"
                    >
                      <img src={instagram} width="28" height="28" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MannaiTravel" target="_blank">
                      <img src={twitter} width="28" height="28" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/83501210/admin/feed/posts/"
                      target="_blank"
                    >
                      <img src={linkedin} width="28" height="28" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.snapchat.com/add/mannaitravelqa"
                      target="_blank"
                    >
                      <img src={snapchat} width="28" height="28" />
                    </a>
                  </li>

                  <img
                    height="25"
                    className="mt-2"
                    src={sharethailand}
                    alt=""
                  />
                  <p className="mt-2 mb-0 ms-2 text-white share">Share</p>
                </ul>
              </div>
              <div className="d-flex justify-content-around mt-2 gap-2">
                <div className="d-flex justify-content-center">
                  {/* <img
                    height="25"
                    className="mt-2"
                    src={sharethailand}
                    alt=""
                  /> */}
                  {/* <p className="mt-2 mb-0 ms-2 text-white">Share</p> */}
                </div>{" "}
                <div className="d-flex justify-content-center">
                  <img height="25" className="mt-2" src={penthailand} alt="" />
                  <p className="mt-2 mb-0 ms-2 text-white  text-center">
                    Posted : 29-10-2023
                  </p>
                </div>{" "}
                <div className="d-flex justify-content-center">
                  <img
                    height="25"
                    className="mt-2"
                    src={compassthailand}
                    alt=""
                  />
                  <p className="mt-2 mb-0 ms-2 text-white text-center">
                    Read Time : 20 Mins 30 Sec
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="linearthailandbgm ">
            <div className="py-4 px-5">
              <h2 className="text-white mobcenterhead">{blog.Title2}</h2>
              <p className="text-white abouttext mt-2 mobcenterpara">
                {blog.desc}
              </p>
            </div>

            {/* ATTRACTION SECTION */}

            <div
              className="d-flex overflow-x-scroll mx-4 scroll-hidden"
              style={{ gap: "20px" }}
            >
              {/* {console.log} */}
              {blog?.attractions?.map((e) => (
                <div className="w-30 position-relative">
                  <div className=" justify-content-center align-items-center mb-4">
                    <img className="op8 car" src={e.image} alt="" />{" "}
                  </div>
                  <div className="position-absolute editcardiv shadowCard">
                    <div className="position-relative " style={{ bottom: 33 }}>
                      <h4 className="loose">{e.title} </h4>
                      <h4 className="mt-4 loose">{e.desc}</h4>
                      <img
                        className="position-absolute editcarplus"
                        src={pluselement}
                        alt=""
                        height="100"
                        width="100"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* PHI PHI SECTION  */}
            {blog?.BlogSubSections?.map((e, i) =>
              i === 0 || i % 2 === 0 ? (
                <div className="row mobborderbottom" key={i}>
                  <div className="col-12 col-lg-3 MesgPicthailand wow fadeInLeft">
                    <img className="roundedtop  phiphi1" src={e.image} />{" "}
                  </div>

                  <div className="col-12 col-lg-9 d-flex  flex-column  align-items-center position-relative wow fadeInRight ">
                    <div className="p-5">
                      <h2 className="text-white w-100 mobcenterhead ">
                        {e.Title}
                      </h2>
                      <p className="text-white abouttext mt-2">{e.desc}</p>
                      <img
                        className="position-absolute editplusnew wow rotateIn"
                        src={pluselement}
                        alt=""
                        height="100"
                        width="100"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mobborderbottom d-flex flex-lg-row flex-column-reverse">
                  <div className="col-12 col-lg-9 d-flex justify-content-center flex-column  align-items-center position-relative ">
                    <div className="p-5">
                      <h2 className="text-white text-end w-100 mobcenterhead ">
                        {e.Title}
                      </h2>
                      <p className="text-white abouttext text-end mt-2">
                        {e.desc}
                      </p>
                      <img
                        className="position-absolute editplusnewright wow rotateIn"
                        src={pluselement}
                        alt=""
                        height="100"
                        width="100"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 MesgPicthailand ">
                    <img className="roundedtop phiphi3" src={e.image} alt="" />{" "}
                  </div>
                </div>
              )
            )}

            <div className="ms-5 py-5">
              <img src={element2png} alt="" class="ani-left-right ms-5" />
              <div class=" wow fadeInUp d-flex ms-5" data-wow-delay="0.4s">
                {" "}
                <a class="btn btn-primary mr-4 fr mt-4" href="#">
                  BOOK PACKAGE
                  <i class="icon ion-ios-arrow-thin-right"></i>
                </a>{" "}
              </div>
            </div>
          </div>

          <div className="bgthailandblue px-5 py-4">
            <h3 className="text-white">FAQ'S</h3>
          </div>

          <div className="linearthailandbgm pt-4">
            {/* accordion  */}
            <div className="container">
              <div className="row">
                {/* <!-- start  --> */}
                {blog?.FAQs?.map((e, i) => (
                  <div className="col-lg-6 col-12 p-lg-4 py-2 px-3  " key={i}>
                    <div className="accordion" id={`#accordionExample${i}`}>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header m-3"
                          id={`headingOne${i}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne${i}`}
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            {e.Question}
                          </button>
                        </h2>
                        <div
                          id={`collapseOne${i}`}
                          className="accordion-collapse collapse "
                          aria-labelledby={`headingOne${i}`}
                          data-bs-parent={`#accordionExample${i}`}
                        >
                          <div className="accordion-body">
                            <p>{e.answer}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Blog list section  */}
            <div className="row py-4 px-5">
              <div className="col-12 col-lg-3 bgsquarex d-flex justify-content-center align-items-center h-150">
                <h2 className="text-white m-0">SIMILAR BLOGS</h2>
              </div>
            </div>
            <Section3 />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Blogs;
