import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";
import emailjs from "@emailjs/browser";

import Header from "../Components/Header";
import Footer from "../Components/Footer";

import WOW from "wow.js";

import biglogoservicepng from "./img/biglogoservice.png";
import roundtxtpng from "./img/roundtxt.png";
import element1png from "./img/element1.png";
import element2png from "./img/element2.png";

import shaking from "./img/businessmen-shakinHands.jpg";

import uparrowelement from "./img/uparrowelement.png";

import Dropdown from "./img/drop-down.png";
import mannairoundtxt from "./img/Mannai Circle 001@72x.png";
import qatarmap from "./img/qatarmap.png";

/////////////////////////////

import "../Pages/css/animate.min.css";
import "../Pages/css/ionicons.min.css";
import "../Pages/css/style.css";
import "../Pages/css/leisuretravel.css";
import "../Pages/css/Responsiveall.css";
import "../Pages/js/script";

////////////////////////////

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "./css/animate.min.css";
import "./css/ionicons.min.css";
import "./css/style.css";
import "./css/connectus.css";
import "./css/globarpartner.css";
import "./css/leisuretravel.css";
import "./js/script";

const ConnectUs = () => {
  const location = useLocation();
  const [acc1, setAcc1] = useState(true);
  const [acc2, setAcc2] = useState(false);
  const [acc3, setAcc3] = useState(false);
  const [acc4, setAcc4] = useState(false);
  const [acc5, setAcc5] = useState(false);
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    CompanyName: "",
    JobTitle: "",
    PhoneNumber: "",
    Country: "",
    BusinessEmail: "",
    POBOX: "",
    annualTravelSpend: "",
    areaOfInterest: "",
    message: "",
  });
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inclass: "fade-in",
        outclass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    $(document).ready(function () {
      $(".toggle-accordion").click(function () {
        const target = $($(this).data("target"));
        target.collapse("toggle");

        // Toggle the plus/minus icon
        const icon = $(this).find(".icon-plus");
        icon.toggleClass("icon-minus");
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation
    // $(".a-counter").countdown(() => {});
    new WOW().init();
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);
  const handleDivClickCRing = () => {
    const mapURL =
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3608.618452776295!2d51.563110375383474!3d25.249772977677132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE0JzU5LjIiTiA1McKwMzMnNTYuNSJF!5e0!3m2!1sen!2sin!4v1709375684960!5m2!1sen!2sin";
    window.open(mapURL, "_blank");
  };
  const handleDivClickSalwa = () => {
    const mapURL =
      "https://www.google.com/maps/place/Mannai+Travel+Salwa+Road+branch/@25.2358333,51.4480418,17z/data=!4m18!1m9!3m8!1s0x3e45d1425d422949:0xb15fc49a190f658b!2sMannai+Travel+Salwa+Road+branch!8m2!3d25.2358333!4d51.4506167!9m1!1b1!16s%2Fg%2F11ty4xh482!3m7!1s0x3e45d1425d422949:0xb15fc49a190f658b!8m2!3d25.2358333!4d51.4506167!9m1!1b1!16s%2Fg%2F11ty4xh482?entry=ttu";
    window.open(mapURL, "_blank");
  };
  const handleDivClickDRing = () => {
    const mapURL = " Mannai Travel Salwa Road branch - Google Maps";
    window.open(mapURL, "_blank");
  };
  const handleDivClickAlkhor = () => {
    const mapURL =
      "https://www.google.com/maps/place/Mannai+Air+Travels/@25.6823049,51.4931533,17z/data=!4m8!3m7!1s0x3e45f9c7dbdf2eff:0x8e23c568c8c5b006!8m2!3d25.6823049!4d51.4957282!9m1!1b1!16s%2Fg%2F11csqsyrls?entry=ttu";
    window.open(mapURL, "_blank");
  };
  const handleDivClickMesaieed = () => {
    const mapURL =
      "https://www.google.com/maps/place/Mannai+Air+Travel+-+Mesaieed+Branch/@24.9950783,51.5448274,17z/data=!4m8!3m7!1s0x3e443123b800b0b9:0x7da720ec032a1d7d!8m2!3d24.9950783!4d51.5474023!9m1!1b1!16s%2Fg%2F11p5_w70r2?entry=ttu";
    window.open(mapURL, "_blank");
  };
  const handleDivClickSheraton = () => {
    const mapURL =
      "https://www.google.com/maps/place/%D8%B3%D9%81%D8%B1%D9%8A%D8%A7%D8%AA+%D8%A7%D9%84%D9%85%D9%86%D8%A7%D8%B9%D9%8A%E2%80%AD/@25.3183005,51.5344904,18z/data=!4m6!3m5!1s0x3e45c526140d413b:0x3b5e95c4fa75c804!8m2!3d25.3190784!4d51.5369309!16s%2Fg%2F11p61zjlq3?entry=tts";
    window.open(mapURL, "_blank");
  };
  const handleDivClickBin = () => {
    const mapURL =
      "https://www.google.com/maps/place/Fereej+Bin+Durham,+Doha,+Qatar/@25.2723301,51.5232711,15z/data=!3m1!4b1!4m6!3m5!1s0x3e45c55d02e01bff:0xd3b9459ad75b280e!8m2!3d25.2723115!4d51.5335708!16s%2Fg%2F11fj7htk7l?entry=ttu";
    window.open(mapURL, "_blank");
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: fieldValue }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_e7q0n79";
    const templateId = "template_t0rxzu7";
    const publicKey = "KwGs19TOytdfp1w5Z";

    emailjs
      .send(serviceId, templateId, formData, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        alert("Email sent successfully!");
        setFormData({
          FirstName: "",
          LastName: "",
          CompanyName: "",
          WorkTitle: "",
          PhoneNumber: "",
          Country: "",
          BusinessEmail: "",
          POBOX: "",
          annualTravelSpend: "",
          areaOfInterest: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("Error sending email! Please check the console for details.");
      });
  };

  return (
    <div className="main-body theme-orange dark-horizontal connectus overflow-hidden">
      <div className="animsition">
        <div className="wrapper parallax-start">
          <ReactTitle title="Contact Us | Mannai Travel" />
          <Header />

          <div className="slide-container">
            <div className="slide-bg wow pulse">
              <div className="inside opacityBG ">
                <img
                  src={shaking}
                  alt=""
                  className=" img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div className="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>
              {/* <div
                className="pluselementglobal wow rotateIn"
                style={{ visibility: "visible", animationName: "rotateIn" }}
            >
                {" "}
                <img src={pluselement} alt="" />
            </div> */}
              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>
              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>
              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>

              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                    CONTACT US
                  </h3>
                  <p
                    className="normaltext  wow slideInRight"
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    {" "}
                    Our core expertise lies in organizing and overseeing
                    business travel efficiently. We assist companies in
                    optimizing their business travel expenditures, providing an
                    array of travel-related services delivered with exceptional
                    service standards. <br />
                    Phone:{" "}
                    <a className="text-decoration-none" href="tel:+97444282655">
                      +974 4428 2655
                    </a>
                    <br /> Email:{" "}
                    <a
                      className="text-decoration-none"
                      href="mailto:travel.services@mannai.com.qa"
                    >
                      {" "}
                      travel.services@mannai.com.qa{" "}
                    </a>
                  </p>

                  <div className="readbutton">
                    <div className="leftarrowanimationelement">
                      {" "}
                      <img
                        src={element2png}
                        alt=""
                        className="ani-left-right"
                      />
                    </div>
                    {/* <Link to={``} > */}
                    <a className="btn btn-primary   " href="#ConnectUs">
                      READ MORE <i className="icon ion-ios-arrow-thin-down"></i>
                    </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ///////////////////////////// */}
          <div className="section m-0 p-0 container1 formbg" id="ConnectUs">
            <div className="section-item ">
              <div
                className="container-fluid wow fadeInUp "
                data-wow-delay=".2s"
              >
                <div className=" formpart row  justify-content-center">
                  {/* <div className="beforeform"> */}
                  <div className="col-10 beforeform ">
                    <h4 className="wow fadeInUp mainhead mt-5">
                      LET'S ENGAGE IN A CONVERSATION !
                    </h4>
                    <div className=" mt-2">
                      <div
                        className="text-white Pyara wow fadeInUp"
                        data-wow-delay=".2s"
                      >
                        Partner with us and be a part of a rapidly growing
                        company.
                        <br />
                        Kindly fill out the form, and our sales executive will
                        connect with you shortly.
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="col-12">
                      <form
                        onSubmit={handleSubmit}
                        className="text-left wow fadeInUp  form"
                        autoComplete="off"
                      >
                        <div className="row justify-content-center gap-2 content">
                          <div className="col-md-5 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="First Name*"
                              name="FirstName"
                              value={formData.FirstName}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Last Name*"
                              name="LastName"
                              value={formData.LastName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2 content">
                          <div className="col-md-5 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Company Name*"
                              name="CompanyName"
                              value={formData.CompanyName}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="JobTitle*"
                              name="JobTitle"
                              value={formData.JobTitle}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2 content">
                          <div className="col-md-5  ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Phone Number*"
                              name="PhoneNumber"
                              value={formData.PhoneNumber}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5 ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Country(India)"
                              name="Country"
                              value={formData.Country}
                              onChange={handleChange}
                              required
                            />{" "}
                            {/* <div className="dropdown">
                          <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Dropdown button
                              </button>
                          <ul className="dropdown-menu dropdown-menu-light">
                            <li>
                              <a className="dropdown-item text-black" href="#">
                                Action
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item text-black" href="#">
                                Another action
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item text-black" href="#">
                                Something else here
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item text-black" href="#">
                                Separated link
                              </a>
                            </li>
                          </ul>
                        </div> */}
                            {/* <img
                          src={Dropdown}
                          alt=""
                          className="drop-downlocation"
                        /> */}
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2 content">
                          <div className="col-md-5  ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Business Email*"
                              name="BusinessEmail"
                              value={formData.BusinesEmail}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-5  ">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="P.O Box / Postal Code"
                              name="POBOX"
                              value={formData.POBOX}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3 gap-2 content">
                          <div className="col-md-5 ">
                            <select
                              className="form-control form-control-custom"
                              name="annualTravelSpend"
                              value={formData.annualTravelSpend}
                              onChange={handleChange}
                            >
                              <option
                                value=""
                                disabled
                                hidden
                                className="form-control form-control-custom"
                              >
                                Annual Travel Spend (in USD)
                              </option>
                              <option className=" blunbg2" value="$1000-$5000">
                                $1000-$5000
                              </option>
                              <option className="blunbg2" value="$5000-$10000">
                                $5000-$10000
                              </option>
                              <option
                                className=" blunbg2"
                                value="$10,000-$50,000"
                              >
                                $10,000-$50,000
                              </option>
                              <option
                                className="blunbg2"
                                value="$50,000-$100,000"
                              >
                                $50,000-$100,000
                              </option>
                            </select>
                            <img
                              src={Dropdown}
                              alt=""
                              className="drop-downannual"
                            />
                          </div>
                          <div className="col-md-5 ">
                            <select
                              className="form-control form-control-custom"
                              name="areaOfInterest"
                              value={formData.areaOfInterest}
                              onChange={handleChange}
                            >
                              <option
                                value=""
                                disabled
                                hidden
                                className="blunbg2"
                              >
                                Area Of Interest
                              </option>
                              <option
                                className="blunbg2"
                                value="Bussiness Travel"
                              >
                                Business Travel
                              </option>
                              <option
                                className="blunbg2"
                                value="Leisure Travel"
                              >
                                Leisure Travel
                              </option>
                            </select>
                            <img
                              src={Dropdown}
                              alt=""
                              className="drop-downannual"
                            />
                          </div>
                        </div>
                        {/* last one  */}
                        <div className="row justify-content-center mt-10 gap-2 content">
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="form-control form-control-custom"
                              placeholder="Message (1000 Character Max)"
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/* ///////////////////////// */}
                        {/* submit button  */}
                        <button
                          className="btn btn-primary my-2  formbtn wow fadeInLeft submitbtn closesum mb-5"
                          data-wow-delay="1s"
                          data-wow-duration="1.2s"
                          type="submit"
                        >
                          SUBMIT{" "}
                          <i className="icon ion-ios-arrow-thin-right"></i>
                        </button>{" "}
                      </form>
                    </div>
                  </div>
                  <div className="p-5">
                    <div className="map-canvas">
                      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d461956.1486135819!2d50.92327295000003!3d25.235833300000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45d1425d422949%3A0xb15fc49a190f658b!2sMannai%20Travel%20Salwa%20Road%20branch!5e0!3m2!1sen!2sin!4v1703577012731!5m2!1sen!2sin" loading="lazy" ></iframe> */}
                      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d334071.7287060365!2d51.134081547522364!3d25.20706855436482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sMannai%20Travel!5e0!3m2!1sen!2sin!4v1703670882166!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" ></iframe> */}
                      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d143353.43861772656!2d51.47144103667144!3d25.22286648056496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSpace%20travels%20and%20mannai%20travels!5e0!3m2!1sen!2sin!4v1703673402089!5m2!1sen!2sin" loading="lazy" ></iframe> */}
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3608.618452776295!2d51.563110375383474!3d25.249772977677132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE0JzU5LjIiTiA1McKwMzMnNTYuNSJF!5e0!3m2!1sen!2sin!4v1709375684960!5m2!1sen!2sin"
                        loading="lazy"
                      ></iframe>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="slide-container slide wow pulse locations qatarmapbg">
            <div className="container ">
              <div className="mapimg">
                <img src={qatarmap} alt="" />
              </div>
              <div className="mapheading">
                <h4
                  className="wow slideInLeft text-center"
                  data-wow-duration="1.2s"
                >
                  WE ARE AVAILABLE ACROSS
                  <br />
                  QATAR <br />
                  TO SERVE YOU BETTER{" "}
                </h4>
              </div>
              {/* 
              <div
                className=" location1 wow fadeInLeft"
                data-wow-delay="0.2s"
                data-wow-duration="1s"
              >
                <div className="reddots1">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line1" />
                <div className="add1">
                  {" "}
                  <b>MATAR QADEEM OFFICE</b>
                  <br />
                  Building No. 282, Street 310 <br />
                  Zone 45{" "}
                </div>
              </div> */}

              <div
                className=" location2 wow fadeInLeft"
                data-wow-delay="0.4s"
                data-wow-duration="1s"
                onClick={handleDivClickSheraton}
              >
                <div className="reddots2">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line2" />
                <div className="add2">
                  {" "}
                  <b>SHERATON HOTEL</b>
                  <br />
                  P.O. Box 76 Doha, Qatar <br />
                  Building 2, Street 390, Zone 61{" "}
                </div>
              </div>

              <div
                className=" location3 wow fadeInLeft"
                data-wow-delay="0.6s"
                data-wow-duration="1s"
                onClick={handleDivClickDRing}
              >
                <div className="reddots3">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line3" />
                <div className="add3">
                  {" "}
                  <b>D-RING ROAD OFFICE,</b>
                  <br />
                  P.O. Box 76 Doha, Qatar <br />
                  Building 117, Street 250, Zone 45{" "}
                </div>
              </div>

              <div
                className=" location4 wow fadeInLeft"
                data-wow-delay="0.8s"
                data-wow-duration="1s"
                onClick={handleDivClickCRing}
              >
                <div className="reddots4">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line4" />
                <div className="add4">
                  {" "}
                  <b className="uppercase">AL-NUAIMI BUILDING,</b>
                  <br />
                  Matar Qadeem, Doha, Qatar <br />
                  Building # 3, Street # 860, Zone # 45{" "}
                </div>
              </div>

              <div
                className=" location5 wow fadeInRight"
                data-wow-delay="0.2s"
                data-wow-duration="1s"
                onClick={handleDivClickAlkhor}
              >
                <div className="reddots5">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line5" />
                <div className="add5">
                  {" "}
                  <b>AL KHOR-AL THAKEERA ROAD OFFICE</b>
                  <br />
                  P.O. Box 76 Doha, Qatar <br />
                  Building 11, Street 211, Zone 74{" "}
                </div>
              </div>

              <div
                className=" location6 wow fadeInRight"
                data-wow-delay="0.41s"
                data-wow-duration="1s"
                onClick={handleDivClickBin}
              >
                <div className="reddots6">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line6" />
                <div className="add6">
                  {" "}
                  <b>BIN DIRHEM OFFICE</b>
                  <br />
                  P.O. Box 76 Doha, Qatar <br />
                  Building 94, Street 850, Zone 25{" "}
                </div>
              </div>

              <div
                className=" location7 wow fadeInRight"
                data-wow-delay="0.41s"
                data-wow-duration="1s"
                onClick={handleDivClickMesaieed}
              >
                <div className="reddots7">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line7" />
                <div className="add7">
                  {" "}
                  <b>MESAIEED OFFICE</b>
                  <br />
                  P.O. Box 76 Doha, Qatar <br />
                  Buliding 32, Street 511, Zone 92{" "}
                </div>
              </div>
              <div
                className=" location8 wow fadeInRight"
                data-wow-delay="0.41s"
                data-wow-duration="1s"
                onClick={handleDivClickSalwa}
              >
                <div className="reddots8">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line8" />
                <div className="add8">
                  {" "}
                  <b>SALWA OFFICE</b>
                  <br />
                  PO Box 76, Doha, Qatar <br />
                  Buliding 437, Street 340, Zone 56{" "}
                </div>
              </div>

              {/* <div
                className=" location8 wow fadeInRight"
                data-wow-delay="0.41s"
                data-wow-duration="1s"
              >
                <div className="reddots8">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line8" />
                <div className="add8">
                  {" "}
                  <b>MANNAI CORPORATION QPSC</b>
                  <br />
                  Building No. 72, Zone No. 57 <br />
                  PO Box 76, Doha, Qatar{" "}
                </div>
              </div> */}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default ConnectUs;
