import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";
import ReactVisibilitySensor from "react-visibility-sensor";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import WOW from "wow.js";
import CountUp from "react-countup";

import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import likeimg from "../img/like.png";

import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";

import uparrowelement from "../img/uparrowelement.png";
import wwapluselement from "../img/wwapluselement.png";
import ltbannerbg from "../img/Leisuretravelbanner.jpg";
import ltbiglogo from "../img/ltbiglogo.png";
import smallxltm from "../img/smallxltm.png";
import lteimgbg from "../img/lteimgbg.png";
import ltmimg from "../img/ltmimg.png";
import keybenibg from "../img/keybenibg.jpg";
import grayuparr from "../img/grayuparr.png";
import likeblueimg from "../img/likeblueimg.png";
import msgbox1 from "../img/msgbox1.png";
import msgbox2 from "../img/msgbox2.png";
import msgbox4 from "../img/msgbox4.png";
import msgbox5 from "../img/msgbox5.png";
import msgimg1 from "../img/booking.png";
import msgimg2 from "../img/gift-box.png";
import msgimg3 from "../img/user.png";
import msgimg4 from "../img/24-hours-support.png";
import msgimg5 from "../img/secure.png";
import qatarmap from "../img/qatarmap.png";

import mannairoundtxt from "../img/Mannai Circle 001@72x.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/globarpartner.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/Responsiveall.css";
import "../css/leisuretravel.css";
import "../js/script";

const Leisuretravel = () => {
  const location = useLocation();
  const [acc1, setAcc1] = useState(true);
  const [acc2, setAcc2] = useState(false);
  const [acc3, setAcc3] = useState(false);
  const [acc4, setAcc4] = useState(false);
  const [acc5, setAcc5] = useState(false);

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          // '<div class="spinner"><div class="mannai-logo-gif"></div></div>', // e.g '<img src="loading.svg" />'
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation
    // $(".a-counter").countdown(() => {});
    new WOW().init();
  }, []);
  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }
    console.log("hi");

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);
  const handleDivClickCRing = () => {
    const mapURL =
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3608.618452776295!2d51.563110375383474!3d25.249772977677132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE0JzU5LjIiTiA1McKwMzMnNTYuNSJF!5e0!3m2!1sen!2sin!4v1709375684960!5m2!1sen!2sin";
    window.open(mapURL, "_blank");
  };
  const handleDivClickSalwa = () => {
    const mapURL =
      "https://www.google.com/maps/place/Mannai+Travel+Salwa+Road+branch/@25.2358333,51.4480418,17z/data=!4m18!1m9!3m8!1s0x3e45d1425d422949:0xb15fc49a190f658b!2sMannai+Travel+Salwa+Road+branch!8m2!3d25.2358333!4d51.4506167!9m1!1b1!16s%2Fg%2F11ty4xh482!3m7!1s0x3e45d1425d422949:0xb15fc49a190f658b!8m2!3d25.2358333!4d51.4506167!9m1!1b1!16s%2Fg%2F11ty4xh482?entry=ttu";
    window.open(mapURL, "_blank");
  };
  const handleDivClickDRing = () => {
    const mapURL = " Mannai Travel Salwa Road branch - Google Maps";
    window.open(mapURL, "_blank");
  };
  const handleDivClickAlkhor = () => {
    const mapURL =
      "https://www.google.com/maps/place/Mannai+Air+Travels/@25.6823049,51.4931533,17z/data=!4m8!3m7!1s0x3e45f9c7dbdf2eff:0x8e23c568c8c5b006!8m2!3d25.6823049!4d51.4957282!9m1!1b1!16s%2Fg%2F11csqsyrls?entry=ttu";
    window.open(mapURL, "_blank");
  };
  const handleDivClickMesaieed = () => {
    const mapURL =
      "https://www.google.com/maps/place/Mannai+Air+Travel+-+Mesaieed+Branch/@24.9950783,51.5448274,17z/data=!4m8!3m7!1s0x3e443123b800b0b9:0x7da720ec032a1d7d!8m2!3d24.9950783!4d51.5474023!9m1!1b1!16s%2Fg%2F11p5_w70r2?entry=ttu";
    window.open(mapURL, "_blank");
  };
  const handleDivClickSheraton = () => {
    const mapURL =
      "https://www.google.com/maps/place/%D8%B3%D9%81%D8%B1%D9%8A%D8%A7%D8%AA+%D8%A7%D9%84%D9%85%D9%86%D8%A7%D8%B9%D9%8A%E2%80%AD/@25.3183005,51.5344904,18z/data=!4m6!3m5!1s0x3e45c526140d413b:0x3b5e95c4fa75c804!8m2!3d25.3190784!4d51.5369309!16s%2Fg%2F11p61zjlq3?entry=tts";
    window.open(mapURL, "_blank");
  };
  const handleDivClickBin = () => {
    const mapURL =
      "https://www.google.com/maps/place/Fereej+Bin+Durham,+Doha,+Qatar/@25.2723301,51.5232711,15z/data=!3m1!4b1!4m6!3m5!1s0x3e45c55d02e01bff:0xd3b9459ad75b280e!8m2!3d25.2723115!4d51.5335708!16s%2Fg%2F11fj7htk7l?entry=ttu";
    window.open(mapURL, "_blank");
  };

  return (
    <div className="main-body theme-orange dark-horizontal leisuretravel overflow-hidden">
      <div className="animsition">
        <div className="wrapper">
          <ReactTitle title="Leisure Travel | Mannai Travel" />
          <Header />
          <div className="slide-container">
            <div className="slide-bg wow pulse">
              <div className="inside opacityBG ">
                <img
                  src={ltbannerbg}
                  alt=""
                  className=" img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div className="container">
              <div className="downplus wow rotateIn ">
                {" "}
                <img src={roundtxtpng} alt="" />
              </div>
              {/* <div
                className="pluselementglobal wow rotateIn"
                style={{ visibility: "visible", animationName: "rotateIn" }}
            >
                {" "}
                <img src={pluselement} alt="" />
            </div> */}
              <div className="smlogoglobal">
                {" "}
                <img
                  src={biglogoservicepng}
                  alt="w-lg-100 h-lg-100 w-md-75 h-md-75 "
                />
              </div>
              <div className="downanimationelement">
                <img
                  src={element1png}
                  alt=""
                  className="wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                />
              </div>
              <div className="uparrowelementall">
                {" "}
                <img src={uparrowelement} alt="" className="ani-top-bottom" />
              </div>

              <div className="roundanimation">
                <div className="roundtxtanimationplus">
                  {" "}
                  <img src={roundtxtpng} alt="" />
                </div>
                <div className="roundtextanimation">
                  {" "}
                  <img src={mannairoundtxt} alt="" />{" "}
                </div>
              </div>
              <div className="bntextglobal d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                    LEISURE TRAVEL
                  </h3>
                  <p
                    className="normaltext  wow slideInRight"
                    data-wow-delay=".1s"
                    data-wow-duration=".80s"
                  >
                    {" "}
                    Experience the world like never before. We're your gateway
                    to unforgettable journeys, offering an array of travel
                    solutions tailored to your desires. Whether you seek
                    relaxation, adventure, or cultural exploration, our team is
                    dedicated to curving out best itinerary with competitive
                    pricing for Leisure Travel.{" "}
                  </p>
                  <div className="readbutton">
                    <div className="leftarrowanimationelement">
                      {" "}
                      <img
                        src={element2png}
                        alt=""
                        className="ani-left-right"
                      />
                    </div>
                    {/* <Link to={``} > */}
                    <a className="btn btn-primary   " href="#Leisuretravel">
                      READ MORE <i className="icon ion-ios-arrow-thin-down"></i>
                    </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid leitr" id="Leisuretravel">
            <div className="slide-body ltmbg">
              <div className="row ">
                <div className="col-lg-12">
                  <div className="col-md-12  leitravelmanagementpart ">
                    <div className="plushead">
                      {" "}
                      <img
                        src={roundtxtpng}
                        className="wow rotateIn plusrotate "
                        alt=""
                      />
                    </div>

                    <div
                      className="wow fadeInLeft NormalHeading"
                      data-wow-delay="0.2s"
                      data-wow-duration="1s"
                    >
                      Leisure Travel Management{" "}
                    </div>
                    <div
                      className="wow fadeInRight SubHeading "
                      data-wow-delay="0.4s"
                      data-wow-duration="1s"
                    >
                      {" "}
                      Your Portal to the Destinations of Your Dreams
                    </div>
                    <div
                      className="wow fadeInLeft Pyara"
                      data-wow-delay="0.6s"
                      data-wow-duration="1s"
                    >
                      Bestholidaysonline.com is our consumer portal, offers an
                      unparalleled booking experience for all your travel needs,
                      whether it's leisurely getaways or thrilling adventures.
                    </div>
                    <div>
                      {" "}
                      <a
                        className="btn  btn-primary  itmbtn wow fadeInLeft"
                        data-wow-delay="1s"
                        data-wow-duration="1.2s"
                        href="http://www.bestholidaysonline.com/"
                      >
                        VISIT OUR WESBITE TO EXPLORE LATEST DEALS
                        <i className="icon ion-ios-arrow-thin-right"></i>
                      </a>{" "}
                      <div className="ltmelementbtm ">
                        {" "}
                        <img
                          src={element2png}
                          alt=""
                          className="ani-left-right"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" itmimagepart position-relative mt-4">
                <div className="lteimgbg wow fadeInRight ani-move2">
                  {" "}
                  <img src={lteimgbg} alt="" />{" "}
                </div>
                <div className="ltmimg">
                  {" "}
                  <img src={ltmimg} alt="" />{" "}
                </div>
                <div
                  classNameName="align-items-center justify-content-between"
                  style={{ display: "flex" }}
                >
                  <div classNameName="col-12  col-lg-3 col-md-6 mobnone ">
                    <img src={ltbiglogo} alt="" className="litrlogo" />
                  </div>
                  <div classNameName="col-12  col-lg-3 col-md-6 d-flex pulsingpart">
                    <img
                      src={smallxltm}
                      alt=""
                      className="wow pulse pulseingimg"
                      data-wow-delay="300ms"
                      data-wow-iteration="infinite"
                      data-wow-duration="2s"
                    />
                    <h5
                      className="wow fadeInUp ms-3 "
                      style={{
                        position: "absolute",
                        top: "26px",
                      }}
                    >
                      {" "}
                      WALK IN TO OUR <br />
                      OFFICE NEAR YOU
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-12 manntextbg fw-normal fs-5">
              <p>
                “Step into our opulent offices across Qatar and prepare to
                encounter a travel experience like no other. Our highly
                knowledgeable travel experts, boasting extensive expertise in
                the realm of travel, will present you with an exclusive
                selection of travel options from a myriad of global suppliers.
                Expect nothing less than top-tier service coupled with
                incomparable prices. Join us in the journey of discovery and
                become a true Mannai Traveler.”
              </p>
            </div>
          </div>

          <div className="keyben ">
            <div className="section pp-scrollable slide wow pulse">
              <div className="slide-container">
                <div className="slide-bg">
                  <div
                    className="inside"
                    style={{ backgroundImage: `url(${keybenibg})` }}
                  ></div>
                </div>
                <div className="container">
                  <div className="keybenelement2 ">
                    {" "}
                    <img src={element2png} alt="" className="ani-left-right" />
                  </div>
                  <h4 className="wow slideInLeft " data-wow-duration="1.2s">
                    BENEFITS OF BEING MANNAIAN{" "}
                  </h4>
                  <div className="row horizontal-service-list">
                    <div className="col-lg-3">
                      <div className="horizontal-service-item  wow fadeInUp">
                        <div className="horizontal-service-item-title">
                          {" "}
                          <span
                            className="txt-rotate"
                            data-period="2000"
                            data-rotate='[  "SPONTANEITY" ]'
                          >
                            SPONTANEITY
                          </span>
                        </div>
                        <div className="horizontal-service-item-descr ">
                          You'll have the flexibility to make last-minute
                          decisions about your travel plans. This spontaneity
                          can lead to unexpected adventures and unique
                          experiences.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="horizontal-service-item  wow fadeInUp">
                        <div className="horizontal-service-item-title">
                          {" "}
                          <span
                            className="txt-rotate"
                            data-period="2000"
                            data-rotate='[  "AVAILABILITY" ]'
                          >
                            AVAILABILITY
                          </span>
                        </div>
                        <div className="horizontal-service-item-descr ">
                          By booking in person or on the spot, walk-in travelers
                          can secure accommodations or activities even in
                          high-demand or fully booked locations.{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="horizontal-service-item  wow fadeInUp">
                        <div className="horizontal-service-item-title">
                          {" "}
                          <span
                            className="txt-rotate"
                            data-period="2000"
                            data-rotate='[  "RECOMMEN<br />DATIONS" ]'
                          >
                            RECOMMEN
                            <br />
                            DATIONS
                          </span>
                        </div>
                        <div className="horizontal-service-item-descr ">
                          You'll often have the opportunity to speak directly
                          with travel experts, gaining valuable insights and
                          recommendations for places to visit, dine, or explore.{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="horizontal-service-item  wow fadeInUp">
                        <div className="horizontal-service-item-title">
                          {" "}
                          <span
                            className="txt-rotate"
                            data-period="2000"
                            data-rotate='[  "REDUCED STREES" ]'
                          >
                            REDUCED STREES
                          </span>
                        </div>
                        <div className="horizontal-service-item-descr ">
                          For those who enjoy living in the moment, walk-in
                          travel can reduce the stress associated with extensive
                          planning and itinerary adherence.{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="horizontal-service-item  wow fadeInUp">
                        <div className="horizontal-service-item-title">
                          {" "}
                          <span
                            className="txt-rotate"
                            data-period="2000"
                            data-rotate='[  "FLEXIBILITY" ]'
                          >
                            FLEXIBILITY
                          </span>
                        </div>
                        <div className="horizontal-service-item-descr ">
                          Itineraries can be adjusted on the fly, allowing
                          walk-in travelers to adapt to changing circumstances
                          or interests.{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="horizontal-service-item  wow fadeInUp">
                        <div className="horizontal-service-item-title">
                          {" "}
                          <span
                            className="txt-rotate"
                            data-period="2000"
                            data-rotate='[  "COST SAVINGS" ]'
                          >
                            COST SAVINGS
                          </span>
                        </div>
                        <div className="horizontal-service-item-descr ">
                          In many cases, walk-in travelers may find discounts or
                          special deals that are only available to those booking
                          on the go.{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="horizontal-service-item  wow fadeInUp">
                        <div className="horizontal-service-item-title">
                          {" "}
                          <span
                            className="txt-rotate"
                            data-period="2000"
                            data-rotate='[  "FREEDOM" ]'
                          >
                            FREEDOM
                          </span>
                        </div>
                        <div className="horizontal-service-item-descr ">
                          Experience the true essence of freedom with our
                          flexible travel options tailored to your preferences.{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="horizontal-service-item  wow fadeInUp">
                        <div className="horizontal-service-item-title">
                          {" "}
                          <span
                            className="txt-rotate text-break"
                            data-period="2000"
                            data-rotate='[  "CANCELLATIONS FEE" ]'
                          >
                            CANCELLATION
                            <br />
                            FEE
                          </span>
                        </div>
                        <div className="horizontal-service-item-descr ">
                          Avoid cancellation fees associated with pre-booked
                          arrangements if they need to change their plans.{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid btm businesslike position-relative">
            <div
              className="slide-body bg-white counterwhitebg "
              style={{ paddingBottom: "5%" }}
            >
              <div className="row ">
                <div className="col-lg-12 countpart">
                  {/* <div className="countpulusbot wow rotateIn">
                    {" "}
                    <img src={wwapluselement} alt="" />
                  </div> */}
                  <div className="graypluselement  position-absolute rotateIn d-none d-sm-block">
                    {" "}
                    <img src={wwapluselement} alt="" className="wow rotateIn" />
                  </div>
                  <div className="countuparrowelement">
                    {" "}
                    <img src={grayuparr} alt="" className="ani-top-bottom" />
                  </div>
                  <div className="countelementsmallx d-none d-sm-block">
                    {" "}
                    <img
                      src={elementsmallxpng}
                      alt=""
                      className="wow pulse pulseimagesmallx"
                      data-wow-delay="300ms"
                      data-wow-iteration="infinite"
                      data-wow-duration="2s"
                    />
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      src={wwapluselement}
                      alt=""
                      className="plusimg wow rotateIn"
                    />
                    <div
                      classNameName="d-flex mt-5   thumbdiv "
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <h5
                        className="wow fadeInLeft pt-md-5 thumbtext "
                        style={{ color: "#17355d" }}
                        data-wow-delay="0.4s"
                        data-wow-duration="1s"
                      >
                        We've excelled thus far and are now <br />
                        pushing the boundaries even further{" "}
                      </h5>
                      <div className="likeimg wow flash">
                        {" "}
                        <img src={likeblueimg} alt="" className="thumblike" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="facts row ">
                <div className="col-md-3 fact-item">
                  <div
                    className=" font-abril text-blue"
                    style={{ color: "#17355d" }}
                  >
                    <div
                      className="inside a-counter mplusblue"
                      style={{ color: "#17355d" }}
                    >
                      <ReactVisibilitySensor
                        partialVisibility
                        offset={{ bottom: 100 }}
                      >
                        {({ isVisible }) => (
                          <CountUp start={0} end={10} duration={7}>
                            {({ countUpRef, start }) => (
                              <div>
                                <span ref={countUpRef} />
                                <span
                                  className="fact-item-param mplusblue"
                                  style={{ color: "#17355d" }}
                                >
                                  M+
                                </span>
                                {start()}
                              </div>
                            )}
                          </CountUp>
                        )}
                      </ReactVisibilitySensor>
                      {/* <CountUp end={10} duration={3}></CountUp>
                      <span className="fact-item-param mplusblue">M+</span> */}
                    </div>
                    <p className="count-textblue">
                      HAPPY TRAVELLERS
                      <br />
                      PER YEAR
                    </p>
                  </div>
                </div>
                <div className="col-md-3 fact-item">
                  <div
                    className=" font-abril text-blue"
                    style={{ color: "#17355d" }}
                  >
                    <div
                      className="inside a-counter mplusblue"
                      style={{ color: "#17355d" }}
                    >
                      <ReactVisibilitySensor
                        partialVisibility
                        offset={{ bottom: 200 }}
                      >
                        {({ isVisible }) => (
                          <CountUp start={0} end={45} duration={7}>
                            {({ countUpRef, start }) => (
                              <div>
                                <span ref={countUpRef} />
                                <span
                                  className="fact-item-param mplusblue"
                                  style={{ color: "#17355d" }}
                                >
                                  +
                                </span>
                                {start()}
                              </div>
                            )}
                          </CountUp>
                        )}
                      </ReactVisibilitySensor>
                      {/* <CountUp end={45} duration={5}></CountUp>{" "}
                      <span className="fact-item-param mplusblue">+</span> */}
                    </div>
                    <p className="count-textblue">
                      YEARS IN BUSINESS
                      <br />
                      TRAVEL
                    </p>
                  </div>
                </div>
                <div className="col-md-3 fact-item">
                  <div
                    className=" font-abril text-blue"
                    style={{ color: "#17355d" }}
                  >
                    <div
                      className="inside a-counter mplusblue"
                      style={{ color: "#17355d" }}
                    >
                      <ReactVisibilitySensor
                        partialVisibility
                        offset={{ bottom: 200 }}
                      >
                        {({ isVisible }) => (
                          <CountUp start={0} end={100} duration={7}>
                            {({ countUpRef, start }) => (
                              <div>
                                <span ref={countUpRef} />
                                <span
                                  className="fact-item-param mplusblue"
                                  style={{ color: "#17355d" }}
                                >
                                  +
                                </span>
                                {start()}
                              </div>
                            )}
                          </CountUp>
                        )}
                      </ReactVisibilitySensor>
                      {/* <CountUp end={100} duration={7}></CountUp>{" "}
                      <span className="fact-item-param mplusblue">+</span> */}
                    </div>
                    <p className="count-textblue">TRAVEL EXPERTS</p>
                  </div>
                </div>
                <div className="col-md-3 fact-item">
                  <div
                    className=" font-abril text-blue"
                    style={{ color: "#17355d" }}
                  >
                    <div
                      className="inside a-counter mplusblue"
                      style={{ color: "#17355d" }}
                    >
                      <ReactVisibilitySensor
                        partialVisibility
                        offset={{ bottom: 200 }}
                      >
                        {({ isVisible }) => (
                          <CountUp start={0} end={200} duration={7}>
                            {({ countUpRef, start }) => (
                              <div>
                                <span ref={countUpRef} />
                                <span
                                  className="fact-item-param mplusblue"
                                  style={{ color: "#17355d" }}
                                >
                                  +
                                </span>
                                {start()}
                              </div>
                            )}
                          </CountUp>
                        )}
                      </ReactVisibilitySensor>
                      {/* <CountUp end={200} duration={8}></CountUp>{" "}
                      <span className="fact-item-param mplusblue">+</span> */}
                    </div>
                    <p className="count-textblue">CORPORATES</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid ltexpandbg">
            <div className="row">
              <div className="ltpluselementbot wow rotateIn">
                {" "}
                <img src={pluselement} alt="" className="ltpluseimg" />
              </div>
              <h4
                className="wow fadeInLeft"
                data-wow-delay="0.2s"
                data-wow-duration="1s"
              >
                {" "}
                EMPOWER YOUR ADVENTURES WITH <br />
                PERSONALIZED TRAVEL ASSISTANCE{" "}
              </h4>
              <div className="col-lg-6 position-relative ltexpandimgbg">
                <div className="msgbox1 wow fadeInUp " data-wow-delay="0.2s">
                  <div className="ani-top-bottom">
                    <img src={msgbox1} alt="" />
                    <div className="benefit-list-item">
                      <div className="benefit-list-photo">
                        <img classNameName="msgimg1" src={msgimg1} alt="" />
                      </div>
                      <div className="benefit-list-item-title">
                        Easy booking With Us.
                      </div>
                      {/* <div className="benefit-list-item-descr">
                        Easy navigations to complete <br />
                        booking in 3 steps.
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="msgbox2 wow fadeInUp" data-wow-delay="0.4s">
                  <div className="ani-top-bottom">
                    <img src={msgbox4} alt="" />
                    <div className="benefit-list-item-long">
                      <div className="benefit-list-photo">
                        <img classNameName="msgimg4" src={msgimg3} alt="" />
                      </div>
                      <div className="benefit-list-item-title">
                        Centralised Profile Management
                      </div>
                      {/* <div className="benefit-list-item-descr">
                        Centralised Profile Management with Mannai travel.
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="msgbox3 wow fadeInUp" data-wow-delay="0.6s">
                  <div className="ani-top-bottom">
                    <img src={msgbox5} alt="" />
                    <div className="benefit-list-item">
                      <div className="benefit-list-photo">
                        <img classNameName="msgimg5" src={msgimg2} alt="" />
                      </div>
                      <div className="benefit-list-item-title">
                        Offers & Promotions{" "}
                      </div>
                      {/* <div className="benefit-list-item-descr">
                        Best Offers & Promotions from Mannai Travel.
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="msgbox4 wow fadeInUp" data-wow-delay="0.8s">
                  <div className="ani-top-bottom">
                    {" "}
                    <img src={msgbox2} alt="" />
                    <div className="benefit-list-item">
                      <div className="benefit-list-photo">
                        <img classNameName="msgimg2" src={msgimg4} alt="" />
                      </div>
                      <div className="benefit-list-item-title">
                        Round the clock Support{" "}
                      </div>
                      {/* <div className="benefit-list-item-descr">
                        With Mannai Travel you will get Round the clock
                        Support.
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="msgbox5 wow fadeInUp " data-wow-delay="1s">
                  <div className="ani-top-bottom">
                    {" "}
                    <img src={msgbox2} alt="" />
                    <div className="benefit-list-item">
                      <div className="benefit-list-photo">
                        <img classNameName="msgimg2" src={msgimg5} alt="" />
                      </div>
                      <div className="benefit-list-item-title">
                        Secured & Reliable
                      </div>
                      {/* <div className="benefit-list-item-descr">
                        24/7 online support from Mannai Travel.
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 ">
                <div
                  className="accordion solution-collapse accordionpart"
                  id="accordionExample"
                >
                  <div className="solution-collapse-item accordion-item">
                    <div
                      className="solution-collapse-item-title font-libre accordion-header"
                      id="headingOne"
                    >
                      {" "}
                      <a
                        classNameName={`${!acc1 ? "collapsed" : ""}`}
                        type="button"
                        // href="#"
                        data-bs-toggle="collapse"
                        data-bs-target="#solution1"
                        aria-expanded="true"
                        aria-controls="solution1"
                        onClick={() => {
                          setAcc1(!acc1);
                          // setAcc2(false);
                          // setAcc3(false);
                          // setAcc4(false);
                          // setAcc5(false);
                        }}
                      >
                        {" "}
                        Centralized Profile Management{" "}
                        <i className="icon-plus"></i>{" "}
                      </a>{" "}
                    </div>
                    <div
                      id="solution1"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#solutions"
                    >
                      <div className="card-body">
                        {" "}
                        With Mannai's cross-platform apps, your profile is
                        securely and centrally managed. Whether you access our
                        apps or visit any of our offices, all your travel
                        information is just one touch away. Thats how we enhance
                        your travel experience, providing seamless service.{" "}
                      </div>
                    </div>
                  </div>
                  <div className="solution-collapse-item accordion-item">
                    <div
                      className="solution-collapse-item-title font-libre accordion-header"
                      id="headingTwo"
                    >
                      {" "}
                      <a
                        classNameName={`${!acc2 ? "collapsed" : ""}`}
                        // href="#"
                        data-bs-toggle="collapse"
                        data-bs-target="#solution2"
                        aria-expanded="false"
                        aria-controls="solution2"
                        onClick={() => {
                          setAcc2(!acc2);
                          // setAcc1(false);
                          // setAcc3(false);
                          // setAcc4(false);
                          // setAcc5(false);
                        }}
                      >
                        {" "}
                        Get the Best Travel Options{" "}
                        <i className="icon-plus"></i>{" "}
                      </a>{" "}
                    </div>
                    <div
                      id="solution2"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#solutions"
                    >
                      <div className="card-body">
                        {" "}
                        When you choose Mannai Travel, you unlock exclusive
                        benefits, especially in the realm of exclusive offers
                        and promotions. Enjoy privileged access to limited-time
                        deals, discounts, and special packages tailored to
                        enhance your travel experience. With Mannai, you'll
                        savor unparalleled savings and opportunities that make
                        your journeys truly extraordinary.
                      </div>
                    </div>
                  </div>
                  <div className="solution-collapse-item accordion-item">
                    <div
                      className="solution-collapse-item-title font-libre accordion-header"
                      id="headingThree"
                    >
                      {" "}
                      <a
                        classNameName={`${!acc3 ? "collapsed" : ""}`}
                        // href="#"
                        data-bs-toggle="collapse"
                        data-bs-target="#solution3"
                        aria-expanded="false"
                        aria-controls="solution3"
                        onClick={() => {
                          setAcc3(!acc3);
                          // setAcc2(false);
                          // setAcc1(false);
                          // setAcc4(false);
                          // setAcc5(false);
                        }}
                      >
                        {" "}
                        Exclusive offers and promotions{" "}
                        <i className="icon-plus"></i>{" "}
                      </a>{" "}
                    </div>
                    <div
                      id="solution3"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#solutions"
                    >
                      <div className="card-body">
                        {" "}
                        Experience the ultimate value for money in travel with
                        the Mannai app. Uncover cost-effective travel options
                        curated for your satisfaction. Our selection ensures you
                        enjoy top-notch travel without breaking the bank. Choose
                        Mannai for a better, budget-friendly travel experience.
                      </div>
                    </div>
                  </div>
                  <div className="solution-collapse-item accordion-item">
                    <div
                      className="solution-collapse-item-title font-libre accordion-header"
                      id="headingFour"
                    >
                      {" "}
                      <a
                        classNameName={`${!acc4 ? "collapsed" : ""}`}
                        // href="#"
                        data-bs-toggle="collapse"
                        data-bs-target="#solution4"
                        aria-expanded="false"
                        aria-controls="solution4"
                        onClick={() => {
                          setAcc4(!acc4);
                          // setAcc2(false);
                          // setAcc3(false);
                          // setAcc1(false);
                          // setAcc5(false);
                        }}
                      >
                        {" "}
                        Paragon of Security <i className="icon-plus"></i>{" "}
                      </a>{" "}
                    </div>
                    <div
                      id="solution4"
                      className="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#solutions"
                    >
                      <div className="card-body">
                        {" "}
                        Rest assured, our apps are a paragon of security and
                        reliability. We employ state-of-the-art encryption to
                        safeguard your data, ensuring your privacy is paramount.
                        With a robust infrastructure, our apps offer seamless
                        performance, delivering a consistently dependable user
                        experience. Trust in our commitment to providing you
                        with secure and reliable technology that you can rely
                        on, every time you use our apps.
                      </div>
                    </div>
                  </div>
                  <div className="solution-collapse-item accordion-item">
                    <div
                      className="solution-collapse-item-title font-libre accordion-header"
                      id="headingFive"
                    >
                      {" "}
                      <a
                        classNameName={`${!acc5 ? "collapsed" : ""}`}
                        // href="#"
                        data-bs-toggle="collapse"
                        data-bs-target="#solution5"
                        aria-expanded="false"
                        aria-controls="solution5"
                        onClick={() => {
                          setAcc5(!acc5);
                          // setAcc2(false);
                          // setAcc3(false);
                          // setAcc4(false);
                          // setAcc1(false);
                        }}
                      >
                        {" "}
                        24/7 online support <i className="icon-plus"></i>{" "}
                      </a>{" "}
                    </div>
                    <div
                      id="solution5"
                      className="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#solutions"
                    >
                      <div className="card-body">
                        {" "}
                        With our 24/7 support, you gain peace of mind for both
                        travel-related and journey-related queries. Our
                        dedicated team is here to assist you around the clock,
                        ensuring seamless travel experiences. Whether you need
                        assistance with bookings, itinerary changes, or any
                        inquiries during your journey, we're just a call away.
                        Count on us for prompt, reliable support that makes
                        every aspect of your travel worry-free.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bottext">
                  <h4
                    className="wow fadeInLeft"
                    data-wow-delay="0.2s"
                    data-wow-duration="1s"
                  >
                    {" "}
                    BENEFITS ABOVE YOUR IMAGINATION
                  </h4>
                </div>
              </div>

              <div className="col-lg-1 "></div>
            </div>
          </div>

          <div className="slide-container slide wow pulse locations qatarmapbg">
            <div className="container ">
              <div className="mapimg">
                <img src={qatarmap} alt="" />
              </div>
              <div className="mapheading">
                <h4
                  className="wow slideInLeft text-center"
                  data-wow-duration="1.2s"
                >
                  WE ARE AVAILABLE ACROSS
                  <br />
                  QATAR <br />
                  TO SERVE YOU BETTER{" "}
                </h4>
              </div>
              {/* 
              <div
                className=" location1 wow fadeInLeft"
                data-wow-delay="0.2s"
                data-wow-duration="1s"
              >
                <div className="reddots1">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line1" />
                <div className="add1">
                  {" "}
                  <b>MATAR QADEEM OFFICE</b>
                  <br />
                  Building No. 282, Street 310 <br />
                  Zone 45{" "}
                </div>
              </div> */}

              <div
                className=" location2 wow fadeInLeft"
                data-wow-delay="0.4s"
                data-wow-duration="1s"
                onClick={handleDivClickSheraton}
              >
                <div className="reddots2">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line2" />
                <div className="add2">
                  {" "}
                  <b>SHERATON HOTEL</b>
                  <br />
                  P.O. Box 76 Doha, Qatar <br />
                  Building 2, Street 390, Zone 61{" "}
                </div>
              </div>

              <div
                className=" location3 wow fadeInLeft"
                data-wow-delay="0.6s"
                data-wow-duration="1s"
                onClick={handleDivClickDRing}
              >
                <div className="reddots3">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line3" />
                <div className="add3">
                  {" "}
                  <b>D-RING ROAD OFFICE,</b>
                  <br />
                  P.O. Box 76 Doha, Qatar <br />
                  Building 117, Street 250, Zone 45{" "}
                </div>
              </div>

              <div
                className=" location4 wow fadeInLeft"
                data-wow-delay="0.8s"
                data-wow-duration="1s"
                onClick={handleDivClickCRing}
              >
                <div className="reddots4">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line4" />
                <div className="add4">
                  {" "}
                  <b className="uppercase">AL-NUAIMI BUILDING,</b>
                  <br />
                  Matar Qadeem, Doha, Qatar <br />
                  Building # 3, Street # 860, Zone # 45{" "}
                </div>
              </div>

              <div
                className=" location5 wow fadeInRight"
                data-wow-delay="0.2s"
                data-wow-duration="1s"
                onClick={handleDivClickAlkhor}
              >
                <div className="reddots5">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line5" />
                <div className="add5">
                  {" "}
                  <b>AL KHOR-AL THAKEERA ROAD OFFICE</b>
                  <br />
                  P.O. Box 76 Doha, Qatar <br />
                  Building 11, Street 211, Zone 74{" "}
                </div>
              </div>

              <div
                className=" location6 wow fadeInRight"
                data-wow-delay="0.41s"
                data-wow-duration="1s"
                onClick={handleDivClickBin}
              >
                <div className="reddots6">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line6" />
                <div className="add6">
                  {" "}
                  <b>BIN DIRHEM OFFICE</b>
                  <br />
                  P.O. Box 76 Doha, Qatar <br />
                  Building 94, Street 850, Zone 25{" "}
                </div>
              </div>

              <div
                className=" location7 wow fadeInRight"
                data-wow-delay="0.41s"
                data-wow-duration="1s"
                onClick={handleDivClickMesaieed}
              >
                <div className="reddots7">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line7" />
                <div className="add7">
                  {" "}
                  <b>MESAIEED OFFICE</b>
                  <br />
                  P.O. Box 76 Doha, Qatar <br />
                  Buliding 32, Street 511, Zone 92{" "}
                </div>
              </div>
              <div
                className=" location8 wow fadeInRight"
                data-wow-delay="0.41s"
                data-wow-duration="1s"
                onClick={handleDivClickSalwa}
              >
                <div className="reddots8">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line8" />
                <div className="add8">
                  {" "}
                  <b>SALWA OFFICE</b>
                  <br />
                  PO Box 76, Doha, Qatar <br />
                  Buliding 437, Street 340, Zone 56{" "}
                </div>
              </div>

              {/* <div
                className=" location8 wow fadeInRight"
                data-wow-delay="0.41s"
                data-wow-duration="1s"
              >
                <div className="reddots8">
                  <i className="icon ion-ios-location"></i>
                </div>
                <hr className="line8" />
                <div className="add8">
                  {" "}
                  <b>MANNAI CORPORATION QPSC</b>
                  <br />
                  Building No. 72, Zone No. 57 <br />
                  PO Box 76, Doha, Qatar{" "}
                </div>
              </div> */}
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Leisuretravel;
