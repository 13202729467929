import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

// import logopng from "./img/logo.png";
// import biglogopng from "./img/biglogobg.png";
import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";
import uparrowelement from "../img/uparrowelement.png";
import playthailand from "../img/playthailand.png";
import camthailand from "../img/camerathailand.png";
import sharethailand from "../img/sharethailand.png";
import penthailand from "../img/writingthailand.png";
import compassthailand from "../img/compassthailand.png";
import thailandcarouselpic from "../img/Grand-palace.png";
import MesgPicthailand from "../img/MesgPicthailand.png";
import accplus from "../img/accplus.png";
import blogimg2 from "../img/blogimg2.jpg";
import blogimg1 from "../img/blogimg1.jpg";
import carousel1 from "../img/carousel1.png";
import DegreeCareer from "../img/icons8-degree-60.png";
import frontview from "../img/front-view.jpg";
import linkicon from "../img/linkicon.png";
import CareerBanner from "../img/careerbanner.jpg";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/CareerDetail.css";
import "../js/timeline";
import "../js/script";

import PartnersData from "./GlobalPartnerData.json";

import img33 from "../img/op1.jpg";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Partners from "../../Components/Partners";
import GlobalPartnerSection1 from "./GlobalPartnerSection1";

const GlobalPartnerSection2 = () => {
  const location = useLocation();
  const [blog, setBlog] = useState("");

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  return (
    <>
      <div className="main-body theme-orange dark-horizontal careersection3 overflow-hidden">
        <div className="animsition">
          <div className="wrapper parallax-start">
            <ReactTitle title="GlobalPartner | Mannai Travel" />
            <div className="clearfix" />
            <div className="section p0 ">
              <div className="section-item globalbnbg">
                <div className="container-fluid p-md-4 p-2">
                  <div className="row p-md-4 p-2">
                    {PartnersData?.GlobalPartners?.map((e, i) => (
                      <>
                        <div
                          key={e.PartnerId}
                          className="d-flex flex-lg-row flex-md-column flex-column align-items-center justify-content-center"
                        >
                          <div className="d-flex col-12 col-md-12 col-lg-4 globallogo pb-md-2 pb-2 pb-lg-0 h-md-100">
                            <img
                              src={require("../img/" + e.image)}
                              alt="img"
                              className="d-flex justify-content-center align-items-center  w-75  h-75 img-fluid wow slideInLeft"
                              data-wow-duration="1.2s"
                            />
                          </div>
                          <div className="d-flex  col-12 col-md-12 col-lg-8 textglobalp flex-fill position-relative h-md-100 ">
                            <p
                              className="normaltext wow slideInRight d-flex justify-content-center align-items-center"
                              data-wow-delay=".1s"
                              data-wow-duration=".80s"
                            >
                              {e.Description}
                            </p>
                          </div>
                        </div>

                        <div className="p-md-4 p-3 mb-lg-4 mb-2">
                          <hr className="lineglobal" />
                        </div>

                        <div className="d-flex flex-column align-items-start p-md-4 p-2">
                          <div className="pl-4 text-white">
                            <h4 className=" font-normal">{e.title}</h4>
                          </div>
                          <div className="align-items-start">
                            {e.Services.map((e, i) => (
                              <button key={i} className="m-2 btn btn-primary">
                                {e}
                              </button>
                            ))}
                          </div>
                          <div className="d-flex w-100 justify-content-end ">
                            <div className="">
                              <p className="text-white small mb-0">
                                MORE DETAILS
                              </p>
                              <hr className="text-white m-0 " />
                              <a
                                className="text-white small text-decoration-none mt-1"
                                href={e.visitwebsite}
                                target="_blank"
                              >
                                Visit Website
                                <img src={linkicon} className="m-1  h-25" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="linelinear p-md-4 p-3 mb-md-4 mb-2">
                          <hr className="lineglobal1" />
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalPartnerSection2;
