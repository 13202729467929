import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import serviceimg1 from "../img/flights.jpg";
import serviceimg2 from "../img/hotels.jpg";
import serviceimg3 from "../img/carrentals.jpg";
import serviceimg4 from "../img/railsandcruises.jpg";
import serviceimg5 from "../img/QatarPP.png";
import serviceimg6 from "../img/meetandgreet.jpg";
import serviceimg7 from "../img/micebg.jpg";
import serviceimg8 from "../img/insurance.jpeg";
import serviceimg9 from "../img/internationaldriverlicense.png";
import serviceimg10 from "../img/247support.jpg";
import Header from "../../Components/Header";
import "animsition";
import "animsition/dist/css/animsition.css";

import "pagepiling.js";
import "pagepiling.js/jquery.pagepiling.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../js/script";
import ServicesSlide1 from "./ServicesSlide1";
import ServicesSlide2 from "./ServiceSlide2";

const Services = () => {
  const location = useLocation();
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div className="spinner"><div className="double-bounce1"></div><div className="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    $(document).ready(function () {
      window.$(".a-pagepiling").pagepiling({
        scrollingSpeed: 280,
        menu: "#menu",
        loopTop: true,
        loopBottom: true,
        navigation: false,
      });
    });

    let wow = new WOW({
      boxClass: "wow", // default
      animateClass: "animated", // default
      offset: 0, // default
      mobile: true, // default
      live: true, // default
    });
    wow.init();
    document.body.style.overflow = "hidden";

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // return () => {
    //   document.body.style.overflow = "auto";
    // };

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    new WOW().init();
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  const toggleClassToHtml = () => {
    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    } else {
      htmlTag.classList.add("body-menu-opened");
      htmlTag.classList.remove("body-menu-close");
    }
  };

  return (
    <div className="main-body theme-orange dark-horizontal servicepage ">
      <div className="animsition">
        <div className="wrapper">
          <ReactTitle title="Services | Mannai Travel" />
          <Header />

          <div className="project-list-piling a-pagepiling full-height">
            <div className="section pp-scrollable slide slide-dark" id="ATF">
              <div className="slide-container ">
                <div className="slide-bg animsition">
                  <div
                    className="inside opacityBG"
                    style={{ backgroundImage: `url(${serviceimg1})` }}
                  ></div>
                </div>
                <ServicesSlide1
                  title={"FLIGHTS"}
                  description={
                    "Over the years, Mannai Travel has built a solid reputation for its highly responsive and personalized services. As an accredited IATA agent, we provide seamless and hassle-free flight bookings with reasonable fares for corporate travelers, business owners, and VIPs across the globe. With our expertise and dedication to customer satisfaction, we strive to exceed expectations and make every journey a memorable experience."
                  }
                />
              </div>
            </div>
            <div
              className="section pp-scrollable slide slide-dark"
              id="HotelsAccomudation"
            >
              <div className="slide-container">
                <div className="slide-bg " id="rm">
                  <div
                    className="inside opacityBG"
                    style={{ backgroundImage: `url(${serviceimg2})` }}
                  ></div>
                </div>
                <ServicesSlide2
                  title={"HOTELS"}
                  description={
                    "Count on Mannai Travel to find your perfect stay and experience a seamless travel journey with us. With a knowledgeable team and access to diverse accommodation option, we exceed expectations with competitive rates and exclusive perks."
                  }
                />
              </div>
            </div>
            <div
              className="section pp-scrollable slide slide-dark"
              id="CarRentals"
            >
              <div className="slide-container">
                <div className="slide-bg ">
                  <div
                    className="inside opacityBG"
                    style={{ backgroundImage: `url(${serviceimg3})` }}
                  ></div>
                </div>
                <ServicesSlide1
                  title={"CAR RENTALS"}
                  description={
                    "Discover the freedom of the open roads with Mannai Travel. Offering a wide range of options through our international partners, we make renting a car a breeze to ensure a convenient and hassle-free travel experience."
                  }
                />
              </div>
            </div>
            <div className="section pp-scrollable slide slide-dark" id="Rail">
              <div className="slide-container">
                <div className="slide-bg ">
                  <div
                    className="inside opacityBG"
                    style={{ backgroundImage: `url(${serviceimg4})` }}
                  ></div>
                </div>
                <ServicesSlide2
                  title={"RAILS & CRUISES"}
                  title2={" "}
                  description={
                    "Experiment with new modes of traveling with panoramic views from your window. Mannai Travel offers an unbeatable combination of rail and sea travel. As a preferred partner of the world’s leading cruise lines and rails with access to more than 25,000 destinations across 30 countries, we will be a gateway to your long-lasting memories."
                  }
                />
              </div>
            </div>
            <div
              className="section pp-scrollable slide slide-dark"
              id="PassportVisa"
            >
              <div className="slide-container ">
                <div className="slide-bg ">
                  <div
                    className="inside opacityBG"
                    style={{ backgroundImage: `url(${serviceimg5})` }}
                  ></div>
                </div>
                <ServicesSlide1
                  title={"VISA ASSISTANCE"}
                  description={
                    "As an official and exclusive partner for VFS Global in Qatar, Mannai Travel will guide through the Visa application and documentation process seamless."
                  }
                />
              </div>
            </div>
            <div
              className="section pp-scrollable slide slide-dark"
              id="MeetGreet"
            >
              <div className="slide-container">
                <div className="slide-bg ">
                  <div
                    className="inside opacityBG"
                    style={{ backgroundImage: `url(${serviceimg6})` }}
                  ></div>
                </div>
                <ServicesSlide2
                  title={"MEET & GREET"}
                  description={
                    "Arrive in style with Mannai Travel's Meet & Greet Services. We ensure a smooth transition through immigration, baggage handling and provides services like fast-tracking through security. Whether solo or corporate, we go beyond transportation, offering assistance with visas and currency exchange."
                  }
                />
              </div>
            </div>
            <div className="section pp-scrollable slide slide-dark" id="Mice">
              <div className="slide-container">
                <div className="slide-bg">
                  <div
                    className="inside opacityBG"
                    style={{ backgroundImage: `url(${serviceimg7})` }}
                  ></div>
                </div>
                <ServicesSlide1
                  title={" M.I.C.E. "}
                  description={
                    "Elevate your corporate events and go beyond expectations. Mannai Travel is at the forefront of Qatar's thriving MICE industry (Meetings, Incentives, Conferences, and Exhibitions services). Our team collaborates closely with clients to conceptualize and execute extraordinary methods to ensure a multi-dimensional experience for your event."
                  }
                />
              </div>
            </div>
            <div
              className="section pp-scrollable slide slide-dark"
              id="TravelInsurance"
            >
              <div className="slide-container">
                <div className="slide-bg ">
                  <div
                    className="inside opacityBG"
                    style={{ backgroundImage: `url(${serviceimg8})` }}
                  ></div>
                </div>
                <ServicesSlide2
                  title={"TRAVEL INSURANCE "}
                  description={
                    "Simplify the process to get travel insurance with the help of a seasoned expert in Qatar. Mannai Travel guides through different coverages and benefits with extensive partnerships and access to trusted providers. We ensure clarity on policy terms and remain by your side in emergencies to safeguard your travel investment for a worry-free exploration of the world."
                  }
                />
              </div>
            </div>
            <div
              className="section pp-scrollable slide slide-dark"
              id="InternationalDrivingLicense"
            >
              <div className="slide-container">
                <div className="slide-bg ">
                  <div
                    className="inside opacityBG"
                    style={{ backgroundImage: `url(${serviceimg9})` }}
                  ></div>
                </div>
                <ServicesSlide1
                  title={"INTERNATIONAL DRIVING LICENSE"}
                  description={
                    "Mannai Travel is dedicated to empowering Qatar residents to confidently explore international roads with their IDL. With a streamlined and straightforward process, we make it easy to obtain an IDL by providing comprehensive guidance on prerequisites within your means along with comfort and convenience."
                  }
                />
              </div>
            </div>
            <div
              className="section pp-scrollable slide slide-dark"
              id="Support"
            >
              <div className="slide-container">
                <div className="slide-bg">
                  <div
                    className="inside opacityBG"
                    style={{ backgroundImage: `url(${serviceimg10})` }}
                  ></div>
                </div>
                <ServicesSlide2
                  title={"24/7 SUPPORT"}
                  description={
                    "Your journey is our top priority, and we're here to assist anytime. At Mannai Travel, we recognize the diverse ways our clients prefer to communicate and reach out to us at any time to ensure that their questions, concerns, or urgent inquiries are addressed promptly. We're committed to meeting our clients where they are, making it convenient and easy to connect whenever assistance is required to ensure your travel experiences are seamless and worry-free."
                  }
                />
              </div>
            </div>
            {/* <div className="section pp-scrollable slide slide-dark" id="Getintouch">
            <Footer />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
