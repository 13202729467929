import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/CareerDetail.css";
import "../js/timeline";
import "../js/script";

const CareerSection2 = () => {
  const location = useLocation();
  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);

  return (
    <>
      <div
        className="main-body theme-orange dark-horizontal careersection2 overflow-hidden"
        id="Careers"
      >
        <div className="animsition">
          <div className="wrapper parallax-start">
            <ReactTitle title="Career | Mannai Travel" />
            <div className="clearfix" />
            <div className="section section-item p0">
              <div className="container-fluid">
                <div className="slide-body carblunbg">
                  <div className="row ">
                    <div className="col-lg-12">
                      <div
                        className="col-md-12  wow fadeInRight"
                        data-wow-delay="0.2s"
                        data-wow-duration="1s"
                      >
                        <h5>
                          Join us in shaping the future of travel and discover
                          the endless opportunities that await you at Mannai
                          Travels. Your career's best moments start here
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section section-item p0">
              <div className="container-fluid">
                <div className="slide-body dblunbg">
                  <div className="row  ">
                    <div className="col-lg-12 ">
                      <div
                        className="col-md-12  wow fadeInRight"
                        data-wow-delay="0.2s"
                        data-wow-duration="1s"
                      >
                        <h5>
                          Take a seat at Mannai Travel and let your skills be
                          the missing piece in our team.
                        </h5>
                      </div>
                      <div className=" d-flex flex-lg-row flex-column justify-content-around p-lg-0 px-md-5 p-2">
                        <div className=" px-lg-0 px-5">
                          <h5>Search keyword</h5>
                          <input
                            type="text"
                            className="form-control form-control-custom"
                          />
                        </div>
                        <div className=" px-lg-0 px-5">
                          <h5>Department</h5>
                          <input
                            type="text"
                            className="form-control form-control-custom"
                          />
                        </div>
                        <div className=" px-lg-0 px-5">
                          <h5>Location</h5>
                          <input
                            type="text"
                            className="form-control form-control-custom"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center p-5">
                    <a className="btn btn-primary mr-4  " href="#">
                      SEARCH JOBS{" "}
                      <i className="icon ion-ios-arrow-thin-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerSection2;
