import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/WhatWeDoPage/Home";
import Technologies from "../Pages/TechnologiesPage/Technologies";

import Whoweare from "../Pages/WhoWeArePage/Whoweare";
import Leisuretravel from "../Pages/LeisureTravelPage/Leisuretravel";
import Businesstravel from "../Pages/BussinessTravelPage/Businesstravel";
import Blogandnews from "../Pages/BlogsAndNewsPage/Blogsandnews";
import VisitThailand from "../Pages/BlogsAndNewsPage/Blogs";
import News from "../Pages/BlogsAndNewsPage/News";
import Partners from "../Components/Partners";
// by me
import ConnectUs from "../Pages/ConnectUs";
import TermsandConditions from "../Pages/Terms&Conditions";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import CookiePolicy from "../Pages/CookiePolicy";
import CareerDetails from "../Pages/CareersPage/CareerDetails";
import Career from "../Pages/CareersPage/Career";

// separate components from blogsandnews

import Section1 from "../Pages/BlogsAndNewsPage/Section1";

import Section3 from "../Pages/BlogsAndNewsPage/Section3";
import Section4 from "../Pages/BlogsAndNewsPage/Section4";
import SectionShape from "../Pages/BlogsAndNewsPage/SectionShape";
import CareerSection1 from "../Pages/CareersPage/CareerSection1";
import CareerSection2 from "../Pages/CareersPage/CareerSection2";
import CareerSection3 from "../Pages/CareersPage/CareerSection3";
import CareerDetailSection1 from "../Pages/CareersPage/CareerDetailSection1";
import SpaceTravel from "../Pages/SpaceTravelPage/SpaceTravel";
import MannaiHoliday from "../Pages/MannaiHolidayPage/MannaiHoliday";
import GlobalPartner from "../Pages/GlobalPartnersPage/GlobalPartner";
import GlobalPartnerSection1 from "../Pages/GlobalPartnersPage/GlobalPartnerSection1";
import GlobalPartnerForm from "../Pages/GlobalPartnersPage/GlobarPartnerForm";
import GlobalPartnerSection2 from "../Pages/GlobalPartnersPage/GlobalPartnerSection2";
import SpaceCargo from "../Pages/SpaceTravelPage/SpaceCargo";
import Services from "../Pages/WhatWeDoPage/Services";
import NotFoundPage from "../Components/NotFoundPage";

const PRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Technologies" element={<Technologies />} />
        <Route path="/Whoweare" element={<Whoweare />} />
        <Route path="/Leisuretravel" element={<Leisuretravel />} />
        <Route path="/Businesstravel" element={<Businesstravel />} />
        <Route path="/Blogsandnews" element={<Blogandnews />} />
        <Route path="/Blogsandnews/Blogs/:ID" element={<VisitThailand />} />
        <Route path="/Blogsandnews/News/:ID" element={<News />} />
        <Route path="/Section1" element={<Section1 />} />
        <Route path="/SectionShape" element={<SectionShape />} />
        <Route path="/Section3" element={<Section3 />} />
        <Route path="/Section4" element={<Section4 />} />
        <Route path="/connectus" element={<ConnectUs />} />
        <Route path="/termsandconditions" element={<TermsandConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/cookiepolicy" element={<CookiePolicy />} />
        <Route path="/Career/CareerDetails/:ID" element={<CareerDetails />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/CareerSection1" element={<CareerSection1 />} />
        <Route path="/CareerSection2" element={<CareerSection2 />} />
        <Route path="/CareerSection3" element={<CareerSection3 />} />
        <Route
          path="/CareerDetailSection1"
          element={<CareerDetailSection1 />}
        />
        <Route path="/SpaceTravel" element={<SpaceTravel />} />
        <Route path="/SpaceCargo" element={<SpaceCargo />} />
        {/* <Route path="/SpaceLuftansa" element={<SpaceTravel />}/> */}
        <Route path="/MannaiHoliday" element={<MannaiHoliday />} />
        <Route path="/GlobalPartner" element={<GlobalPartner />} />
        <Route path="/partners" element={<Partners />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default PRoute;
