import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import element2png from "../img/element2.png";

import pluselement from "../img/pluselement.png";

import playicon from "../img/playicon.png";

import ltbiglogo from "../img/ltbiglogo.png";
import smallxltm from "../img/smallxltm.png";

// Json Data
import Sec4DataJson from "./SectionData/Section4.json";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/Section4.css";
import "../js/script";
import Partners from "../../Components/Partners";

const Section4 = () => {
  const location = useLocation();

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation
    // $(".a-counter").countdown(() => {});
    new WOW().init();
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, []);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  return (
    <div class="main-body theme-orange dark-horizontal section4 overflow-hidden">
      <div class="animsition">
        <div class="wrapper">
          <ReactTitle title="Blogs & News | Mannai Travel" />
          {/* <Header /> */}

          {/* SECTION - 4  */}
          <div class="newspart">
            <div class="section section-item p0 ">
              <div class="container-fluid ">
                <div class="slide-body newsbg">
                  <div class="row  ">
                    <div class="col-lg-12">
                      <div class="col-md-12  newscontentpart ">
                        <h3
                          class="wow fadeInRight "
                          data-wow-delay="0.2s"
                          data-wow-duration="1s"
                        >
                          NEWS{" "}
                        </h3>
                        <div class="newsbiglogo ">
                          {" "}
                          <img src={ltbiglogo} alt="" />
                        </div>
                        <div class="smallxnews  d-sm-block">
                          {" "}
                          <img
                            src={smallxltm}
                            alt=""
                            class="wow pulse"
                            data-wow-delay="300ms"
                            data-wow-iteration="infinite"
                            data-wow-duration="2s"
                          />
                        </div>
                        <div class="playiconnews wow fadeInLeft">
                          {" "}
                          <img src={playicon} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="newssectionpart">
                    {Sec4DataJson?.News?.map((e, i) =>
                      i === 0 || i % 2 === 0 ? (
                        <div class="row my-2" key={i}>
                          <div class="col-12 col-lg-6 newsdotsbgleft">
                            <div class="news-item ">
                              {/* news item photo  */}
                              <div
                                class="news-item-photo zoomingnews wow fadeInDown"
                                data-wow-delay=".2s"
                                data-wow-offset="150"
                              >
                                {" "}
                                {/* new image 1  */}
                                <img
                                  src={e.image}
                                  alt=""
                                  class="img-fluid  linearimg"
                                />
                                {/* pluse element  */}
                                {/* <div class="blogimgtpluselement wow rotateIn">
                                      {" "}
                                      <img src={pluselement} alt="" />
                                    </div> */}
                                {/* //////////// */}
                                {/* <div class="newsimgarelement ">
                                  {" "}
                                  <img
                                    src={element2png}
                                    alt=""
                                    class="ani-left-right"
                                  />
                                </div> */}
                                {/* ////////////////// */}
                                <div
                                  class="newsimgrbbtn wow fadeInUp"
                                  data-wow-delay="0.4s"
                                >
                                  {" "}
                                  <Link to={`/Blogsandnews/News/${e.newsId}`}>
                                    <a
                                      class="btn btn-primary mr-4 fr "
                                      href="#"
                                      onClick={handleScrollToTop}
                                    >
                                      READ NEWS{" "}
                                      <i class="icon ion-ios-arrow-thin-right"></i>
                                    </a>{" "}
                                  </Link>
                                </div>
                              </div>
                              {/* image oda plus element  */}
                              <div class="blogimgtpluselement wow rotateIn">
                                {" "}
                                <img src={pluselement} alt="" />
                              </div>
                              <div class="newsimgarelement ">
                                {" "}
                                <img
                                  src={element2png}
                                  alt=""
                                  class="ani-left-right"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mob-pad-20 tab-pad-20">
                            <div class="news-item nw1">
                              {/* plus  */}
                              {/* <div class=" wow rotateIn">
                                {" "}
                                <div className="leftplusnotit">
                                  <h5
                                    class="wow fadeInUp"
                                    data-wow-delay="0.4s"
                                    data-wow-duration="1s"
                                  >
                                    {e.newsId}
                                  </h5>
                                  <h6
                                    class="wow fadeInUp"
                                    data-wow-delay="0.6s"
                                    data-wow-duration="1s"
                                  >
                                    {e.title}
                                  </h6>
                                </div>
                                <div class="wow rotateIn">
                                  <div className="leftplusn ">
                                    <img src={pluselement} alt="" />
                                  </div>
                                </div>
                              </div> */}

                              <div className="d-flex align-items-center">
                                <div className="wow rotateIn">
                                  <img src={pluselement} alt="" />
                                </div>
                                <div style={{ marginLeft: "-50px" }}>
                                  <h5
                                    className="wow fadeInUp text-white fs24 pb-3 text-start"
                                    data-wow-delay="0.4s"
                                    data-wow-duration="1s"
                                  >
                                    {e.newsId}
                                  </h5>
                                  <h6
                                    className="wow fadeInUp text-white fs20 mb-3 text-start"
                                    data-wow-delay="0.6s"
                                    data-wow-duration="1s"
                                  >
                                    {e.title}
                                  </h6>
                                </div>
                              </div>

                              {/* news blog no  */}
                              {/* <h5
                                class="wow fadeInUp"
                                data-wow-delay="0.4s"
                                data-wow-duration="1s"
                              >
                                {e.newsId}
                              </h5> */}
                              {/* news titile  */}
                              {/* <h6
                                class="wow fadeInUp"
                                data-wow-delay="0.6s"
                                data-wow-duration="1s"
                              >
                                {e.title}
                              </h6> */}
                              {/* news desc  */}
                              <div class="newstextright wow fadeInUp ps-md-5 ps-0">
                                <p className="fs-6 px-md-5 px-0 ">{e.desc}</p>
                              </div>
                              {/* Person (author) and timer  */}
                              <div class="ps-md-5 ps-0">
                                <div className="newstextrightbg wow fadeInUp  d-flex flex-column py-3 ps-md-4 ps-3 ms-md-5 ms-0 ">
                                  <div>
                                    <i class="icon ion-person"></i> {e.person}
                                  </div>
                                  <div>
                                    <i class="icon ion-ios-timer-outline"></i>{" "}
                                    {e.timer}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="row mrsection " key={i}>
                          <div class="col-12 col-lg-6 mob-pad-20 ">
                            <div class="news-item nw2 ">
                              {/* <div class="newsspartpluselement wow rotateIn">
                                {" "}
                                <img src={pluselement} alt="" />
                              </div> */}
                              <div className="d-flex align-items-center justify-content-end">
                                <div
                                  className="w-100"
                                  style={{ marginRight: "-50px" }}
                                >
                                  <h5
                                    class="wow fadeInUp text-white fs24 pb-3 text-end "
                                    data-wow-delay="0.4s"
                                    data-wow-duration="1s"
                                  >
                                    {e.newsId}
                                  </h5>
                                  <h6
                                    class="wow fadeInUp text-white fs20 pb-3 text-end"
                                    data-wow-delay="0.6s"
                                    data-wow-duration="1s"
                                  >
                                    {e.title}
                                  </h6>
                                </div>
                                {/* <div className="newsspartpluselement "> */}
                                <div className="">
                                  {" "}
                                  <img
                                    src={pluselement}
                                    alt=""
                                    className="wow rotateIn"
                                  />
                                </div>
                                {/* </div> */}
                              </div>

                              <div class="newstextleft wow fadeInUp pe-md-5 pe-0">
                                <p className="px-md-5 px-0 fs-6">{e.desc}</p>
                              </div>
                              <div className="me-md-5 me-0">
                                <div class="newstextrightbg wow fadeInUp d-flex flex-column align-items-end py-3 pe-4 me-5">
                                  <div className="">
                                    <i class="icon ion-person"></i>
                                    {e.person}
                                  </div>
                                  <div>
                                    <i class="icon ion-ios-timer-outline"></i>{" "}
                                    {e.timer}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* image and read news btn & plusimg */}
                          <div class="col-12 col-lg-6 newsdotsbgright mobnone">
                            <div class="news-item ">
                              <div
                                class="news-item-photo zoomingnews wow fadeInDown"
                                data-wow-delay=".2s"
                                data-wow-offset="150"
                              >
                                {" "}
                                <img
                                  src={e.image}
                                  alt=""
                                  class="img-fluid rightimg linearimg"
                                />
                                {/* pluse symbol with img  */}
                                <div class="blogimgtpluselement wow rotateIn">
                                  {" "}
                                  <img src={pluselement} alt="" />
                                </div>
                                {/* read news button  */}
                                <div
                                  class="newsimgrbbtn wow fadeInUp"
                                  data-wow-delay="0.4s"
                                >
                                  {" "}
                                  <Link to={`/Blogsandnews/News/${e.newsId}`}>
                                    <a
                                      class="btn btn-primary mr-4 fr "
                                      href="#"
                                      onClick={handleScrollToTop}
                                    >
                                      READ NEWS{" "}
                                      <i class="icon ion-ios-arrow-thin-right"></i>
                                    </a>{" "}
                                  </Link>
                                </div>
                              </div>
                              <div class="blogimgtpluselement wow rotateIn">
                                {" "}
                                <img src={pluselement} alt="" />
                              </div>
                              <div class="newsimgarelement ">
                                {" "}
                                <img
                                  src={element2png}
                                  alt=""
                                  class="ani-left-right"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}

                    {/* second news  */}
                    {/* <div class="row mrsection">
                      <div class="col-12 col-lg-6 mob-pad-20 ">
                        <div class="news-item nw2 ">
                          <div class="newsspartpluselement wow rotateIn">
                            {" "}
                            <img src={pluselement} alt="" />
                          </div>
                          <h5
                            class="wow fadeInUp"
                            data-wow-delay="0.4s"
                            data-wow-duration="1s"
                          >
                            NEWS - 20
                          </h5>
                          <h6
                            class="wow fadeInUp"
                            data-wow-delay="0.6s"
                            data-wow-duration="1s"
                          >
                            MANNAI HOSTED QATAR <br />
                            NATIONAL DAY EVENT
                          </h6>
                          <div class="newstext">
                            <p>
                              Mannai – Microsoft Solutions, an ICT division of
                              Mannai Trading Company WLL and a leading Microsoft
                              Solutions provider in Qatar, is thrilled to
                              announce its strategic partnership with Wizard
                              Cyber, a global Cybersecurity firm. This
                              collaboration marks a significant milestone in
                              Qatar’s cybersecurity landscape, promising
                              enhanced services, expertise, and solutions to
                              businesses and organizations across Qatar, Oman,
                              Bahrain, and Kuwait. The partnership also
                              introduces a unique Sentinel CyberShield platform
                              in Qatar Microsoft data center powered by
                              Microsoft Sentinel to provide Modern SOC services.
                              As Qatar aims to become a prominent digital hub,
                              the demand for robust cybersecurity measures has
                              never been more critical. Mannai Microsoft
                              Solutions{" "}
                            </p>
                          </div>
                          <div class="newstextrightbg">
                            <p>
                              <i class="icon ion-person"></i> AUTHOR | MANSI
                              ARUNAKAR
                              <br />
                              <i class="icon ion-ios-timer-outline"></i> MONDAY
                              - SEPTEMBER 25, 2023
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6 newsdotsbgright mobnone">
                        <div class="news-item ">
                          <div
                            class="news-item-photo zoomingnews wow fadeInDown"
                            data-wow-delay=".2s"
                            data-wow-offset="150"
                          >
                            {" "}
                            <img src={newsimg1} alt="" class="img-fluid" />
                            <div class="blogimgtpluselement wow rotateIn">
                              {" "}
                              <img src={pluselement} alt="" />
                            </div>
                            <div
                              class="newsimgrbbtn wow fadeInUp"
                              data-wow-delay="0.4s"
                            >
                              {" "}
                              <a class="btn btn-primary mr-4 fr " href="#">
                                READ NEWS{" "}
                                <i class="icon ion-ios-arrow-thin-right"></i>
                              </a>{" "}
                            </div>
                          </div>
                          <div class="blogimgtpluselement wow rotateIn">
                            {" "}
                            <img src={pluselement} alt="" />
                          </div>
                          <div class="newsimgarelement ">
                            {" "}
                            <img
                              src={element2png}
                              alt=""
                              class="ani-left-right"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* third news  */}
                    {/* <div class="row tabnone">
                      <div class="col-12 col-lg-6 newsdotsbgleft">
                        <div class="news-item mob-pad-20 ">
                          <div
                            class="news-item-photo zoomingnews wow fadeInDown"
                            data-wow-delay=".2s"
                            data-wow-offset="150"
                          >
                            {" "}
                            <img src={newsimg1} alt="" class="img-fluid" />
                            <div class="blogimgtpluselement wow rotateIn">
                              {" "}
                              <img src={pluselement} alt="" />
                            </div>
                            <div
                              class="newsimgrbbtn wow fadeInUp"
                              data-wow-delay="0.4s"
                            >
                              {" "}
                              <a
                                class="btn btn-sm btn-primary mr-4 fr"
                                href="#"
                              >
                                READ NEWS{" "}
                                <i class="icon ion-ios-arrow-thin-right"></i>
                              </a>{" "}
                            </div>
                          </div>
                          <div class="blogimgtpluselement wow rotateIn">
                            {" "}
                            <img src={pluselement} alt="" />
                          </div>
                          <div class="newsimgarelement ">
                            {" "}
                            <img
                              src={element2png}
                              alt=""
                              class="ani-left-right"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6 ">
                        <div class="news-item nw1 ">
                          <div class="newsspartpluselement wow rotateIn">
                            {" "}
                            <img src={pluselement} alt="" />
                          </div>
                          <h5
                            class="wow fadeInUp"
                            data-wow-delay="0.4s"
                            data-wow-duration="1s"
                          >
                            NEWS - 23
                          </h5>
                          <h6
                            class="wow fadeInUp"
                            data-wow-delay="0.6s"
                            data-wow-duration="1s"
                          >
                            MANNAI HOSTED QATAR <br />
                            NATIONAL DAY EVENT
                          </h6>
                          <div class="newstext">
                            <p>
                              Mannai – Microsoft Solutions, an ICT division of
                              Mannai Trading Company WLL and a leading Microsoft
                              Solutions provider in Qatar, is thrilled to
                              announce its strategic partnership with Wizard
                              Cyber, a global Cybersecurity firm. This
                              collaboration marks a significant milestone in
                              Qatar’s cybersecurity landscape, promising
                              enhanced services, expertise, and solutions to
                              businesses and organizations across Qatar, Oman,
                              Bahrain, and Kuwait. The partnership also
                              introduces a unique Sentinel CyberShield platform
                              in Qatar Microsoft data center powered by
                              Microsoft Sentinel to provide Modern SOC services.
                              As Qatar aims to become a prominent digital hub,
                              the demand for robust cybersecurity measures has
                              never been more critical. Mannai Microsoft
                              Solutions{" "}
                            </p>
                          </div>
                          <div class="newstextbg">
                            <p>
                              <i class="icon ion-person"></i> AUTHOR | MANSI
                              ARUNAKAR
                              <br />
                              <i class="icon ion-ios-timer-outline"></i> MONDAY
                              - SEPTEMBER 25, 2023
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div class="text-center explorebtnnews  pb-4">
                      <a class="btn btn-primary mr-4 my-4  " href="#">
                        EXPLORE MORE{" "}
                        <i class="icon ion-ios-arrow-thin-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Partners /> */}

          {/* <Footer /> */}
        </div>
      </div>
    </div>
  );
};

export default Section4;
