import React from "react";
import StatsSection from "./StatsSection";
import Data from "./Travelpalce.json";

const SpaceTravelsub = () => {
  return (
    <div>
      <p
        className="fw-semibold text-light fs-5 text-justify  p-5 text-align-justify wow slideInUp"
        data-wow-delay="0.6s"
        data-wow-duration="1.4s"
      >
        Space Travel, a subsidiary company of Mannai Travel, was set up in 1988
        and is a general sales agent facilitating the operations of various
        international airlines operating from Qatar. Space Travel currently
        represents Flydubai, Kenya Airways, Phillippine Airlines, and Akasa Air.
      </p>
      <div
        className="text-center fw-bold text-dark pb-5 mt-3 wow slideInUp"
        data-wow-delay="0.6s"
        data-wow-duration="1.4s"
      >
        <h2
          className="pb-4 mx-5 text-blue "
          // style={{ borderBottom: "2px dashed #17355d" }}
        >
          We currently represents following Airlines in Qatar
        </h2>
      </div>
      <div className="">
        {Data.map((e, i) => (
          <StatsSection data={e} key={i} />
        ))}
      </div>
    </div>
  );
};

export default SpaceTravelsub;
