import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import $ from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import biglogoservicepng from "../img/biglogoservice.png";
import roundtxtpng from "../img/roundtxt.png";

import elementsmallxpng from "../img/elementsmallx.png";
import pluselement from "../img/pluselement.png";
import uparrowelement from "../img/uparrowelement.png";

import capimg from "../img/capimg.png";

import "animsition";
import "animsition/dist/css/animsition.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "../css/style.css";
import "../css/CareerDetail.css";
import "../css/globarpartner.css";
import "../js/timeline";
import "../js/script";

import CareerSectionData from "./CareerSection3.json";

const CareerDetailSection1 = () => {
  const params = useParams();
  const location = useLocation();
  const [blog, setBlog] = useState("");

  useEffect(() => {
    $(document).ready(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: true,
        // loadingParentElement: "body", //animsition wrapper element
        loadingClass: "preloader",
        loadingInner:
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>',
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        transition: function (url) {
          window.location.href = url;
        },
      });
    });

    // Initialize or use the scripts here
    // For example, you can initialize a WOW.js animation

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    new WOW().init();
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      });
    });
    /* eslint-disable no-undef */
    $(function () {
      var timelineElement = document.getElementById("timeline-aboutus");

      if (window.Timeline && timelineElement)
        new Timeline({
          element: timelineElement,
        });
    });
    /* eslint-enable no-undef */
  }, []);

  useEffect(() => {
    console.log(params.ID);
    console.log(CareerSectionData);
    const selectedBlog = CareerSectionData.CareerList.find(
      (e) => e.CareerId === params.ID
    );
    setBlog(selectedBlog);
    console.log(location);
    const ppnav = document.getElementById("pp-nav");
    if (ppnav) {
      ppnav.classList.remove("d-block");
      ppnav.classList.add("d-none");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }
  }, [params.ID]);

  return (
    <>
      <div className="main-body theme-orange dark-horizontal careersection1 overflow-hidden">
        <div className="animsition">
          <div className="wrapper parallax-start">
            <ReactTitle title="CareerSection1 | Mannai Travel" />

            <div className="slide-container p0">
              <div className="slide-bg wow pulse">
                <div className="inside opacityBG ">
                  {blog !== "" ? (
                    <img
                      src={require("../img/" + blog.image)}
                      alt=""
                      className="img-fluid object-fit-cover"
                      // style={{ display: 'block',width:'100%',objectFit:'contain' }}
                    />
                  ) : null}
                </div>
                <div className="container">
                  <div className="downplus wow rotateIn ">
                    {" "}
                    <img src={roundtxtpng} alt="wow rotateIn" />
                  </div>
                  <div className="smlogoglobal">
                    {" "}
                    <img src={biglogoservicepng} alt="" />
                  </div>

                  <div className="uparrowelementall">
                    {" "}
                    <img
                      src={uparrowelement}
                      alt=""
                      className="ani-top-bottom"
                    />
                  </div>
                  <div className="ltelementsmallx d-none d-sm-block">
                    {" "}
                    <img
                      src={elementsmallxpng}
                      alt=""
                      className="wow pulse"
                      data-wow-delay="300ms"
                      data-wow-iteration="infinite"
                      data-wow-duration="2s"
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-left cd">
                    <div
                      className="d-flex align-items-center justify-content-center cdpluselementcd wow rotateIn"
                      style={{
                        visibility: "visible",
                        animationName: "rotateIn",
                      }}
                    >
                      {" "}
                      <img src={pluselement} alt="" className="wow rotateIn" />
                    </div>
                    <div className="d-flex flex-column cdetextcd">
                      <h3 className="wow slideInLeft " data-wow-duration="1.2s">
                        {blog.CareerId}
                      </h3>
                      <h3
                        className="  wow slideInRight"
                        data-wow-delay=".1s"
                        data-wow-duration=".80s"
                      >
                        {" "}
                        {blog.title}{" "}
                      </h3>
                    </div>
                  </div>
                  <Link to={``} className="">
                    <a
                      className="btn btn-primary  buttonapplyposition "
                      href="#"
                    >
                      APPLY FOR THIS POSITION{" "}
                      <i className="icon ion-ios-arrow-thin-right"></i>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="clearfix" /> */}
            <div className="section section-item p0">
              <div className="container-fluid">
                <div className="slide-body blunbg1 ">
                  <div className="row ">
                    <div className="col-lg-12">
                      <div
                        className="col-md-12 d-flex align-items-center cdedestextcd  wow fadeInRight"
                        data-wow-delay="0.2s"
                        data-wow-duration="1s"
                      >
                        <div className="d-lg-flex d-md-block justify-content-evenly align-items-center w-100">
                          <div className="p-4 d-flex align-items-center">
                            <div className="capimgg d-flex align-items-center justify-content-center wow fadeInLeft">
                              <img src={capimg} alt="" />
                            </div>
                            <div className="cdedestextcdsmallx d-flex align-items-center">
                              <img
                                src={elementsmallxpng}
                                alt=""
                                className="wow pulse"
                                data-wow-delay="300ms"
                                data-wow-iteration="infinite"
                                data-wow-duration="2s"
                              />
                            </div>

                            <h2 className="wow fadeInRight">JOB DESCRIPTION</h2>
                          </div>
                          <span className=" ">
                            <h4 className="  px-4 px-lg-0 wow fadeInLeft">
                              {blog.CareerId}
                            </h4>
                            <h5 className="    px-4 px-lg-0 wow fadeInLeft ">
                              PUBLISH DATE : 10-10-23
                            </h5>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerDetailSection1;
