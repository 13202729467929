import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import $, { ready } from "jquery";
import { ReactTitle } from "react-meta-tags";

import WOW from "wow.js";

import leftbgimg from "../img/leftbg.png";
import logopng from "../img/logo.png";
import biglogopng from "../img/biglogobg.png";
import roundtxtpng from "../img/roundtxt.png";
import botlinepng from "../img/botline.png";
import verlinepng from "../img/verline.png";
import roundelementpng from "../img/roundelement.png";
import elementbgpng from "../img/elementbg.png";
import element1png from "../img/element1.png";
import element2png from "../img/element2.png";
import mannairoundtxt from "../img/Mannai Circle 001@72x.png";
import homevideo from "../img/homevideo.mp4";
import elementsmallxpng from "../img/elementsmallx.png";
import atpng from "../img/airtravel.png";
import rtpng from "../img/railticket.png";
import mtpng from "../img/MeetGreet.png";
import carcaroselpng from "../img/carrental.png";
import homeicon from "../img/headerhome.png";
import hotelpng from "../img/hotelaccomadation.png";
import bgvideo from "../img/bg-video.jpg";
// by me
import videoPause from "../img/pause1.png";
import videoPlay from "../img/play1.png";

import "pagepiling.js";
import "pagepiling.js/jquery.pagepiling.css";

import "animsition";
import "animsition/dist/css/animsition.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "socicon/css/socicon.css";

import "../css/animate.min.css";
import "../css/ionicons.min.css";
import "socicon/css/socicon.css";
import "../css/style.css";
import "../css/globarpartner.css";
import "../css/Responsiveall.css";
import "../js/script";
import Header from "../../Components/Header";

const Home = () => {
  const location = useLocation();
  const [playvideo, setPlayvideo] = useState(true);

  useEffect(() => {
    $(function () {
      $(".animsition").animsition({
        inClass: "fade-in",
        outClass: "fade-out",
        inDuration: 1500,
        outDuration: 800,
        linkElement: ".animsition-link",
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
        // loading: false,
        // loadingParentElement: "body",
        loadingClass: "preloader",
        loadingInner:
          // '<div class="spinner"><div class="mannai-logo-gif"></div></div>', // e.g '<img src="loading.svg" />'
          '<div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div>', // e.g '<img src="loading.svg" />'
        timeout: true,
        timeoutCountdown: 1000,
        onLoadEvent: true,
        browser: ["animation-duration", "-webkit-animation-duration"],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: "animsition-overlay-slide",
        overlayParentElement: "body",
        // transition: function (url) {
        //   location.href = url;
        // },
      });
    });
    new WOW().init();
    document.body.style.overflow = "hidden";

    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    $(function () {
      // Initialize PagePiling
      window.$(".a-horizontal").pagepiling({
        scrollingSpeed: 280,
        menu: "#menu",
        direction: "horizontal",
        loopTop: true,
        loopBottom: true,
        // anchors: ["HOME", "WHATWEDO", "TECHNOLOGY", "WHOWEARE"],
        // navigation: true,
        navigation: {
          textColor: "#000",
          bulletsColor: "#000",
          position: "right",
          tooltips: ["HOME", "WHATWEDO", "WHOWEARE", "TECHNOLOGY"],
        },
        afterLoad: function (anchorLink, index) {
          if (index === 1 || index === 2 || index === 3 || index === 4) {
            $(".mainbody").addClass("dark-horizontal");
          } else {
            $(".mainbody").removeClass("dark-horizontal");
          }
        },
      });
      $(".a-pp-prev").on("click", function () {
        window.$.fn.pagepiling.moveSectionUp();
      });
      $(".a-pp-next").on("click", function () {
        window.$.fn.pagepiling.moveSectionDown();
      });
    });
  }, []);

  useEffect(() => {
    $(function () {
      window.$(".owl-carousel").owlCarousel({
        items: 3,
        itemsDesktop: [1000, 3],
        itemsDesktopSmall: [979, 2],
        itemsTablet: [768, 2],
        itemsMobile: [650, 1],
        // pagination: true,
        loop: true,
        margin: 10,
        // nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        // autoplayHoverPause: true,
        center: true,
        navText: [
          "<i class='fa fa-angle-left'></i>",
          "<i class='fa fa-angle-right'></i>",
        ],
        // responsive: {
        //   0: {
        //     items: 1,
        //   },
        //   600: {
        //     items: 1,
        //   },
        //   1000: {
        //     items: 3,
        //   },
        // },
      });
    });
  }, []);

  useEffect(() => {
    const ppnav = document.getElementById("pp-nav");
    const tooltip = document.querySelector(".pp-tooltip");
    if (ppnav) {
      ppnav.classList.remove("d-none");
      ppnav.classList.add("d-block");
    }

    if (ppnav) {
      ppnav.remove(); // Remove the ppnav element from the DOM
    }

    const video = document.getElementById("myMovie");
    if (video.paused) {
      video.play();
      setPlayvideo(true);
    }
  }, []);

  const toggleClassToHtml = () => {
    const htmlTag = document.querySelector("html");
    if (htmlTag.classList.contains("body-menu-opened")) {
      htmlTag.classList.remove("body-menu-opened");
      htmlTag.classList.add("body-menu-close");
    } else {
      htmlTag.classList.add("body-menu-opened");
      htmlTag.classList.remove("body-menu-close");
    }
  };

  return (
    <div className="mainbody theme-orange dark-horizontal homepage">
      <ReactTitle title="Home | Mannai Travel - Travel Management Company from Qatar" />
      <CookieConsent
        location="bottom"
        buttonText="I Accept!!"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#17355d" }}
        buttonStyle={{ color: "#fff", fontSize: "13px", background: "#3fa9f5" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <div className="animsition overflow-hidden">
        <div className="wrapper">
          <header id="header" className="header header-fixed">
            <div className="container-fluid mp0 clearfix">
              <div className="d-flex justify-content-between">
                {" "}
                <div className="p-2 px-5 py-4">
                  {" "}
                  {/* <Link to="/">
                    <div>
                      <img src={logopng} alt="" className="brand-logo" />
                    </div>
                  </Link> */}
                </div>
                <div className="d-flex h-fit-content ">
                  <div className="homeicon d-flex align-items-center mx-2">
                    <Link to="/">
                      {/* <i className="icon icon ion-home"></i>{" "} */}
                      <img
                        src={homeicon}
                        height={30}
                        width={30}
                        alt=""
                        className=""
                      />
                    </Link>
                  </div>
                  <button
                    className="nav-toggle-btn a-nav-toggle"
                    onClick={toggleClassToHtml}
                  >
                    {" "}
                    <span className="nav-toggle nav-toggle-sm">
                      {" "}
                      <span className="stick stick-1"></span>{" "}
                      <span className="stick stick-2"></span>{" "}
                      <span className="stick stick-3"></span>{" "}
                    </span>{" "}
                  </button>
                </div>
              </div>
            </div>
            <Header />
          </header>
          <div className="biglogo-fixed">
            {" "}
            <img src={biglogopng} alt="" />
          </div>
          {/* <div className="horizontal-nav">
            <div className="container">
              <ul id="menu">
                <li data-menuanchor="HOME" className="active"></li>
                <li data-menuanchor="WHATWEDO"></li>
                <li data-menuanchor="TECHNOLOGY"></li>
                <li data-menuanchor="WHOWEARE"></li>
              </ul>
            </div>
            <div class="horizontal-controls navarrow">
              {" "}
              <a class="a-pp-prev disabled" href="#" aria-label="Previous">
                {" "}
                <i class="icon ion-ios-arrow-left fs20"></i>{" "}
              </a>{" "}
              <a class="a-pp-next disabled" href="#" aria-label="Next">
                {" "}
                <i class="icon ion-ios-arrow-right fs20"></i>{" "}
              </a>{" "}
            </div>  
          </div>*/}
          <div class="home-horizontal a-horizontal full-height">
            <div class="section pp-scrollable slide slide1 slide-dark">
              <div
                className="slide-container"
                style={{ margin: 0, padding: 0, height: "100vh" }}
              >
                <div className="container" style={{ zIndex: 400 }}>
                  <div className="contentparthome">
                    <div
                      className="wow slideInLeft  NormalHeading"
                      data-wow-delay="2.0s"
                      data-wow-duration="2.5s"
                    >
                      WELCOME TO THE WORLD OF TRAVEL{" "}
                    </div>
                    <div
                      className="wow fadeInRight   SubHeading"
                      data-wow-delay="2.0s"
                      data-wow-duration="2.6s"
                    >
                      Enhancing Sustainable Travel Experiences Through
                      Innovative Approaches and Tech Advancements!
                    </div>
                    <div
                      className="wow fadeInLeft  Pyara"
                      data-wow-delay="2.0s"
                      data-wow-duration="2.7s"
                    >
                      Achieving Travel Program Excellence with a Preferred
                      Global Partner{" "}
                    </div>
                    <div>
                      <div className="mb-1">
                        {" "}
                        <img
                          src={element2png}
                          alt=""
                          className="ani-left-right"
                        />
                      </div>
                      <Link
                        to="/connectus"
                        className="btn btn-primary mr-4 fr rmbtn wow bounceInUp"
                        data-wow-delay="1.2s"
                        data-wow-duration="1.6s"
                      >
                        CONTACT US{" "}
                        <i className="icon ion-ios-arrow-thin-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="wow fadeInLeft animated leftbg"
                  data-wow-duration="2000ms"
                  data-wow-delay="0ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "2000ms",
                    animationDelay: "0ms",
                    animationName: "fadeInLeft",
                  }}
                >
                  {" "}
                  <img src={leftbgimg} className=" vh-100 vvw-50" alt="" />{" "}
                </div>
                <div className={"playpause"}>
                  {/* <ion-icon name="pause-outline"></ion-icon> */}

                  <i
                    onClick={() => {
                      const video = document.getElementById("myMovie");
                      console.log(video);
                      if (video.paused) {
                        video.play();
                        setPlayvideo(true);
                      } else {
                        video.pause();
                        setPlayvideo(false);
                      }

                      // const button = document.querySelector(".playpause");
                      // button.remove();
                    }}
                  >
                    {" "}
                    {playvideo ? (
                      <img
                        src={videoPause}
                        width="40"
                        height="40"
                        alt="pause"
                        // className="pausevid"
                      />
                    ) : (
                      <img
                        src={videoPlay}
                        width="40"
                        height="40"
                        alt="pause"
                        // className="playvid"
                      />
                    )}{" "}
                  </i>
                </div>

                <div className="botline">
                  {" "}
                  <img src={botlinepng} alt="" />
                </div>
                <div className="verline">
                  {" "}
                  <img src={verlinepng} alt="" />
                </div>
                <div className="roundelement ">
                  {" "}
                  <img src={roundelementpng} alt="" />
                </div>
                {/* <div className="element1 float-bob-y">
                  {" "}
                  <img src={element1png} alt="" />
                </div>
                */}
                {/* <div
                  className="elementbg"
                  style={{
                    visibility: "visible",
                    animationDuration: "2000ms",
                    animationDelay: "0ms",
                    animationName: "fadeInRight",
                  }}
                >
                  {" "}
                  <img src={elementbgpng} alt="" />
                </div> */}

                <div className="roundanimation">
                  <div className="roundtxtanimationplus">
                    {" "}
                    <img src={roundtxtpng} alt="" />
                  </div>
                  <div className="roundtextanimation">
                    {" "}
                    <img src={mannairoundtxt} alt="" />{" "}
                  </div>
                </div>
                <video
                  id="myMovie"
                  width="100%"
                  height="auto"
                  preload="auto"
                  muted
                  loop
                  autoplay
                >
                  <source src={homevideo} type="video/mp4" />
                </video>
              </div>
            </div>
            <div class="section pp-scrollable slide slide2 slide-dark">
              <div className="slide-container">
                <div className="slide-bg">
                  <div className="inside"></div>
                </div>

                <div className="botline">
                  {" "}
                  <img src={botlinepng} alt="" />
                </div>
                <div className="verline">
                  {" "}
                  <img src={verlinepng} alt="" />
                </div>
                <div className="roundelement ">
                  {" "}
                  <img src={roundelementpng} alt="" />
                </div>
                {/* <div className="element1 float-bob-y">
                  {" "}
                  <img src={element1png} alt="" />
                </div> */}

                <div className="element1botWWA">
                  {" "}
                  <img
                    src={elementsmallxpng}
                    className="wow pulse"
                    data-wow-delay="300ms"
                    data-wow-iteration="infinite"
                    data-wow-duration="2s"
                  />
                </div>
                <div className="roundanimation">
                  <div className="roundtxtanimationplus">
                    {" "}
                    <img src={roundtxtpng} alt="" />
                  </div>
                  <div className="roundtextanimation">
                    {" "}
                    <img src={mannairoundtxt} alt="" />{" "}
                  </div>
                </div>
                <div className="container">
                  <div className="contentpartwwd ">
                    <div
                      className="wow slideInLeft 
                      NormalHeading"
                      data-wow-duration="1.4s"
                    >
                      WHAT WE DO
                    </div>
                    <div
                      className=" wow slideInRight text-white SubHeading"
                      data-wow-delay=".2s"
                      data-wow-duration=".95s"
                    >
                      Embark on a global adventure with our comprehensive travel
                      services. Whether it’s for Business or Leisure purposes,
                      we'll get you there with our comprehensive travel
                      services.
                    </div>
                    <div
                      className=" wow fadeInRight Pyara"
                      data-wow-delay="1.0s"
                      data-wow-duration="1.4s"
                    >
                      Empowered by our seasoned team and cutting-edge
                      technology, you have the freedom to savor your most
                      cherished travel experiences.
                    </div>{" "}
                    <div>
                      <div className="mb-1">
                        {" "}
                        <img
                          src={element2png}
                          alt=""
                          className="ani-left-right"
                        />
                      </div>
                      <Link
                        to="/Services"
                        className="btn btn-primary mr-4 fr rmbtn wow bounceInUp"
                        data-wow-delay="1.2s"
                        data-wow-duration="1.6s"
                      >
                        EXPLORE{" "}
                        <i className="icon ion-ios-arrow-thin-right"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="contentpartserviceswwd ">
                    <div className="slider">
                      <div className="owl-carousel">
                        <div className="slider-card">
                          <div className=" justify-content-center align-items-center mb-4">
                            {" "}
                            <img src={atpng} alt="" />{" "}
                          </div>
                        </div>
                        <div className="slider-card">
                          <div className=" justify-content-center align-items-center mb-4">
                            {" "}
                            <img src={hotelpng} alt="" />{" "}
                          </div>
                        </div>
                        <div className="slider-card">
                          <div className=" justify-content-center align-items-center mb-4">
                            {" "}
                            <img src={carcaroselpng} alt="" />{" "}
                          </div>
                        </div>
                        <div className="slider-card">
                          <div className=" justify-content-center align-items-center mb-4">
                            {" "}
                            <img src={rtpng} alt="" />{" "}
                          </div>
                        </div>
                        <div className="slider-card">
                          <div className=" justify-content-center align-items-center mb-4">
                            {" "}
                            <img src={mtpng} alt="" />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="wow fadeInLeft animated leftbg "
                    data-wow-duration="2000ms"
                    data-wow-delay="0ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "2000ms",
                      animationDelay: "0ms",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <img src={leftbgimg} className=" vh-100 vvw-50" alt="" />{" "}
                  </div>
                </div>
              </div>
            </div>

            <div class="section pp-scrollable slide slide4 slide-dark">
              <div className="slide-container">
                <div className="slide-bg">
                  <div className="inside"></div>
                </div>
                <div className="container">
                  <div className="botline">
                    {" "}
                    <img src={botlinepng} alt="" />
                  </div>
                  <div className="verline">
                    {" "}
                    <img src={verlinepng} alt="" />
                  </div>
                  <div className="roundelement ">
                    {" "}
                    <img src={roundelementpng} alt="" />
                  </div>

                  <div className="element1botWWA">
                    {" "}
                    <img
                      src={elementsmallxpng}
                      className="wow pulse"
                      data-wow-delay="300ms"
                      data-wow-iteration="infinite"
                      data-wow-duration="2s"
                    />
                  </div>
                  <div className="roundanimation">
                    <div className="roundtxtanimationplus">
                      {" "}
                      <img src={roundtxtpng} alt="" />
                    </div>
                    <div className="roundtextanimation">
                      {" "}
                      <img src={mannairoundtxt} alt="" />{" "}
                    </div>
                  </div>

                  <div className="contentpartwwa ">
                    <div
                      className="wow slideInLeft NormalHeading"
                      data-wow-duration="1.4s"
                    >
                      WHO WE ARE
                    </div>
                    <div
                      className=" wow slideInRight SubHeading"
                      data-wow-delay=".2s"
                      data-wow-duration=".95s"
                    >
                      Built upon a foundation of core human values, Mannai
                      Travel stands as a cherished and esteemed company in
                      Qatar. Serving a diverse spectrum of customers, we are
                      renowned for our unwavering ethics and human values.
                      <br />
                      Our relentless dedication is focused on crafting an
                      exceptional organization within Qatar.
                    </div>{" "}
                    <div>
                      <div className="mb-1">
                        {" "}
                        <img
                          src={element2png}
                          alt=""
                          className="ani-left-right"
                        />
                      </div>
                      <Link
                        to="/Whoweare"
                        className="btn btn-primary mr-4 fr rmbtn wow bounceInUp"
                        data-wow-delay="1.2s"
                        data-wow-duration="1.6s"
                      >
                        ABOUT US{" "}
                        <i className="icon ion-ios-arrow-thin-right"></i>
                      </Link>
                    </div>
                  </div>
                  <div
                    className="wow fadeInLeft animated leftbg"
                    data-wow-duration="2000ms"
                    data-wow-delay="0ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "2000ms",
                      animationDelay: "0ms",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <img src={leftbgimg} className=" vh-100 vvw-50" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="section pp-scrollable slide slide3 slide-dark">
              <div className="slide-container">
                <div className="slide-bg">
                  <div className="inside"></div>
                </div>
                <div className="container">
                  <div className="botline">
                    {" "}
                    <img src={botlinepng} alt="" />
                  </div>
                  <div className="verline">
                    {" "}
                    <img src={verlinepng} alt="" />
                  </div>
                  <div className="roundelement ">
                    {" "}
                    <img src={roundelementpng} alt="" />
                  </div>

                  <div className="element1botWWA">
                    {" "}
                    <img
                      src={elementsmallxpng}
                      className="wow pulse"
                      data-wow-delay="300ms"
                      data-wow-iteration="infinite"
                      data-wow-duration="2s"
                    />
                  </div>
                  <div className="roundanimation">
                    <div className="roundtxtanimationplus">
                      {" "}
                      <img src={roundtxtpng} alt="" />
                    </div>
                    <div className="roundtextanimation">
                      {" "}
                      <img src={mannairoundtxt} alt="" />{" "}
                    </div>
                  </div>
                  <div className="contentparttechnology ">
                    <div
                      className="wow slideInLeft NormalHeading
                      "
                      data-wow-duration="1.4s"
                    >
                      TECHNOLOGY
                    </div>
                    <div
                      className=" wow slideInRight SubHeading"
                      data-wow-delay=".2s"
                      data-wow-duration=".95s"
                    >
                      Upgrade your travel experience with state-of-the-art
                      technology and unlock efficient ways to save your money
                      without compromising on safety and comfort while
                      travelling.
                    </div>
                    <div
                      className=" wow fadeInRight Pyara"
                      data-wow-delay="1.0s"
                      data-wow-duration="1.4s"
                    >
                      We prioritize continual investment in cutting-edge
                      technology, enabling our customers to stay updated on the
                      latest travel trends and reimagine their journeys while
                      minimizing expenses.
                      <br />
                    </div>{" "}
                    <div>
                      <div className="mb-1">
                        {" "}
                        <img
                          src={element2png}
                          alt=""
                          className="ani-left-right"
                        />
                      </div>
                      <Link
                        to="/Technologies"
                        className="btn btn-primary mr-4 fr rmbtn wow bounceInUp"
                        data-wow-delay="1.2s"
                        data-wow-duration="1.6s"
                      >
                        EXPLORE{" "}
                        <i className="icon ion-ios-arrow-thin-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="wow fadeInLeft animated leftbg"
                  data-wow-duration="2000ms"
                  data-wow-delay="0ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "2000ms",
                    animationDelay: "0ms",
                    animationName: "fadeInLeft",
                  }}
                >
                  {" "}
                  <img src={leftbgimg} className=" vh-100 vvw-50" alt="" />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
